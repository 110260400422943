import React, { useState } from "react";
import { Input } from "react-native-elements";
import { useFormikContext } from "formik";

export default function FormInput({
  disabled=false,
  disabledStyle,
  enabled=true,
  name,
  onValueChange,
  inputProps,
  ...otherProps
}) {
  const {
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
  } = useFormikContext();

  return (
    <>
      <Input
        disabled={!enabled || disabled}
        errorMessage={touched[name] ? errors[name] : "  "}
        onBlur={() => {
          setFieldTouched(name);
        }}
        onChangeText={(data) => {
          setFieldValue(name, data);
          if (onValueChange) onValueChange(data);
        }}
        renderErrorMessage={true}
        {...otherProps}
        {...inputProps}
        {...(!enabled || disabled ? disabledStyle : {})}
      />
    </>
  );
}
