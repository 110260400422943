import React from "react";
import { View, Text } from "react-native";

export default function MessageBox({ message, textStyle, wrapperStyle }) {
  if (!message) return null;
  return (
    <View style={wrapperStyle}>
      <Text style={textStyle}>{message}</Text>
    </View>
  );
}
