export default ({ colors, typos }) => ({
  input: {
    disabledInputStyle: {
      ...typos.inputText,
      backgroundColor: colors.transparent,
      color: colors.text,
      borderColor: colors.transparent,
      opacity: 1,
    },
    errorStyle: {
      color: colors.error,
      height: 15,
      marginTop: 0,
      right: 0,
      textAlign: "left",
    },
    inputStyle: {
      ...typos.inputText,
      color: colors.text,
      minHeight: 24,
      maxHeight: 24,
    },
    inputContainerStyle: {
      backgroundColor: colors.background,
      borderBottomWidth: 0,
      height: 24,
    },
    labelStyle: {
      ...typos.subTitleText,
      color: colors.subText,
      fontWeight: "normal",
      marginBottom: 0,
      borderColor: colors.divider,
      borderBottomWidth: 1,
    },
    placeholderTextColor: colors.divider,
  },

  inputDisabled: {
    inputContainerStyle: {
      backgroundColor: colors.background,
      borderColor: colors.transparent,
      borderTopWidth: 1,
      borderBottomWidth: 0,
      height: 24,
      padding: 4,
      paddingHorizontal: 8,
    },
    errorStyle: { height: 15 },
  },

  picker: {
    containerStyle: {
      paddingHorizontal: 10,
      width: "100%",
    },
    labelContainerStyle: {
      left: 0,
      marginBottom: 4,
    },
    labelStyle: {
      ...typos.subTitleText,
      color: colors.subText,
      fontWeight: "normal",
      borderColor: colors.divider,
      borderBottomWidth: 1,
    },
    pickerContainerStyle: {
      borderColor: colors.divider,
      borderRadius: 8,
      borderWidth: 1,
      overflow: "hidden",
    },
    style: {
      ...typos.inputText,
      backgroundColor: colors.background,
      borderColor: colors.transparent,
      //borderRadius: 8,
      // borderWidth: 1,
      color: colors.text,
      minHeight: 16,
      height: 24,
      margin: 0,
      padding: 0,
      paddingHorizontal: 4,
      width: "100%",
    },
  },

  pickerDisabled: {
    pickerContainerStyle: {
      borderColor: colors.transparent,
      borderRadius: 8,
      borderWidth: 1,
      overflow: "hidden",
    },
    style: {
      ...typos.inputText,
      //borderColor: colors.transparent,
      //borderRadius: 8,
      //borderWidth: 1,
      color: colors.text,
      minHeight: 16,
      height: 24,
      margin: 0,
      padding: 2,
      paddingHorizontal: 12,
      width: "100%",
    },
  },
});
