// Libraries
import React, { useEffect, useState } from "react";
import * as FileSystem from "expo-file-system";

// components
import { Platform, View, Text } from "react-native";
import { Button, Webview } from "../../../components";

export default function WebDrawer({
  props,
  expanded,
  switchExpanded,
  title,
  content,
}) {
  const [webContent, setWebContent] = useState(null);

  useEffect(() => {
    // if (Platform.OS === "web") setWebContent(content);
    // else {
    FileSystem.readAsStringAsync(content)
      .then((data) => {
        setWebContent(data);
      })
      .catch(() => setWebContent(content));
    // }
  }, [content]);

  return (
    <View style={[props.info.wrapperStyle, { flex: expanded ? 1 : -1 }]}>
      <View style={props.info.headerStyle}>
        <Text {...props.info.headerText}>{title}</Text>
        <Button
          {...props.buttons.common}
          iconProps={
            expanded
              ? props.buttons.shrinkIconProps
              : props.buttons.expandIconProps
          }
          onPress={switchExpanded}
        />
      </View>
      {expanded ? (
        <View style={props.expanded}>
          <Webview
            {...props.info.content}
            originWhitelist={["*"]}
            style={{ flex: Platform.OS === "android" ? 0 : 1, width: "100%" }}
            scalesPageToFit={false}
            source={{ html: webContent }}
            textZoom={100}
          />
        </View>
      ) : null}
    </View>
  );
}

/*
<View
        style={[props.info.contentWrapperStyle, expanded ? props.expanded : {}]}
      >
        <Webview
          {...props.info.content}
          originWhitelist={["*"]}
          style={{ flex: 0, width: "100%" }}
          scalesPageToFit={false}
          source={{ html: content }}
          textZoom={100}
        />
      </View>

*/
