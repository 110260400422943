export default ({ colors, iconButton, typos }) => ({
  activeButton: {
    iconProps: {
      color: colors.offText,
    },
    inactiveBackgroundColor: colors.darkPrimary,
  },

  container: {
    // height: 48,
    backgroundColor: colors.primary,
    borderColor: colors.darkPrimary,
    borderTopWidth: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    overflow: "hidden",
    paddingTop: 4,
    paddingHorizontal: 20,
  },

  info: {
    containerStyle: {
      position: "absolute",
      bottom: 8,
      right: 8,
    },
    textStyle: {
      ...typos.subTitleText,
      color: colors.lightPrimary,
      borderColor: colors.lightPrimary,
      borderWidth: 2,
      borderRadius: 20,
      lineHeight: 12,
      height: 16,
      width: 16,
      padding: 2,
      textAlign: "center",
    },
  },

  buttons: {
    account: {
      name: "user", // "account-circle-outline",
      type: "antdesign", // "ionicons", //"material-community",
    },
    home: {
      name: "calendar",
      type: "antdesign",
    },
    admin: {
      name: "setting",
      type: "antdesign",
    },
    booking: {
      name: "plus",
      type: "antdesign",
    },
  },

  buttonProps: {
    ...iconButton,
    iconProps: {
      ...iconButton.iconProps,
      color: colors.darkPrimary,
      size: 20,
    },
    inactiveBackgroundColor: colors.lighterPrimary,
  },
  buttonContainer: { alignItems: "center", width: 80 },
  buttonLabel: {
    numberOfLines: 1,
    style: {
      ...typos.annotation,
      color: colors.offText,
    },
  },
});
