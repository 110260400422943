import React, { useEffect, useState } from "react";
import { Picker } from "@react-native-picker/picker";
import { View, Text } from "react-native";
import FormInput from "./FormInput";

const HOURS = [...Array(13).keys()].map((hour) => {
  return ` ${hour.toString().padStart(2, "0")} `;
});
const MINUTES = [...Array(60).keys()].map((minutes) => {
  return ` ${minutes.toString().padStart(2, "0")} `;
});

export default function FormTimePicker({
  buttonProps,
  disabled,
  value,
  onValueChange,
  ...otherProps
}) {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [period, setPeriod] = useState("am");

  useEffect(() => {
    const frontTime = timeBackToFront(value);
    setHours(parseInt(frontTime?.slice(0, 2)) || 0);
    setMinutes(parseInt(value?.slice(2, 4)) || 0);
    setPeriod(frontTime?.slice(-2) || "am");
  }, [value]);

  if (disabled)
    return (
      <FormInput disabled value={timeBackToFront(value)} {...otherProps} />
    );
  else
    return (
      <View
        style={{
          flexDirection: "row",
          borderWidth: 1,
          borderColor: "white",
          borderRadius: 6,
          paddingHorizontal: 2,
          paddingTop: 2,
          jutifyContent: "center",
          marginLeft: 4,
        }}
      >
        <Picker
          onValueChange={(newHour) => {
            onValueChange(
              timeFrontToBack(`${newHour.toString()}:${minutes} ${period}`)
            );
          }}
          selectedValue={hours}
          style={pickerStyle}
        >
          {HOURS.map((_hour) => (
            <Picker.Item
              label={_hour}
              key={_hour}
              value={parseInt(_hour)}
              {...itemStyle}
            />
          ))}
        </Picker>
        <Text style={{ color: "white", marginLeft: -4, marginTop: -2 }}>
          {":"}
        </Text>
        <Picker
          style={pickerStyle}
          selectedValue={minutes}
          onValueChange={(newMinutes) => {
            onValueChange(
              timeFrontToBack(`${hours}:${newMinutes.toString()} ${period}`)
            );
          }}
        >
          {MINUTES.map((_minutes) => (
            <Picker.Item
              label={_minutes.toString()}
              key={_minutes}
              value={parseInt(_minutes)}
              {...itemStyle}
            />
          ))}
        </Picker>
        <Picker
          style={[pickerStyle, { marginLeft: 0 }]}
          selectedValue={period}
          onValueChange={(newPeriod) => {
            onValueChange(timeFrontToBack(`${hours}:${minutes} ${newPeriod}`));
          }}
        >
          {["am", "pm"].map((_period) => (
            <Picker.Item
              label={_period}
              key={_period}
              value={_period}
              {...itemStyle}
            ></Picker.Item>
          ))}
        </Picker>
      </View>
    );
}

const pickerStyle = {
  borderWidth: 0,
  backgroundColor: "transparent",
  color: "white",
  fontSize: 16,
  webkitAppearance: "none",
  mozAppearance: "none",
  webkitScrollbar: { display: "none" },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  width: 22,
  marginHorizontal: 4,
};

const itemStyle = {
  color: "black",
  fontSize: 20,
  style: { fontSize: 20 },
};

// convert time from frontend format to backend format
const timeFrontToBack = (timeString, is12 = true) => {
  let time = timeString.toLowerCase();
  let hours = time.substring(0, time.indexOf(":")).trim();
  if (isNaN(hours)) return "";
  let minutes = time.substring(time.indexOf(":") + 1).trim();
  if (isNaN(parseInt(minutes))) return "";
  if (is12) {
    if (minutes.endsWith("pm")) hours = parseInt(hours) + 12;
    if (hours === 24) hours = 0;
  }
  minutes = parseInt(minutes);
  return (hours * 100 + minutes).toString().padStart(4, "0");
};

// convert time from backend format to frontend format
const timeBackToFront = (timeString, is12 = true) => {
  const hours = timeString.substring(0, 2);
  const minutes = timeString.substring(2);
  if (is12) {
    const h = parseInt(hours);
    if (h > 12) return `${(h - 12).toString()}:${minutes} pm`;
    if (h === 0) return `12:${minutes} pm`;
    return `${hours}:${minutes} am`;
  } else return `${hours}:${minutes}`;
};
