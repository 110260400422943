import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";
import { State, PanGestureHandler } from "react-native-gesture-handler";

import dayjs from "dayjs";

export default function MonthSelector({
  containerProps,
  leftButtonProps,
  monthList = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  onChange,
  rightButtonProps,
  titleProps,
}) {
  const [activeMonth, setActiveMonth] = useState(dayjs());
  const [swiped, setSwiped] = useState(false);

  useEffect(() => {
    onChange(activeMonth);
  }, [activeMonth]);

  return (
    <PanGestureHandler
      activeOffsetX={[-40, 40]}
      failOffSetY={[-20, 20]}
      shouldCancelWhenOutside={false}
      minPointers={1}
      maxPointers={1}
      onGestureEvent={({ nativeEvent }) => {
        if (!swiped) {
          if (nativeEvent.translationX !== 0) {
            if (nativeEvent.translationX < 0)
              setActiveMonth(activeMonth.add(1, "month"));
            else setActiveMonth(activeMonth.subtract(1, "month"));
            setSwiped(true);
          }
        }
      }}
      onHandlerStateChange={({ nativeEvent }) => {
        if (
          nativeEvent.state == State.END ||
          nativeEvent.state == State.FAILED ||
          nativeEvent.state == State.CANCELLED
        ) {
          setSwiped(false);
        }
      }}
    >
      <View {...containerProps}>
        <Icon
          {...leftButtonProps}
          onPress={() => setActiveMonth(activeMonth.subtract(1, "month"))}
        />
        <TouchableOpacity
          activeOpacity={0.5}
          onPress={() => setActiveMonth(dayjs())}
        >
          <Text {...titleProps}>
            {activeMonth
              ? monthList[activeMonth.month()] + " " + activeMonth.year()
              : null}
          </Text>
        </TouchableOpacity>
        <Icon
          {...rightButtonProps}
          onPress={() => setActiveMonth(activeMonth.add(1, "month"))}
        />
      </View>
    </PanGestureHandler>
  );
}
