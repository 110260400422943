export default ({ colors, button, iconButton, typos }) => ({
  buttonBack: {
    buttonProps: {
      ...button,
      titleStyle: {
        ...button.titleStyle,
        color: colors.darkPrimary,
        paddingHorizontal: 8,
      },
      containerStyle: { ...button.containerStyle },
      inactiveBackgroundColor: colors.background,
    },
  },

  credits: {
    buttonProps: {
      ...iconButton,
      iconProps: {},
      titleStyle: {
        ...iconButton.titleStyle,
        ...typos.titleText,
        alignSelf: "center",
        color: colors.darkPrimary,
      },
      inactiveBackgroundColor: colors.lighterPrimary,
    },
    containerStyle: { alignItems: "center" },
    label: {
      style: { ...typos.annotation, color: colors.offText, marginTop: 2 },
    },
  },

  containerStyle: {
    backgroundColor: colors.primary,
    flexDirection: "row",
    height: 40,
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 10,
  },

  logo: {
    containerStyle: {
      alignItems: "center",
      height: 32,
      marginLeft: 8,
    },

    imageProps: {
      source: require("../styles/assets/logo128.png"),
      resizeMode: "contain",
      style: {
        height: 32,
        width: 32,
      },
    },
    siteProps: {
      style: {
        color: colors.tagline,
        fontSize: 8,
        fontFamily: "Rendang",
        textShadowColor: colors.taglineShadow,
        textShadowOffset: { width: 2, height: 2 },
        textShadowRadius: 2,
        //marginLeft: -42,
        marginTop: -14,
      },
    },
  },

  userText: {
    style: {
      ...typos.titleText,
      color: colors.offText,
      marginLeft: -50,
    },
  },
});
