import React, { useState } from "react";
import { Image, View, Text, TouchableOpacity } from "react-native";

export default function Product({ data, image, lang, props, qty = 0, setQty }) {
  const increase = () => {
    setQty(qty + 1);
  };
  const decrease = () => {
    if (qty > 0) setQty(qty - 1);
  };

  return (
    <View style={props.wrapperStyle}>
      {image ? (
        <Image source={image} {...props.image} />
      ) : (
        <View style={props.image.style} />
      )}
      <View style={props.texts.wrapperStyle}>
        <View style={props.texts.headerStyle}>
          <Text {...props.texts.label}>{data["label"]}</Text>
          <Text {...props.texts.price}>
            {lang.PRICE_BEFORE + data["price"] / 100 + lang.PRICE_AFTER}
          </Text>
        </View>
        <Text {...props.texts.details}>{data["details"]}</Text>
      </View>
      <View style={props.qty.wrapperStyle}>
        <TouchableOpacity onPress={increase}>
          <Text {...props.qty.increaseButton}>+</Text>
        </TouchableOpacity>
        <Text {...props.qty.text}>{qty}</Text>
        <TouchableOpacity onPress={decrease}>
          <Text {...props.qty.decreaseButton}>-</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
