import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";
import { Button } from "../../../components";
import { Input } from "react-native-elements";

export default function Credits({
  props,
  lang,
  balance,
  isEditor,
  onUpdateCredits,
}) {
  const [delta, setDelta] = useState("");
  const [comments, setComments] = useState("");

  useEffect(() => {
    setDelta(""), setComments("");
  }, [balance]);

  return (
    <View style={props.containerStyle}>
      <View style={props.headerStyle}>
        <Text style={props.titleStyle}>{lang.CREDITS}</Text>
        <Text style={props.balanceStyle}>{balance}</Text>
      </View>
      {isEditor ? (
        <View style={props.body.containerStyle}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Text style={props.body.labelStyle}>Add or Remove</Text>
            <Input
              {...props.body.inputStyle}
              onChangeText={(value) => setDelta(value)}
              value={delta}
            />
            <Text style={props.body.labelStyle}>{lang.CREDITS}</Text>
          </View>
          <Text style={props.body.labelStyle}>{lang.CREDITS_COMMENTS}</Text>
          <Input
            {...props.body.inputComment}
            onChangeText={(value) => setComments(value)}
            value={comments}
          />
          {!isNaN(delta) && delta !== 0 && comments.length ? (
            <Button
              title={lang.CREDITS_UPDATE}
              {...props.button}
              onPress={() => onUpdateCredits(delta, comments)}
            />
          ) : null}
        </View>
      ) : null}
    </View>
  );
}
