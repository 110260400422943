import userAPI from "./user";

const api = (() => {
  let dispatch;
  let firebase;
  let region;
  let auth;
  let paymentIntent;
  let unsubscribePaymentRequest;

  const initiateStore = (_dispatch, _backend, _region) => {
    dispatch = _dispatch;
    firebase = _backend;
    region = _region;
    auth = firebase.auth();
    paymentIntent = firebase.firestore().collection("users_payments");
  };
  /*
  // Stripe integration
  const getPaymentIntent = (basket, callback) => {
    const data = {};
    data.userID = auth.currentUser.uid;
    data.email = auth.currentUser.email;
    data.paymentMethods = ["card"];
    data.order = basket;

    return firebase
      .app()
      .functions(region)
      .httpsCallable("getCardPaymentIntent")(data)
      .then((res) => {
        paymentIntent
          .doc(res.data.id)
          .onSnapshot((doc) => callback(doc.data()));
        return res.data;
      });
  };
*/
  // HitPay integration
  const getPaymentRequest = (basket, method, callback) => {
    const data = {};
    data.userID = auth.currentUser.uid;
    data.email = auth.currentUser.email;
    data.paymentMethods = method || "card";
    data.order = basket;

    return firebase
      .app()
      .functions(region)
      .httpsCallable("getPaymentRequest")(data)
      .then((res) => {
        endHitPay();
        unsubscribePaymentRequest = paymentIntent.doc(res.data.id).onSnapshot(
          (doc) => {
            const data = doc.data() || {};
            if (data.status && data.status !== "processing") {
              // userAPI.refreshData(); // update credits in the client app
              callback(data);
              endHitPay();
            }
          },
          (error) => {
            console.warn(
              "data/store/getPaymentRequest/onSnapShot something went wrong in onSnapshot.",
              error
            );
            endHitPay();
          }
        );
        return res.data;
      }).catch((err) => {
        endHitPay();
        throw err;
      });
  };

  const endHitPay = () => {
    if (unsubscribePaymentRequest) {
      unsubscribePaymentRequest();
      unsubscribePaymentRequest = null;
    }
  };

  return {
    initiateStore,
    //getPaymentIntent,
    getPaymentRequest,
    endHitPay,
  };
})();

export default api;
