// librairies
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
// UI components
import { View, Text } from "react-native";
import Store from "./components/Store";
import { Button } from "../../components";
import { ScrollView } from "react-native-gesture-handler";

// app data
import { LanguageContext, StylesContext } from "../../data/contexts";
import routes from "../../navigation/routes";

export default function BuyCredits({ navigation }) {
  const [basket, setBasket] = useState({});
  const [sum, setSum] = useState(0);
  const lang = useContext(LanguageContext);
  const { BuyCredits: props } = useContext(StylesContext);
  const uiData = useSelector((state) => state.user["UIdata"] || {});

  const onPressPayment = (method) => {
    navigation.navigate({
      name: routes.HITPAY_PAYMENT,
      key: "ordering",
      params: { basket, method, sum },
    });
  };

  const updateBasket = (id, qty) => {
    const newBasket = { ...basket };
    newBasket[id] = qty;
    setBasket(newBasket);
    sumBasket(newBasket);
  };

  const sumBasket = (newBasket) => {
    let newSum = 0;
    Object.entries(newBasket).forEach((product) => {
      newSum += uiData.store[product[0]].price * product[1];
    });
    setSum(newSum / 100);
  };

  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      <View style={props.container}>
        <View style={props.rateCard.containerStyle}>
          <View style={props.store.headerStyle}>
            <Text {...props.store.headerText}>{lang.RATE_CARD}</Text>
          </View>
          <View style={props.rateCard.ratesWrapperStyle}>
            {Object.entries(uiData?.session_types).map(
              (session_type, index) => {
                return (
                  <View style={props.rateCard.rate.wrapperStyle} key={index}>
                    <Text {...props.rateCard.rate.label}>
                      {session_type[0]}
                    </Text>
                    <View>
                      <Text {...props.rateCard.rate.price}>{`${
                        session_type[1].value || 0
                      }`}</Text>
                      <Text {...props.rateCard.rate.currency}>{` ${
                        session_type[1].value > 1 ? "credits" : "credit"
                      }`}</Text>
                    </View>
                  </View>
                );
              }
            )}
          </View>
          <Text {...props.rateCard.warning}>{lang.RATE_WARNING}</Text>
        </View>

        <Store
          basket={basket}
          props={props.store}
          lang={lang}
          products={uiData.store}
          updateBasket={updateBasket}
        />
        <View style={props.sumUp.containerStyle}>
          <View style={props.sumUp.wrapperStyle}>
            <Text {...props.sumUp.label}>{lang.TO_PAY}</Text>
            <Text {...props.sumUp.details}>{lang.TO_PAY_DETAILS}</Text>
          </View>
          <Text {...props.sumUp.sum}>
            {lang.PRICE_BEFORE + sum + lang.PRICE_AFTER}
          </Text>
        </View>
        {sum > 0 ? (
          <>
            <Button
              {...props.paymentButton1}
              title={lang.PAYMENT_PAYNOW}
              onPress={() => onPressPayment("paynow_online")}
            />
            <Button
              {...props.paymentButton2}
              title={lang.PAYMENT_CREDITS}
              onPress={() => onPressPayment("card")}
            />
          </>
        ) : null}
      </View>
    </ScrollView>
  );
}

/* 

*/
