const colors = {
  // base colors
  primary: "#FF9800", // "#f2b606", ///"#FF9800",
  darkPrimary: "#f57c00",
  lightPrimary: "#ffe0b2",
  lighterPrimary: "#fff3e0",
  accent: "#00bcd4",
  darkAccent: "#0097a7",
  lightAccent: "#b2e2f2",
  lighterAccent: "#A1BCC4",
  // related to componnets
  background: "#ffffff",
  divider: "#bdbdbd",
  error: "#ff1900",
  tagline: "#ffffff",
  taglineShadow: "black", // "#ff0000",
  surface: "#fef7ed",
  warning: "#bd9292",
  cancelled: "#DDB1B1", //"#EDCDCC", //"#E8BFBE", //"#FFC3C2",
  // texts
  darkText: "#212121",
  text: "#303030", //"#606060", //"#323105", //"#212121",
  subText: "#555555", //"#959595",
  lightText: "#c0c0c0",
  offText: "#ffffff",
  // translucent/ transparent
  clearTranslucent: "rgba(255,255,255,0.2)",
  translucent: "rgba(255,255,255,0.7)",
  lighterTranslucent: "rgba(0,0,0,0.1)",
  lightTranslucent: "rgba(0,0,0,0.4)",
  darkTranslucent: "rgba(0,0,0,0.7)",
  transparent: "rgba(0,0,0,0)",
};

export default { colors };
