import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// components
import { Text, View } from "react-native";
import { Avatar } from "../../../components";

export default function PlayersList({
  avatarProps,
  avatarSize = 36,
  isPlayersEditor = false,
  fitInParentWidth = false,
  players = [],
  session,
  user,
  wrapperStyle,
}) {
  const avatars = useSelector((state) => state.user["avatars"] || {});
  const UIdata = useSelector((state) => state.user["UIdata"] || false);
  const [avatarShift, setAvatarShift] = useState(0);

  useEffect(() => {
    if (fitInParentWidth) {
      const sizeAvatarWithMargin = avatarSize;
      let shift =
        (fitInParentWidth - sizeAvatarWithMargin) / (players.length - 1);
      setAvatarShift(
        shift > 0 && shift < sizeAvatarWithMargin
          ? shift - sizeAvatarWithMargin
          : 0
      );
    } else setAvatarShift(0);
  }, [avatarSize, players, fitInParentWidth]);

  return (
    <View
      style={[
        {
          flex: 1,
          flexDirection: "row",
          flexWrap: fitInParentWidth ? "nowrap" : "wrap",
          justifyContent:
            avatarShift < 0 && avatarShift > 4 - avatarSize
              ? "flex-start"
              : "flex-end",
        },
        wrapperStyle,
      ]}
    >
      {(() => {
        if (
          fitInParentWidth &&
          fitInParentWidth < avatarSize &&
          players.length > 0
        ) {
          // return LINK or PLUS sign
          return <Text>{">"}</Text>;
        }
        if (avatarShift < 4 - avatarSize && players.length > 0) {
          // return NUMBER
          return (
            <Avatar
              avatar={players.length.toString()}
              size={avatarSize}
              {...avatarProps}
            />
          );
        }
        if (Array.isArray(players))
          return players.map((player, index) => {
            const borderColor = getBorderColor(
              avatarProps,
              isPlayersEditor,
              player,
              session,
              UIdata,
              user
            );
            return (
              <Avatar
                avatar={avatars[player.uid] || player.displayName || "?"}
                key={index}
                size={avatarSize}
                {...avatarProps}
                containerStyle={{
                  ...avatarProps.containerStyle,
                  marginLeft: index == 0 ? 0 : avatarShift,
                  borderColor,
                }}
              />
            );
          });
      })()}
    </View>
  );
}

const getBorderColor = (
  avatarProps,
  isPlayersEditor,
  player,
  session,
  UIdata,
  user
) => {
  let borderColor = avatarProps.playerColors.other;
  const isUser = player.uid == user.uid;
  const guestMember = (user.dependants || []).find(
    (member) => member.uid == player.uid
  );

  if (isUser) borderColor = avatarProps.playerColors.user;
  if (guestMember) borderColor = avatarProps.playerColors.tribe;

  if (isUser || guestMember || isPlayersEditor) {
    // color it
    const playerDetail =
      guestMember && guestMember.isGuest
        ? guestMember
        : (UIdata.users || {})[player.uid] || {};

    if (
      (session.age_group &&
        playerDetail.age_group &&
        session.age_group !== playerDetail.age_group) ||
      (session.category &&
        playerDetail.category &&
        session.category !== playerDetail.category)
    )
      borderColor = avatarProps.playerColors.unfit;
  }
  return borderColor;
};
