import React, { useRef, useState } from "react";
import {
  Animated,
  Image,
  ImageBackground,
  PanResponder,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import { useSelector } from "react-redux";
const ALLOWED_WIDE_SCREEN = ["administrator", "manager", "supervisor", "coach"];

export default function WebWrapper({ children }) {
  const [sideWidth, setSideWidth] = useState(null);
  const [screenWidth, setScreenWidth] = useState(560);
  const pan = useRef(new Animated.ValueXY()).current;
  const expansionAllowed = useSelector((state) => {
    const role = state?.user?.details?.role;
    if (ALLOWED_WIDE_SCREEN.includes(role)) return true;
    return false;
  });

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onPanResponderMove: Animated.event([
      null,
      {
        dx: pan.x, // x,y are Animated.Value
        dy: 0,
      },
    ]),
    onPanResponderRelease: () => {
      setScreenWidth(screenWidth + pan.x._value);
      pan.setValue({ x: 0, y: 0 });
    },
  });

  if (useWindowDimensions().width < 640)
    return <View style={{ flex: 1 }}>{children}</View>;
  else
    return (
      <View style={{ backgroundColor: "black", flex: 1 }}>
        <ImageBackground
          source={require("../styles/assets/california-2039344.jpg")}
          style={{ flex: 1, flexDirection: "row" }}
        >
          <View
            style={{
              marginLeft: "12%",
              marginVertical: 20,
              width: screenWidth,
              backgroundColor: "#212121",
              overflow: "hidden",
              padding: 16,
              borderRadius: 16,
            }}
          >
            {children}
          </View>
          {expansionAllowed ? (
            <Animated.View
              {...panResponder.panHandlers}
              style={[
                pan.getLayout(),
                {
                  backgroundColor: "white",
                  marginVertical: 36,
                  width: 8,
                  zIndex: 5,
                },
              ]}
            />
          ) : null}
          <View
            style={{
              flex: 1,
              margin: 40,
              justifyContent: "center",
              alignItems: "center",
            }}
            onLayout={(event) => setSideWidth(event.nativeEvent.layout.width)}
          >
            {sideWidth > 200 ? (
              <>
                <Text
                  style={{
                    fontSize: 42,
                    fontWeight: "bold",
                    backgroundColor: "rgba(255,255,255,0.5)",
                    color: "black",
                    borderColor: "black",
                    borderWidth: 12,
                    padding: 8,
                    paddingHorizontal: 40,
                    marginBottom: 60,
                    textAlign: "center",
                  }}
                >
                  {"COMING SOON"}
                </Text>
                <Text
                  style={{
                    color: "black",
                    overflow: "hidden",
                    fontSize: 32,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {
                    "You will be able to enjoy a better experience with the mobile app for iOS and Android."
                  }
                </Text>
                <View
                  style={
                    sideWidth > 460
                      ? {
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          padding: 10,
                        }
                      : { alignItems: "center", paddingTop: 20 }
                  }
                >
                  <Image
                    style={{
                      height: 60,
                      width: 60 * 3,
                      resizeMode: "contain",
                      margin: 20,
                    }}
                    source={require("../styles/assets/AppStore1xxxhdpi.png")}
                  />
                  <Image
                    style={{
                      height: 86,
                      width: 86 * 2.58,
                      resizeMode: "contain",
                      margin: 20,
                    }}
                    source={require("../styles/assets/google-play-badge.png")}
                  />
                </View>
              </>
            ) : null}
          </View>
        </ImageBackground>
      </View>
    );
}

/* 

{Platform.OS === "web" ? (
        <>
          <View
            style={{
              height: "100%",
              maxWidth: 2,
              flex: 1,
              backgroundColor: "black",
            }}
          />
          <View
            style={props.sideScreen}
            onLayout={(event) => setSideWidth(event.nativeEvent.layout.width)}
          >
            {sideWidth > 200 ? (
              <View>
                <Text
                  style={{ overflow: "hidden", height: "100%", width: "100%" }}
                >
                  {
                    "Enjoy a better experience with our mobile app for iOS and Android."
                  }
                </Text>
              </View>
            ) : null}
          </View>
        </>
      ) : null}



      {Platform.OS === "web" ? (
        <>
          <View
            style={{
              height: "100%",
              maxWidth: 2,
              flex: 1,
              backgroundColor: "black",
            }}
          />
          <View
            style={props.sideScreen}
            onLayout={(event) => setSideWidth(event.nativeEvent.layout.width)}
          >
            {sideWidth > 200 ? (
              <View>
                <Text
                  style={{ overflow: "hidden", height: "100%", width: "100%" }}
                >
                  {
                    "Enjoy a better experience with our mobile app for iOS and Android."
                  }
                </Text>
              </View>
            ) : null}
          </View>
        </>
      ) : null}

*/
