import "react-native-gesture-handler"; // to avoid some error, has to be first thing to do.
import React, { useCallback, useEffect, useState } from "react";
import { loadAsync } from "expo-font";
import { Image, LogBox, View, YellowBox } from "react-native";
import { AppearanceProvider } from "react-native-appearance";

// Hack for persistence for firestore on Android
require("./data/persistence");

// navigation
import { NavigationContainer } from "@react-navigation/native";
import HomeNavigator from "./navigation/HomeNavigator";
//import AdminNavigator from "./navigation/AdminNavigator";
import { enableScreens } from "react-native-screens";
import { navigationRef } from "./navigation/rootNavigation";
enableScreens();

// redux
import { Provider, useSelector } from "react-redux";
import configureStore from "./data/configureStore";

// rights to access device functionnalities
import * as ImagePicker from "expo-image-picker";

// App data
require("dayjs/locale/en");
import { fonts } from "./styles";

// Providers
import { ContextsProvider } from "./data/contexts";
import BackendProvider from "./data/backend";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { MenuProvider } from "react-native-popup-menu";

// UI Components
import AppScreen from "./screens/AppScreen";
//import AppLoading from "expo-app-loading";
import Welcome from "./screens/auth/Welcome";
import Loading from "./screens/Loading";
import WebWrapper from "./screens/WebWrapper";

// import AdminHome from "./screens/admin/AdminHome";

const store = configureStore();

if (LogBox) {
  // avoid many warning from firebase using timers set to very long time
  console.log("Ignore some logs whith LogBox.");
  LogBox.ignoreLogs(["Setting a timer"]);
} else {
  console.log("Ignore some logs whith YellowBox.");
  YellowBox.ignoreWarnings(["Setting a timer"]);
}

// wrap providers
export default function Main() {
  /* Permissions are Automatically asked at first use. Better for customer satisfaction.
  useEffect(() => {
    requestAppPermissions();
  }, []);

  const requestAppPermissions = useCallback(async () => {
    const {
      granted: cameraGranted,
    } = await ImagePicker.requestMediaLibraryPermissionsAsync(); // previously requestCameraRollPermissionsAsync();
    if (!cameraGranted)
      alert("You need to enable permission to access camera roll.");
  });
*/
  return (
    <Provider store={store}>
      <AppearanceProvider>
        <ContextsProvider>
          <BackendProvider storeDispatch={store.dispatch}>
            <SafeAreaProvider>
              <MenuProvider>
                <App />
              </MenuProvider>
            </SafeAreaProvider>
          </BackendProvider>
        </ContextsProvider>
      </AppearanceProvider>
    </Provider>
  );
}

const App = () => {
  const user = useSelector((state) => (state.user || {}).details);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function prepare() {
      try {
        await loadAsync({ ...fonts });
      } catch (e) {
        console.warn(e);
      } finally {
        setLoading(false);
      }
    }
    prepare();
  }, []);

  // If no user some async job are not done
  if (!user || loading)
    return (
      <View
        style={{
          alignItems: "center",
          justifyContent: "space-around",
          flex: 1,
          backgroundColor: "#000000",
        }}
      >
        <Image
          source={require("./styles/assets/logo.png")}
          style={{
            marginTop: 20,
            height: "50%",
            resizeMode: "contain",
            width: "80%",
          }}
        />
        <Loading />
      </View>
    );

  return (
    <NavigationContainer documentTitle={{ enabled: false }} ref={navigationRef}>
      <WebWrapper>
        {user.uid && user.emailVerified && !user.isAnonymous ? (
          <AppScreen>
            <HomeNavigator />
          </AppScreen>
        ) : (
          <Welcome />
        )}
      </WebWrapper>
    </NavigationContainer>
  );
};
