// actions types
const CONNECTION_CHANGE = "connectionChange";

// action creator
export const changeConnection = (userName) => ({
  type: connectionChange,
  payload: {
    isConnected,
  },
});

// reducer
export const networkReducer = (state = { isConnected: false }, action) => {
  switch (action.type) {
    case CONNECTION_CHANGE: {
      return { ...state, isConnected: action.payload.isConnected };
    }

    default:
      return state;
  }
};

// selectors
