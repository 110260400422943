// API
const api = (() => {
  let dispatch;
  let firebase;
  let admin;
  const ADMIN_DATA_PATH = "references/";

  const initiateAdmin = (storeDispatch, backend) => {
    firebase = backend;
    dispatch = storeDispatch;
    admin = firebase.firestore();
  };

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const getDataSet = (dataSet) => {
    return new Promise((resolve, reject) => {
      admin
        .collection(ADMIN_DATA_PATH + dataSet + "/data")
        .get()
        .then((results) => {
          const docs = [];
          results.forEach((doc) => {
            docs[doc.id] = doc.data();
          });
          dispatch(setAdminData(dataSet, docs));
          resolve(docs);
        })
        .catch((error) => {
          console.warn(
            "getDataSet failed to retrieve data from ",
            ADMIN_DATA_PATH + dataSet + "/data",
            " -> ",
            error
          );
          reject(error);
        });
    });
  };

  const getSchema = (dataSet) => {
    return new Promise((resolve, reject) => {
      admin
        .collection(ADMIN_DATA_PATH + dataSet + "/schema")
        .get()
        .then((results) => {
          const fields = {};
          results.forEach((field) => {
            fields[field.id] = field.data();
          });
          resolve(fields);
        })
        .catch((error) => reject(error));
    });
  };

  const getMetaData = (dataSet) => {
    return new Promise((resolve, reject) => {
      admin
        .doc(ADMIN_DATA_PATH + dataSet)
        .get()
        .then((docSnap) => resolve(docSnap.data()))
        .catch((error) => reject(error));
    });
  };

  const saveRecord = (dataSet, data) => {
    const dataNoUndefined = Object.entries(data)
      .filter((item) => item[1] !== undefined)
      .reduce((acc, cv) => {
        acc[cv[0]] = cv[1];
        return acc;
      }, {});
    return new Promise((resolve, reject) => {
      admin
        .collection(ADMIN_DATA_PATH + dataSet + "/data")
        .doc(dataNoUndefined.documentID)
        .set(dataNoUndefined)
        .then((docRef) => {
          const newData = [];
          newData[dataNoUndefined.documentID] = Object.keys(dataNoUndefined)
            .filter((key) => key != "documentID")
            .reduce((result, key) => {
              result[key] = data[key];
              return result;
            }, {});
          dispatch(addAdminData(dataSet, newData));
          resolve(docRef);
        })
        .catch((error) => reject(error));
    });
  };

  return {
    initiateAdmin,
    uuidv4,
    getDataSet,
    getSchema,
    getMetaData,
    saveRecord,
  };
})();
export default api;

// THE REDUX PART
// ********************************
// actions types
const SET_ADMIN_DATA = "setAdminData";
const ADD_ADMIN_DATA = "addAdminData";
const DEL_ADMIN_DATA = "delAdminData";

// action creator
const setAdminData = (dataSetName, data) => ({
  type: SET_ADMIN_DATA,
  payload: {
    dataSetName,
    data,
  },
});

const addAdminData = (dataSetName, data) => ({
  type: ADD_ADMIN_DATA,
  payload: {
    dataSetName,
    data,
  },
});

const delAdminData = (dataSetName, docID) => ({
  type: DEL_ADMIN_DATA,
  payload: {
    dataSetName,
    docID,
  },
});

// reducer
export const adminReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ADMIN_DATA: {
      let newData = {};
      newData[action.payload.dataSetName] = action.payload.data;
      return { ...state, ...newData };
    }
    case ADD_ADMIN_DATA: {
      const updatedData = {};
      updatedData[action.payload.dataSetName] = {
        ...state[action.payload.dataSetName],
        ...action.payload.data,
      };
      return {
        ...state,
        ...updatedData,
      };
    }
    case DEL_ADMIN_DATA: {
      const updatedData = {};
      updatedData[action.payload.dataSetName] = Object.keys(
        state[action.payload.dataSetName]
      )
        .filter((key) => key != action.payload.docID)
        .reduce((result, key) => {
          result[key] = state[action.payload.dataSetName][key];
          return result;
        }, {});
      return {
        ...state,
        ...updatedData,
      };
    }
    default:
      return state;
  }
};
