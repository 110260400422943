import React, { useState } from "react";
import { useSelector } from "react-redux";

// components
import { View, Text } from "react-native";
import { Button } from "../../../components";
// Forms
import { Formik } from "formik";
import { FormAvatar, FormInput, FormPicker } from "../../../components/forms";

export default function NewGuest({
  lang,
  onNewGuest,
  styles,
  initialGuest = {},
}) {
  const [adding, setAdding] = useState(false);
  const UIdata = useSelector((state) => state.user["UIdata"] || false);

  if (!UIdata) return null;
  return (
    <Formik
      enableReinitialize
      initialValues={{
        miniAvatar: initialGuest.miniAvatar || "",
        displayName: initialGuest.displayName || "",
        age_group: initialGuest.age_group || "",
        category: initialGuest.category || "",
      }}
      onSubmit={(values) => onSavePress(values)}
    >
      {({ values }) => {
        return (
          <View style={styles.container}>
            {adding ? (
              <View style={styles.form.wrapperStyle}>
                <View
                  style={{
                    alignItems: "center",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <FormAvatar
                    getMiniOnly={true}
                    avatarFieldName="miniAvatar"
                    miniAvatarFieldName="miniAvatar"
                    usernameFieldName="displayName"
                    {...styles.form.avatar}
                  />
                  <FormInput
                    autoCapitalize="none"
                    autoCorrect={false}
                    name="displayName"
                    placeholder={lang.DISPLAY_NAME_PLACEHOLDER}
                    {...styles.form.input}
                    value={values["displayName"]}
                  />
                  <Button
                    {...styles.buttonUpDown}
                    {...styles.buttonUp}
                    onPress={() => setAdding(!adding)}
                  />
                </View>
                <View
                  style={{
                    alignItems: "flex-end",
                    flexDirection: "row",
                    justifyContent: "space-between",

                    width: "100%",
                  }}
                >
                  <FormPicker
                    enabled
                    emptyValue={lang.NO_SELECTED_VALUE}
                    label={lang.AGE_GROUP}
                    name="age_group"
                    selectedValue={values["age_group"]}
                    valueList={UIdata["age_groups"].map((el) => el.label)}
                    {...styles.form.picker}
                  />
                  <FormPicker
                    enabled
                    emptyValue={lang.NO_SELECTED_VALUE}
                    label={lang.CATEGORY}
                    name="category"
                    selectedValue={values["category"]}
                    valueList={UIdata["categories"].map((el) => el.label)}
                    {...styles.form.picker}
                  />
                  <Button
                    {...styles.buttonAdd}
                    title={"add"}
                    onPress={() => onNewGuest(values)}
                  />
                </View>
              </View>
            ) : (
              <View style={styles.divider}>
                <View style={styles.textWrapper}>
                  <Text {...styles.textInfoNew}>{lang.ADD_GUEST_INFO}</Text>
                  <Text {...styles.textInfoDetails}>
                    {lang.ADD_GUEST_INFO_DETAILS}
                  </Text>
                  <Text {...styles.textInfoSelect}>
                    {lang.ADD_GUEST_OR_SELECT}
                  </Text>
                </View>
                <Button
                  {...styles.buttonUpDown}
                  onPress={() => setAdding(!adding)}
                />
              </View>
            )}
          </View>
        );
      }}
    </Formik>
  );
}
