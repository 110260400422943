import dayjs from "dayjs";
import userAPI from "./user";

dayjs.extend(require("dayjs/plugin/isoWeek"));
dayjs.extend(require("dayjs/plugin/customParseFormat"));

// API
const api = (() => {
  let dispatch;
  let firebase;
  let sessions;
  let region;

  const initiateSessions = (storeDispatch, backend, _region) => {
    firebase = backend;
    dispatch = storeDispatch;
    region = _region;
    sessions = firebase.firestore().collection("sessions");
  };

  const docIDfromDate = (date) => {
    return date.year() * 100 + (date.isoWeek() == 53 ? 0 : date.isoWeek());
  };

  const reloadSessionData = (sessionDate) => {
    // in fact have to reload and redispatch the whole week
    const date = sessionDateToDayjs(String(sessionDate));
    const docID = docIDfromDate(date);
    const monthCode = date.format("YYYYMM");
    loadWeekSessions(docID, monthCode);
  };

  const loadWeekSessions = (weekCode, monthCode) => {
    return sessions
      .doc(String(weekCode))
      .get()
      .then((weekSessions) => {
        const data = weekSessions.data();
        if (data) {
          const monthData = {};
          for (const key in data) {
            monthData["20" + key.slice(0, 4)] = {
              ...monthData["20" + key.slice(0, 4)],
              [key]: data[key],
            };
          }
          for (const key in monthData) {
            dispatch(setWeekSessions(key, monthData[key]));
          }
        }
      });
  };

  const loadMonthSessions = (month) => {
    let promises = [];
    // identify needed weeks
    const firstDayOfMonth = month.clone().date(1);
    const firstWeekToLoad = docIDfromDate(firstDayOfMonth);
    const monthCode = firstDayOfMonth.format("YYYYMM");
    const previousMonthCode = month.clone().date(-1).format("YYYYMM");
    const nextMonthCode = month.clone().date(32).format("YYYYMM");

    // const shortMonthCode = monthCode.slice(-4);
    // load weeks and dispatch
    for (
      let numWeek = firstWeekToLoad;
      numWeek < firstWeekToLoad + 6;
      numWeek++
    ) {
      promises.push(
        loadWeekSessions(String(numWeek), monthCode).catch((e) => {
          console.warn("failed to load week sessions: ", numWeek, e);
        })
      );
    }
    return Promise.all(promises);
  };

  const sessionDateToDayjs = (sessionDate, sessionTime) => {
    return dayjs(
      "" + sessionDate + sessionTime,
      sessionTime ? "YYMMDDHHmm" : "YYMMDD"
    );
  };

  const newSession = async (session) => {

    return firebase
      .app()
      .functions(region)
      .httpsCallable("newSession")({
        session,
      })
      .then(() => {
        const monthCode = "20" + session.date.slice(0, 4);
        dispatch(addSession(monthCode, session));
      });
  };

  const updateSession = async (oldSession, newSession) => {
    return firebase
      .app()
      .functions(region)
      .httpsCallable("updateSession")({
        oldSession,
        newSession,
      })
      .then(() => {
        const monthCode = "20" + newSession.date.slice(0, 4);
        dispatch(addSession(monthCode, newSession));
        // remove if necessary
        if (
          oldSession.date !== newSession.date ||
          oldSession.time !== newSession.time ||
          oldSession.location !== newSession.location ||
          (oldSession.court || "_") !== (newSession.court || "_")
        )
          dispatch(deleteSession(monthCode, oldSession));
      });
  };

  const cancelSession = async (session) => {
    return firebase
      .app()
      .functions(region)
      .httpsCallable("cancelSession")({
        session,
      })
      .then(() => {
        const monthCode = "20" + session.date.slice(0, 4);
        const nbPlayers =
          (session?.players?.length || 0) + (session?.waitlist?.length || 0);
        if (nbPlayers === 0) dispatch(deleteSession(monthCode, session));
        else dispatch(addSession(monthCode, session));
      });
  };

  const addPlayer = async (session, player) => {

    return firebase
      .app()
      .functions(region)
      .httpsCallable("addPlayer")({
        session,
        player,
      })
      .then(() => {
        // userAPI.reloadUserDetails(player);
        const monthCode = "20" + session.date.slice(0, 4);
        dispatch(addSession(monthCode, session));
      });
  };

  const removePlayer = async (session, player) => {
    return firebase
      .app()
      .functions(region)
      .httpsCallable("removePlayer")({
        session,
        player,
      })
      .then(() => {
        // userAPI.reloadUserDetails(player);
        const monthCode = "20" + session.date.slice(0, 4);
        dispatch(addSession(monthCode, session));
      });
  };

  // from time of format "h:m" returns "hhmm"
  /* const extractTime = (timeString) => {
    let time = timeString.trim();
    const hours = time.substring(0, time.indexOf(":"));
    const minutes = time.substring(time.indexOf(":") + 1);
    if (isNaN(hours) || isNaN(minutes)) return "";
    time =
      (hours.length == 1 ? "0" : "") +
      hours +
      (minutes.length == 1 ? "0" : "") +
      minutes;
    return time;
  };
*/

  // convert time from frontend format to backend format
  const timeFrontToBack = (timeString, is12 = true) => {
    let time = timeString.toLowerCase();
    let hours = time.substring(0, time.indexOf(":")).trim();
    if (isNaN(hours)) return "";
    let minutes = time.substring(time.indexOf(":") + 1).trim();
    if (isNaN(parseInt(minutes))) return "";
    if (is12) {
      if (minutes.endsWith("pm")) hours = parseInt(hours) + 12;
      if (hours === 24) hours = 0;
    }
    minutes = parseInt(minutes);
    return (hours * 100 + minutes).toString().padStart(4, "0");
  };

  // convert time from backend format to frontend format
  const timeBackToFront = (timeString, is12 = true) => {
    const hours = timeString.substring(0, 2);
    const minutes = timeString.substring(2);
    if (is12) {
      const h = parseInt(hours);
      if (h > 12) return `${(h - 12).toString()}:${minutes} pm`;
      if (h === 0) return `12:${minutes} pm`;
      return `${hours}:${minutes} am`;
    } else return `${hours}:${minutes}`;
  };

  // search if the provided session overlap a provided set of sessions
  const isSessionOverlap = (session, sessionsList) => {
    if (!session || !sessionsList) return false;
    const sessionDate = session.date;
    const sessionStartTime = parseInt(session.time);
    const sessionEndTime = parseInt(
      session.until ||
        (session.endTime
          ? timeFrontToBack(session.endTime, false)
          : session.time)
    );

    for (const key in sessionsList) {
      const _session = sessionsList[key];
      if (_session.date === sessionDate) {
        const _sessionStartTime = parseInt(_session.time);
        const _sessionEndTime = parseInt(
          _session.until ||
            (_session.endTime
              ? timeFrontToBack(_session.endTime, false)
              : _session.time)
        );
        if (
          sessionStartTime < _sessionEndTime &&
          sessionEndTime > _sessionStartTime
        )
          return true;
      }
    }
    return false;
  };

  const isPast = (encodedDateTime) => {
    const now = new Date();
    const encodedNow =
      (now.getFullYear() - 2000) * 10e7 +
      (now.getMonth() + 1) * 10e5 +
      now.getDate() * 10e3 +
      now.getHours() * 10e1 +
      now.getMinutes();
    return parseInt(encodedDateTime) < encodedNow;
  };

  const setCurrentSession = (data) => {
    dispatch(storeCurrentSession(data));
  };

  return {
    addPlayer,
    cancelSession,
    initiateSessions,
    isPast,
    isSessionOverlap,
    loadMonthSessions,
    newSession,
    reloadSessionData,
    removePlayer,
    sessionDateToDayjs,
    setCurrentSession,
    timeFrontToBack,
    timeBackToFront,
    updateSession,
  };
})();
export default api;

// THE REDUX PART
// ********************************
// actions types
const LOAD_WEEK_SESSIONS = "setWeekSessions";
const ADD_SESSION = "addSession";
const DELETE_SESSION = "deleteSession";
const SET_CURRENT_SESSION = "setCurrentSession";

// action creator
const setWeekSessions = (monthCode, data) => ({
  type: LOAD_WEEK_SESSIONS,
  payload: {
    monthCode,
    data,
  },
});
const addSession = (monthCode, data) => ({
  type: ADD_SESSION,
  payload: {
    monthCode,
    data,
  },
});
const deleteSession = (monthCode, data) => ({
  type: DELETE_SESSION,
  payload: {
    monthCode,
    data,
  },
});
const storeCurrentSession = (data) => ({
  type: SET_CURRENT_SESSION,
  payload: data,
});

// reducer
export const sessionReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_WEEK_SESSIONS: {
      let newValue = {};
      newValue[action.payload.monthCode] = {
        ...state[action.payload.monthCode],
        ...action.payload.data,
      };
      return { ...state, ...newValue };
    }
    case ADD_SESSION: {
      const data = action.payload.data;
      const monthCode = action.payload.monthCode;
      const newValue = {};
      newValue[monthCode] = { ...state[monthCode] };

      newValue[monthCode][data.date] = newValue[monthCode][data.date]
        ? { ...newValue[monthCode][data.date] }
        : {};

      newValue[monthCode][data.date][data.location] = newValue[monthCode][
        data.date
      ][data.location]
        ? { ...newValue[monthCode][data.date][data.location] }
        : {};

      newValue[monthCode][data.date][data.location][data.court] = newValue[
        monthCode
      ][data.date][data.location][data.court]
        ? { ...newValue[monthCode][data.date][data.location][data.court] }
        : {};

      newValue[monthCode][data.date][data.location][data.court][
        data.time
      ] = data;

      return { ...state, ...newValue };
    }

    case DELETE_SESSION: {
      const newState = {};
      for (let month in state) {
        for (let date in state[month]) {
          for (let location in state[month][date]) {
            for (let court in state[month][date][location]) {
              for (let time in state[month][date][location][court]) {
                if (
                  month !== action.payload.monthCode ||
                  date !== action.payload.data.date ||
                  location !== action.payload.data.location ||
                  court !== action.payload.data.court ||
                  time !== action.payload.data.time
                ) {
                  if (!newState[month]) newState[month] = {};
                  if (!newState[month][date]) newState[month][date] = {};
                  if (!newState[month][date][location])
                    newState[month][date][location] = {};
                  if (!newState[month][date][location][court])
                    newState[month][date][location][court] = {};

                  newState[month][date][location][court][time] =
                    state[month][date][location][court][time];
                }
              }
            }
          }
        }
      }
      return newState;
    }
    case SET_CURRENT_SESSION: {
      return { ...state, currentSession: action.payload };
    }

    default:
      return state;
  }
};
