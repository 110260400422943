export default ({ button, colors, typos }) => ({
  container: {
    margin: 20,
  },

  rateCard: {
    containerStyle: {
      borderRadius: 6,
      backgroundColor: colors.background,
      marginBottom: 20,
    },
    ratesWrapperStyle: {
      margin: 10,
    },
    rate: {
      wrapperStyle: {
        flexDirection: "row",
        marginVertical: 4,
        justifyContent: "space-between",
      },
      label: {
        style: {
          ...typos.titleText,
          alignSelf: "flex-start",
          color: colors.subText,
        },
      },
      price: {
        style: {
          ...typos.numbers,
          alignSelf: "flex-end",
          color: colors.subText,
        },
      },
      currency: {
        style: { ...typos.annotation, alignSelf: "flex-end" },
      },
    },
    warning: {
      numberOfLines: 1,
      style: {
        ...typos.annotation,
        textAlign: "center",
        backgroundColor: colors.primary,
        color: colors.offText,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        lineHeight: 28,
        height: 28,
        paddingHorizontal: 6,
        width: "100%",
      },
    },
  },

  store: {
    headerStyle: {
      backgroundColor: colors.primary,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      height: 28,
    },
    headerText: {
      numberOfLines: 1,
      style: {
        ...typos.titleText,
        color: colors.offText,
        lineHeight: 28,
        paddingHorizontal: 12,
      },
    },
    wrapperStyle: {
      backgroundColor: colors.background,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
    product: {
      containerStyle: {
        paddingHorizontal: 10,
      },
      divider: {
        height: 0,
        borderColor: colors.divider,
        borderTopWidth: 1,
        width: "100%",
      },
      image: {
        style: {
          backgroundColor: colors.lightPrimary,
          borderColor: colors.divider,
          borderWidth: 1,
          height: 60,
          width: 80,
        },
      },
      qty: {
        increaseButton: {
          style: {
            ...typos.titleText,
            backgroundColor: colors.primary,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            color: colors.offText,
            textAlign: "center",
          },
        },
        decreaseButton: {
          style: {
            ...typos.titleText,
            backgroundColor: colors.primary,
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            color: colors.offText,
            textAlign: "center",
          },
        },
        text: {
          style: {
            ...typos.titleText,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderColor: colors.primary,
            color: colors.text,
            textAlign: "center",
          },
        },
        wrapperStyle: { width: 22 },
      },
      texts: {
        details: {
          numberOfLines: 2,
          style: {
            ...typos.subTitleText,
            color: colors.text,
          },
        },
        headerStyle: {
          flexDirection: "row",
          justifyContent: "space-between",
        },

        label: {
          numberOfLines: 1,
          style: {
            ...typos.titleText,
            color: colors.text,
          },
        },
        price: {
          style: {
            ...typos.titleText,
            color: colors.darkPrimary,
          },
        },
        wrapperStyle: {
          flex: 1,
          marginHorizontal: 10,
        },
      },
      wrapperStyle: {
        flexDirection: "row",
        marginVertical: 10,
      },
    },
  },

  paymentButton1: {
    ...button,
    containerStyle: {
      ...button.containerStyle,
      marginTop: 20,
    },
    inactiveBackgroundColor: colors.accent,
  },
  paymentButton2: {
    ...button,
    containerStyle: {
      ...button.containerStyle,
      marginTop: 20,
    },
    inactiveBackgroundColor: colors.background,
    titleStyle: {
      ...button.titleStyle,
      color: colors.darkAccent,
    },
  },

  sumUp: {
    containerStyle: {
      marginBottom: 20,
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    details: {
      numberOfLines: 1,
      style: {
        ...typos.annotation,
        color: colors.offText,
        lineHeight: 28,
        marginLeft: 12,
      },
    },
    label: {
      style: {
        ...typos.titleText,
        color: colors.offText,
        marginTop: 2,
      },
    },

    sum: {
      style: {
        ...typos.titleText,
        borderWidth: 1,
        borderColor: colors.primary,
        backgroundColor: colors.background,
        borderBottomRightRadius: 6,
        color: colors.primary,
        lineHeight: 26,
        paddingHorizontal: 10,
        textAlign: "right",
        minWidth: 60,
      },
    },
    wrapperStyle: {
      borderWidth: 1,
      borderColor: colors.primary,
      backgroundColor: colors.primary,
      borderBottomLeftRadius: 6,
      flex: 1,
      flexDirection: "row",
      height: 28,
      paddingHorizontal: 10,
    },
  },
});
