// Libraries
import React, { useState } from "react";

// UI components
import { Text, View } from "react-native";
import { Button } from "../../../components";

// Forms
import { Formik } from "formik";
import { FormPicker } from "../../../components/forms";

export default function Filter({
  props,
  lang,
  UIdata,
  filter = {},
  setFilter,
}) {
  const [showFilter, setShowFilter] = useState(false);

  return (
    <View {...props.container}>
      {showFilter ? (
        <View style={{ width: "100%", justifyContent: "center" }}>
          <Formik
            enableReinitialize
            initialValues={{
              age_group: filter["age_group"] || lang.NO_SELECTED_VALUE_FILTER,
              category: filter["category"] || lang.NO_SELECTED_VALUE_FILTER,
              location: filter["session_type"] || lang.NO_SELECTED_VALUE_FILTER,
              location: filter["location"] || lang.NO_SELECTED_VALUE_FILTER,
            }}
          >
            {({ values }) => {
              return (
                <View style={props.pickerWrapperStyle}>
                  <FormPicker
                    emptyValue={lang.NO_SELECTED_VALUE_FILTER}
                    enabled={true}
                    label={lang.AGE_GROUP}
                    name="age_group"
                    onValueChange={(data) => {
                      let _data = data;
                      if (_data == lang.NO_SELECTED_VALUE_FILTER) _data = "";
                      setFilter({ ...filter, age_group: _data });
                    }}
                    selectedValue={values["age_group"]}
                    valueList={UIdata["age_groups"].map((el) => el.label)}
                    {...props.picker}
                  />
                  <FormPicker
                    emptyValue={lang.NO_SELECTED_VALUE_FILTER}
                    enabled={true}
                    label={lang.CATEGORY}
                    name="category"
                    onValueChange={(data) => {
                      let _data = data;
                      if (_data == lang.NO_SELECTED_VALUE_FILTER) _data = "";
                      setFilter({ ...filter, category: _data });
                    }}
                    selectedValue={values["category"]}
                    valueList={UIdata["categories"].map((el) => el.label)}
                    {...props.picker}
                  />
                  <FormPicker
                    emptyValue={lang.NO_SELECTED_VALUE_FILTER}
                    enabled={true}
                    label={lang.SESSION_TYPE}
                    name="session_type"
                    onValueChange={(data) => {
                      let _data = data;
                      if (_data == lang.NO_SELECTED_VALUE_FILTER) _data = "";
                      setFilter({ ...filter, session_type: _data });
                    }}
                    selectedValue={values["session_type"]}
                    valueList={Object.keys(UIdata["session_types"])}
                    {...props.picker}
                  />
                  <FormPicker
                    emptyValue={lang.NO_SELECTED_VALUE_FILTER}
                    enabled={true}
                    label={lang.LOCATION}
                    name="location"
                    onValueChange={(data) => {
                      let _data = data;
                      if (_data == lang.NO_SELECTED_VALUE_FILTER) _data = "";
                      setFilter({ ...filter, location: _data });
                    }}
                    selectedValue={values["location"]}
                    valueList={Object.keys(UIdata["locations"])}
                    {...props.picker}
                  />
                </View>
              );
            }}
          </Formik>
        </View>
      ) : (
        <View>
          <View style={props.textWrapperStyle}>
            <Text {...props.textTitleProps}>{lang.AGE_GROUP + " :  "}</Text>
            <Text
              {...(filter.age_group
                ? props.textValuePropsSelected
                : props.textValueProps)}
            >
              {!filter.age_group || filter.age_group == ""
                ? lang.NO_SELECTED_VALUE_FILTER
                : filter.age_group}
            </Text>
            <View />
            <Text {...props.textTitleProps}>{lang.CATEGORY + " :  "}</Text>
            <Text
              {...(filter.category
                ? {
                    ...props.textValuePropsSelected,
                    style: {
                      ...props.textValuePropsSelected.style,
                      backgroundColor: UIdata["categories"].find(
                        (el) => el.label === filter.category
                      )?.color,
                    },
                  }
                : props.textValueProps)}
            >
              {!filter.category || filter.category == ""
                ? lang.NO_SELECTED_VALUE_FILTER
                : filter.category}
            </Text>
          </View>
          <View style={props.textWrapperStyle}>
            <Text {...props.textTitleProps}>{lang.SESSION_TYPE + " :  "}</Text>
            <Text
              {...(filter.session_type
                ? props.textValuePropsSelected
                : props.textValueProps)}
            >
              {!filter.session_type || filter.session_type == ""
                ? lang.NO_SELECTED_VALUE_FILTER
                : filter.session_type}
            </Text>
          </View>
          <View style={props.textWrapperStyle}>
            <Text {...props.textTitleProps}>{lang.LOCATION + " :  "}</Text>
            <Text
              {...(filter.location
                ? props.textValuePropsSelected
                : props.textValueProps)}
            >
              {!filter.location || filter.location == ""
                ? lang.NO_SELECTED_VALUE_FILTER
                : filter.location}
            </Text>
          </View>
        </View>
      )}

      <Button
        {...props.expandButton}
        onPress={() => setShowFilter(!showFilter)}
        title={"filters"}
      />
    </View>
  );
}
