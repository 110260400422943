export default {
  ACCOUNT_DETAILS: "Account",
  ACTIVITY_TITLE: "Activities management",
  ADD_GUEST_INFO: "Add a new child or guest",
  ADD_GUEST_INFO_DETAILS: "(will be editable only from your account)",
  ADD_GUEST_OR_SELECT: "or Select a user below.",
  ADD_NEW_BUTTON: "Add a new record",
  ADD_PLAYER_FAILED: "Failed to add player. Try Again.",
  ADMIN_NAVIGATOR: "Admin",
  AGE_GROUP: "Age group",
  AGE_GROUPS: "age groups",
  ALREADY_ENROLLED: "No tribe member to add.",
  AVATAR_NEW_SHOT: "Camera",
  AVATAR_LIBRARY: "Photo library",
  BOOK_SESSION: "new booking",
  BOOK_SESSION_TITLE: "select a session",
  BUY_CREDITS: "buy credits",
  CAMERA_ACCESS_FAILED: "You need to enable permission to access camera roll.",
  CANCEL_CONFIRM_TITLE: "Confirm cancellation",
  CANCEL_CONFIRM_MESSAGE:
    "Do you confirm cancellation of this session ? This action is irrevocable.",
  CANCEL_CONFIRM_BTN_YES: "Yes",
  CANCEL_CONFIRM_BTN_NO: "No",
  CANCEL_BOOKING: "cancel booking",
  CANCEL_SESSION: "cancel session",
  CANCELLATION_FAILED: "cancellation failed. try again.",
  CATEGORY: "Category",
  CATEGORIES: "categories",
  CHANGES_NOT_SAVED: "Changes have not been saved.",
  CLOSE: "close",
  COACH: "Coach",
  CONDITIONS: `by signing up you agree to our terms, conditions and policies. 
    Press to read them.`,
  CONDITIONS_END: "GO BACK TO SIGNUP",
  COURT: "court",
  CREDITS: "credits",
  CREDITS_COMMENTS: "Comments (mandatory)",
  CREDITS_TEXT: "your credits",
  CREDITS_UPDATE: "update credits",
  DEFAULT_DATA_EDITOR_TITLE: "Data Editor",
  DEFAULT_DATA_LIST_TITLE: "Data List",
  DELETE_BUTTON_YES: "delete",
  DELETE_BUTTON_CANCEL: "cancel",
  DELETE_CONFIRMATION_TITLE: "Confirmation required",
  DELETE_CONFIRMATION_MESSAGE:
    "You're about to delete this record. Please confirm.",
  DELETE_FAILED: "Something went wrong ! Delete operation failed.",
  DELETE_SUCCEEDED: "deleted succesfully",
  DISPLAY_NAME_LABEL: "Username",
  DISPLAY_NAME_PLACEHOLDER: "displayed name",
  DISPLAY_NAME_ERROR_REQUIRED: "Required",
  DISPLAY_NAME_ERROR_MIN: "Minimum 3 characters.",
  DISPLAY_NAME_ERROR_MATCHES: "Only letters, digits, space and hyphen allowed.",
  EDITING: "Editing ",
  EMAIL_PLACEHOLDER: "email address",
  EMAIL_ERROR: "Wrong format of email address",
  ERROR_LOGIN: "Login failed. Wrong credentials.",
  ERROR_REGISTER_DISPLAYNAME: "Username invalid or already registered.",
  ERROR_REGISTER_EMAIL: "Email already registered.",
  ERROR_REGISTER_OTHER: "Sign up failed ! Try again.",
  ERROR_UNEXPECTED: "You have found a glitch in the matrix !",
  ERROR_USER_DISABLED: "User disabled.",
  ERROR_USER_NOT_FOUND: "User not found.",
  ERROR_VERIFCODE_EXPIRED: "Verification code expired.",
  ERROR_VERIFCODE_INVALID: "Verification code invalid.",
  EVENT_STARTS: "this event starts ",
  EVENT_WAS: "this event was ",
  FILL_ACCOUNT: "Please fill out your information.",
  FORGOTTEN_PASSWORD: "forgot it ?",
  FROM: "from",
  GUEST: "GUEST",
  HOME: "Schedule",
  INTRO_LABEL: "About you",
  INTRO_PLACEHOLDER: "tell us about yourself",
  LOCATION: "Location",
  LOCATIONS: "locations",
  LOGIN: "sign in",
  LOGO_TEXT: "Singapore",
  LOG_OUT: "Log out",
  MANAGE: "Manage sessions",
  MAX_PLAYERS: "# players",
  MAX_WAITLIST: "# in waiting list",
  MEMBER_REGISTERED: "browse registered users",
  MEMBER_UNREGISTERED: "new unregistered member",
  MONTHS_SHORT: [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ],
  MONTHS_LONG: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  NEW_RECORD: "New in ",
  NEW_SESSION: "new session",
  NO_BOOKING: "No scheduled session this month.",
  NOTES_LABEL: "Coach notes",
  NO_FILTER: "no active filter",
  NO_SELECTED_VALUE: "",
  NO_SELECTED_VALUE_FILTER: "all",
  NO_SPOT_LEFT: "no spot left",
  NOT_ENOUGH_CREDITS: "Not enough credits.",
  ONE_SPOT_LEFT: "1 spot left",
  PASSWORD_PLACEHOLDER: "password",
  PASSWORD_ERROR_MIN: "Minimum 8 characters",
  PAYMENT_CARD: "card payment",
  PAYMENT_GO: "pay ",
  PAYMENT_PAYNOW: "pay with PayNow",
  PAYMENT_CREDITS: "pay by Credit cards",
  PAYMENT_REQUEST_FAILED: "failed to reach payment platform",
  PAYMENT_FAILED: "payment request cancelled",
  PAYMENT_SUCCEEDED: "payment succeeded",
  PHONE_NUMBER_ERROR: "invalid phone number",
  PHONE_NUMBER_LABEL: "Phone number",
  PHONE_NUMBER_PLACEHOLDER: "to stay in touch",
  PLANNING: "manage",
  PLAYERS: "Players",
  PRICE_BEFORE: "$",
  PRICE_AFTER: "",
  RATE_CARD: "rate card",
  RATE_WARNING: "specific session rate may be occasionally applied.",
  REMOVE_PLAYER_FAILED: "Failed to cancel player. Try Again.",
  RESET_PWD_TEXT:
    "We sent you an email to reset your password. Please check and follow the instructions.",
  RESET_PWD_BUTTON: "change password",
  RESET_PWD_CODE: "verification code",
  RESET_PWD_PWD: "new password",
  ROLE: "Role",
  RULES: "rules",
  SAVE: "Save",
  SAVE_BUTTON_NEW: "Save",
  SAVE_BUTTON_UPDATE: "Save changes",
  SAVE_FAILED: "Something went wrong ! Save operation failed.",
  SAVE_SUCCEEDED: "saved successfully",
  SEARCH_USER: "search user",
  SESSION_CANCELLED: "SESSION CANCELLED",
  SESSION_DETAILS: "Details",
  SEVERAL_SPOT_LEFT: " spots left",
  SESSION_TYPE:"Session type",
  SESSION_TYPES: "session types",
  SESSION_TYPE_UNDEFINED: "undefined session type",
  SIGNUP: "register",
  SUBMIT: "Save",
  SWITCH_TO_LOGIN: "already registered ?",
  SWITCH_TO_SIGNUP: "not yet registered ?",
  TAGLINE: "Singapore", //'"life is a beach"',
  TITLE_ADMIN_MANAGEMENT: "Admin management tools",
  TITLE_SESSION: "Beach Volleyball course",
  TO: "to",
  TO_PAY: "pay",
  TO_PAY_DETAILS: "(GST included, excluding payment fee)",
  TRIBE_ADD: "Add member",
  TRIBE_INFO: "Add members to your tribe in order to book for them.",
  TRIBE_TITLE: "tribe",
  UNDEFINED: "",
  UNVERIFIED_EXPLAIN:
    "We just sent you an email to confirm your registration. Please check and follow the instructions.",
  UNVERIFIED_GREETINGS: "We are very happy you joined Beach Tribes.",
  UNVERIFIED_RESEND: "To resend the confirmation email, please click below.",
  UNVERIFIED_RESEND_BUTTON: "verify email",
  UNVERIFIED_WELCOME: "Welcome ",
  USER_NAME_LABEL: "Full name",
  USER_NAME_PLACEHOLDER: "first name and Last Name",
  USERNAME_PLACEHOLDER: "pick a username",
  USERS: "users",
  USERS_TITLE: "Users management",
  WAITING_LIST: "waiting list",
  WEEK: "Week",
  YOUR_BOOKINGS: "your schedule",
  LEGAL: {
    TC: {
      label: "Terms and Conditions",
      content: ``,
    },
    PP: {
      label: "Privacy Policy",
      content: ``,
    },
    DPP: {
      label: "Data Protection Policy",
      content: ``,
    },
    RP: {
      label: "Refund Policy",
      content: `<p>Thanks for using our services operated by Beach Tribes Pte Ltd. 

                We offer a full refund of unused credits bought in the last 60 days on our app.
                After 60 days, you will no longer be eligible and won't be able to receive a refund.

                If you have any additional questions or would like to request a refund, feel free to contact us.
                </p>`,
    },
  },
};
