export default {
  Rendang: require("../../assets/fonts/Rendang.ttf"),
  Droid: require("../../assets/fonts/DroidSerif-Regular.ttf"),
  DroidBold: require("../../assets/fonts/DroidSerif-Bold.ttf"),
  DroidItalic: require("../../assets/fonts/DroidSerif-Italic.ttf"),
  Roboto: require("../../assets/fonts/Roboto-Regular.ttf"),
  RobotoLight: require("../../assets/fonts/Roboto-Light.ttf"),
  RobotoMedium: require("../../assets/fonts/Roboto-Medium.ttf"),
  RobotoItalic: require("../../assets/fonts/Roboto-Italic.ttf"),
  RobotoLightItalic: require("../../assets/fonts/Roboto-LightItalic.ttf"),
  RobotoBold: require("../../assets/fonts/Roboto-Bold.ttf"),
};
