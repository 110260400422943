export default ({ button, colors, input, inputDisabled, typos }) => ({
  buttonSave: {
    ...button,
    containerStyle: {
      ...button.containerStyle,
      marginHorizontal: 10,
      marginBottom: 20,
    },
  },

  containerStyle: {
    flex: 1,
    backgroundColor: colors.translucent,
    paddingHorizontal: 10,
  },

  checkBox: {
    checkedColor: colors.error,
    containerStyle: {
      alignItems: "center",
    },
    labelStyle: {
      ...typos.subTitleText,
      color: colors.subText,
      fontWeight: "bold",
      fontSize: 16,
      textAlign: "center",
    },
  },

  deleteButton: {
    buttonStyle: {
      backgroundColor: "rbga(0,0,0,0)",
      borderColor: "cyan",
      borderRadius: 20,
      height: 38,
      justifyContent: "center",
      padding: 0,
      width: 38,
    },
    containerStyle: {
      alignSelf: "flex-end",
      backgroundColor: colors.white,
      borderWidth: 0,
      marginTop: 10,
      marginRight: 10,
    },

    icon: {
      // backgroundColor: "green",
      name: "trash",
      type: "simple-line-icon",
      size: 24,
      color: "gray",
      style: { width: 24 },
    },
  },

  formArray: {
    container: { marginTop: 8, marginHorizontal: 10 },
    addContainer: {
      flexDirection: "row",
      height: 30,
      justifyContent: "space-between",
      alignItems: "center",
    },
    input: {
      ...input,
      inputStyle: {
        ...input.inputStyle,
        color: colors.text,
        paddingHorizontal: 8,
      },
      containerStyle: { flex: 1, paddingHorizontal: 0 },
      labelStyle: {
        ...typos.subTitleText,
        color: colors.subText,
        borderWidth: 0,
        fontSize: 16,
      },
    },
    addIcon: {
      color: colors.darkAccent,
      containerStyle: { marginHorizontal: 10 },
    },
    itemsContainer: { marginBottom: 10, marginTop: 4 },
    itemContainer: {
      paddingHorizontal: 10,
      paddingVertical: 2,
      borderWidth: 1,
      borderColor: colors.divider,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    itemText: {},
    delIcon: {},
  },

  input: {
    ...input,
    inputStyle: {
      ...input.inputStyle,
      color: colors.text,
      paddingHorizontal: 8,
      maxHeight: 48,
    },
    labelStyle: {
      ...typos.subTitleText,
      color: colors.subText,
      borderWidth: 0,
      fontSize: 16,
    },
    inputContainerStyle: {
      backgroundColor: colors.background,
      borderBottomWidth: 0,
      borderRadius: 4,
      height: 48,
    },
  },
  inputDisabled: {
    ...input,
    inputStyle: {
      ...input.inputStyle,
      color: colors.text,
      paddingHorizontal: 8,
      maxHeight: 48,
    },
    labelStyle: {
      ...typos.subTitleText,
      color: colors.subText,
      borderWidth: 0,
      fontSize: 16,
    },
    inputContainerStyle: {
      backgroundColor: colors.background,
      borderBottomWidth: 0,
      borderRadius: 4,
      height: 48,
    },
  },
});
