import React from "react";

const navigationRef = React.createRef();

const navigate = (name, params) => {
  navigationRef.current?.navigate(name, params);
};

const navChange = (() => {
  let listener;
  const callbacks = new Set();

  const listen = (callback) => {
    callbacks.add(callback);
    if (!listener)
      listener = navigationRef.current?.addListener("state", (data) => {
        callbacks.forEach((_callback) => _callback(data));
      });
  };

  const stopListening = (callback) => {
    callbacks.delete(callback);
    if (!callbacks.size && listener) {
      listener();
      listener = null;
    }
  };

  return { listen, stopListening };
})();

export { navigate, navigationRef, navChange };
