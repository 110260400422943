import React, { useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import dayjs from "dayjs";

import DayView from "./DayView";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";

dayjs.extend(require("dayjs/plugin/isoWeek"));
dayjs.extend(require("dayjs/plugin/isToday"));

export default function MonthView({
  scheduling,
  containerProps,
  clipboard,
  dayProps,
  isSessionEditor,
  isPlayersEditor,
  month,
  noBookingText,
  noBookingStyle,
  onNewSession,
  onDayCopy,
  onPaste,
  onSelectSession,
  onSessionCopy,
  onWeekCopy,
  sessions,
  sessionsProps,
  todayProps,
  weekLocalized,
  weekProps = {},
  wrapperStyle,
}) {
  const [nbDays, setNbDays] = useState(0);
  const [todayY, setTodayY] = useState(0);
  const [scrollViewRef, setScrollViewRef] = useState(null);
  const [scrollToDate, setScrollToDate] = useState(false);

  useEffect(() => {
    if (month) {
      const lastDayOfMonth = new Date(month.year(), month.month() + 1, 0);
      setNbDays(lastDayOfMonth.getDate());
      setTodayY(0);
      setScrollToDate(true);
    }
  }, [month]);

  useEffect(() => {
    if (scrollViewRef) scrollViewRef.scrollTo({ x: 0, y: todayY });
  }, [todayY]);

  return (
    <View style={wrapperStyle}>
      <ScrollView
        ref={(ref) => setScrollViewRef(ref)}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <View {...containerProps}>
          {!scheduling && Object.keys(sessions).length < 1 ? (
            <Text style={noBookingStyle}>{noBookingText}</Text>
          ) : null}
          {[...Array(nbDays).keys()].map((item) => {
            const ref = item + 1;
            const currentDay = month.date(ref);
            const jsx = [];
            if (scheduling && currentDay.isoWeekday() == 1)
              if (isSessionEditor)
                jsx.push(
                  <View key={"w" + ref} {...weekProps.container}>
                    <View {...weekProps.separator} />
                    <Menu>
                      <MenuTrigger>
                        <Text style={weekProps.textStyle}>
                          {weekLocalized + " " + currentDay.isoWeek()}
                        </Text>
                      </MenuTrigger>
                      <MenuOptions
                        customStyles={{
                          optionsContainer: {
                            borderRadius: 6,
                            borderWidth: 1,
                            borderColor: "white",
                          },
                          optionWrapper: {
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            borderColor: "rgba(0, 0, 0, 0.3)",
                            borderBottomWidth: 1,
                            marginHorizontal: 3,
                            paddingVertical: 6,
                          },
                        }}
                      >
                        <MenuOption
                          customStyles={{
                            optionWrapper: {
                              alignItems: "center",
                              backgroundColor: "#F2CB05",
                              borderTopLeftRadius: 6,
                              borderTopRightRadius: 6,
                            },
                            optionText: { color: "white" },
                          }}
                          disabled
                          text={"week " + currentDay.isoWeek()}
                        />
                        <MenuOption
                          text="copy"
                          onSelect={() => onWeekCopy(currentDay)}
                        />
                        {!clipboard.content ||
                        (clipboard.type == "week" &&
                          clipboard.sessionDate == currentDay) ? null : (
                          <MenuOption
                            text="paste"
                            onSelect={() => onPaste(currentDay)}
                          />
                        )}
                      </MenuOptions>
                    </Menu>
                  </View>
                );
              else
                jsx.push(
                  <View key={"w" + ref} {...weekProps.container}>
                    <View {...weekProps.separator} />
                    <Text style={weekProps.textStyle}>
                      {weekLocalized + " " + currentDay.isoWeek()}
                    </Text>
                  </View>
                );
            if (scheduling || sessions[currentDay.format("YYMMDD")])
              jsx.push(
                <View
                  key={ref}
                  onLayout={(event) => {
                    if (currentDay.isToday() && scrollToDate) {
                      setTodayY(event.nativeEvent.layout.y);
                      setScrollToDate(false);
                    }
                  }}
                >
                  <DayView
                    scheduling={scheduling}
                    clipboard={clipboard}
                    date={currentDay}
                    dayProps={{
                      ...dayProps,
                      ...(currentDay.isToday() ? todayProps : null),
                    }}
                    isSessionEditor={isSessionEditor}
                    isPlayersEditor={isPlayersEditor}
                    onNewSession={onNewSession}
                    onDayCopy={onDayCopy}
                    onPaste={onPaste}
                    onSelectSession={onSelectSession}
                    onSessionCopy={onSessionCopy}
                    sessions={sessions[currentDay.format("YYMMDD")]}
                    sessionsProps={sessionsProps}
                  />
                </View>
              );
            return jsx;
          })}
        </View>

        <View style={{ height: 20, width: "100%" }}></View>
      </ScrollView>
    </View>
  );
}
