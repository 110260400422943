// librairies
import React, { useContext } from "react";
import { useSelector } from "react-redux";

// UI components
import Agenda from "../sessions/Agenda";
import { View } from "react-native";
import WebMessage from "./components/WebMessage";

// App data
import { LanguageContext, StylesContext } from "../../data/contexts";

export default function Home() {
  const lang = useContext(LanguageContext);
  const { Home: props } = useContext(StylesContext);

  const messages = useSelector(
    (state) => (state.user["UIdata"] || {})["messages"]
  );

  return (
    <View style={props.containerStyle}>
      <WebMessage messages={messages} styles={props.messages} />
      <Agenda scheduling={false} filtering={false} title={lang.YOUR_BOOKINGS} />
    </View>
  );
}
