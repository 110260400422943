import defaultTheme from "./default";

const palette = {
  ...defaultTheme.palette,
};

const colors = {
  ...defaultTheme.colors,
};

export default { colors, palette };
