export default ({ colors }) => ({
  annotation: {
    color: colors.text,
    fontFamily: "RobotoLight",
    fontSize: 11,
  },
  buttonText: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "center",
    textAlignVertical: "center",
  },
  regularText: {
    color: colors.text,
    fontFamily: "Roboto",
    fontSize: 12,
  },
  titleText: {
    color: colors.darkText,
    fontFamily: "RobotoMedium",
    fontSize: 16,
  },
  inputText: {
    color: colors.darkText,
    fontFamily: "Roboto",
    fontSize: 16,
  },
  subTitleText: {
    color: colors.text,
    fontSize: 14,
    fontFamily: "RobotoItalic",
  },
  numbers: {
    color: colors.text,
    fontSize: 16,
    fontFamily: "DroidBold",
  },
});

/* 
tagline
          fontSize: 9,
      fontFamily: "Rendang",
filterText: {
    fontSize: 12,
    fontFamily: "RobotoItalic",
  },
103 day
            fontSize: 14,
        fontFamily: "RobotoLightItalic",
125 week
            fontSize: 14,
        fontFamily: "RobotoItalic",
228 month
            fontFamily: "Roboto",
        fontSize: 18,
250 location
            fontSize: 14,
        fontFamily: "RobotoMedium",
300 category
                fontSize: 12,
            fontFamily: "DroidItalic",
351 time
              fontFamily: "Roboto",
          fontSize: 14,
*/
