export default ({ animations, colors, typos }) => ({
  animations,
  containerStyle: {
    flex: 1,
  },
  cardForm: {
    wrapperStyle: {
      backgroundColor: colors.background,
      borderRadius: 6,
      flex: 1,
      margin: 20,
    },
    headerStyle: {
      backgroundColor: colors.primary,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      height: 28,
    },
    headerText: {
      numberOfLines: 1,
      style: {
        ...typos.titleText,
        color: colors.offText,
        lineHeight: 28,
        paddingHorizontal: 12,
      },
    },
    webviewWrapperStyle: {
      flex: 1,
      // margin: 10,
    },
  },
});
