import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";

export default function MenuItem({
  activeColor = "black",
  activeOpacity = 0.7,
  backgroundColor = "white",
  color = "gray",
  containerStyle,
  height = 30,
  separatorColor = "#f0f0f0",
  onPress,
  target,
  children,
}) {
  const menuItemPress = () => {
    onPress
      ? onPress(target ? target : null)
      : console.warn("Menu pressed, but no action provided.");
  };

  return (
    <View style={containerStyle}>
      <View style={{ backgroundColor: activeColor }}>
        <TouchableOpacity activeOpacity={activeOpacity} onPress={menuItemPress}>
          <View style={{ backgroundColor: backgroundColor }}>
            <View
              style={[
                {
                  borderBottomWidth: separatorColor == "none" ? 0 : 1,
                  borderBottomColor: separatorColor,
                  borderTopWidth: separatorColor == "none" ? 0 : 1,
                  borderTopColor: separatorColor,
                },
                styles.container,
              ]}
            >
              <View style={styles.label}>{children}</View>
              <View
                style={{
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Icon {...styles.icon} color={color} size={height} />
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = {
  container: {
    marginTop: -1,
    // paddingHorizontal: 5,
    paddingBottom: 2,
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
  },
  icon: {
    name: "chevron-right",
    type: "material-community",
  },
  label: {
    justifyContent: "center",
    flex: 1,
  },
};
