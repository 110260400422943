import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";

export default function DataSetItem({
  id = "undefined",
  label = "undefined",
  style = {},
  tags,
  tagStyle = {},
  touchableProps = {},
  onPress,
  ...otherprops
}) {
  const onItemPress = () => {
    onPress
      ? onPress(id ? id : null)
      : console.warn("DataSetItem pressed, but no action provided.");
  };

  return (
    <View style={{ backgroundColor: touchableProps.activeColor || "gray" }}>
      <TouchableOpacity
        activeOpacity={touchableProps.activeOpacity || 0.7}
        onPress={onItemPress}
      >
        <View
          style={{ backgroundColor: touchableProps.inactiveColor || "white" }}
        >
          <View style={styles.itemHeader}>
            <View style={{ flex: 1, left: 0 }}>
              <Text
                style={[
                  styles.itemHeaderText,
                  { color: style.dimmedColor || styles.itemHeaderText.color },
                ]}
              >
                {id}
              </Text>
            </View>
            {tags
              ? tags.map((item) =>
                  item ? (
                    <View
                      style={[
                        styles.tag.style,
                        tagStyle.style,
                        { backgroundColor: item.color },
                      ]}
                      key={item.field}
                    >
                      <Text style={[styles.tag.textStyle, tagStyle.textStyle]}>
                        {item.field}
                      </Text>
                    </View>
                  ) : null
                )
              : null}
          </View>
          <View
            style={[
              styles.itemContainer,
              { borderBottomColor: style.separatorColor },
            ]}
          >
            <View style={styles.label}>
              <Text
                style={[
                  styles.labelText,
                  { color: style.fullColor || styles.itemHeaderText.color },
                ]}
              >
                {label}
              </Text>
            </View>
            <View style={styles.iconContainer}>
              <Icon
                {...styles.icon}
                color={style.dimmedColor || styles.icon.color}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = {
  itemContainer: {
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: "#666666",
    paddingHorizontal: 5,
    paddingBottom: 2,
    flexDirection: "row",
    justifyContent: "center",
  },
  icon: {
    color: "#999999",
    name: "chevron-right",
    type: "material-community",
    size: 30,
  },
  iconContainer: {
    height: 22,
    top: -12,
    width: 22,
  },
  itemHeader: {
    flex: 1,
    flexDirection: "row",
    paddingLeft: 15,
    paddingRight: 50,
    justifyContent: "flex-end",
  },
  itemHeaderText: {
    fontSize: 12,
    color: "#999999",
    left: 0,
    fontStyle: "italic",
  },
  label: {
    width: "100%",
    flex: 2,
    paddingHorizontal: 2,
    paddingLeft: 30,
    justifyContent: "center",
  },
  labelText: {
    fontSize: 15,
    color: "black",
  },
  tag: {
    style: {
      paddingHorizontal: 8,
      marginVertical: 1,
      borderRadius: 10,
    },
    textStyle: {
      fontSize: 10,
      color: "white",
    },
  },
};
