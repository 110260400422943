const minWidth = { minWidth: 320 };
const maxWidth = { maxWidth: 2048 };

export default ({ colors }) => ({
  fullScreen: {
    alignItems: "center",
    backgroundColor: colors.text,
    height: "100%",
    overflow: "hidden",
    width: "100%",
    flexDirection: "row",
  },

  screen: {
    backgroundColor: "black", //colors.divider,
    height: "100%",
    ...minWidth,
    ...maxWidth,
    width: "100%",
  },

  usedScreen: {
    // backgroundColor: "blue", // colors.background, //"rgb(220,220,220)",
    width: "100%",
    height: "100%",
    ...minWidth,
    ...maxWidth,
  },
});
