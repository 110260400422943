import React, { useState } from "react";
// components
import { View, Text } from "react-native";
import { Avatar, Button } from "../../../components";
import { Icon } from "react-native-elements";
// Forms
import { Formik } from "formik";
import { FormAvatar, FormInput, FormPicker } from "../../../components/forms";
import Swipeable from "react-native-gesture-handler/Swipeable";

export default function Member({
  avatar,
  editing,
  isDividerVisible,
  lang,
  member,
  onDelete,
  onUpdate,
  styles,
  UIdata,
}) {
  const [expanded, setExpanded] = useState(false);

  if (expanded)
    return (
      <View style={[isDividerVisible ? styles.divider : null]}>
        <View style={styles.containerExpanded}>
          <Formik
            enableReinitialize
            initialValues={{
              miniAvatar: member.miniAvatar || "",
              displayName: member.displayName || "",
              age_group: member.age_group || "",
              category: member.category || "",
            }}
            onSubmit={(values) => onSavePress(values)}
          >
            {({ values }) => {
              return (
                <View style={styles.form.wrapperStyle}>
                  <View
                    style={{
                      alignItems: "center",
                      flex: 1,
                      flexDirection: "row",
                    }}
                  >
                    <FormAvatar
                      enabled={editing}
                      getMiniOnly={true}
                      miniAvatarFieldName="miniAvatar"
                      name="miniAvatar"
                      onValueChange={(data) => onUpdate({ miniAvatar: data })}
                      usernameFieldName="displayName"
                      {...styles.form.avatar}
                    />
                    <FormInput
                      autoCapitalize="none"
                      autoCorrect={false}
                      disabledStyle={{
                        containerStyle: {
                          ...styles.form.input.containerStyle,
                          paddingTop: 3,
                          paddingLeft: 3,
                        },
                      }}
                      enabled={false}
                      name="displayName"
                      {...styles.form.input}
                      value={values["displayName"]}
                    />
                    <Button
                      {...styles.contract}
                      onPress={() => setExpanded(false)}
                    />
                  </View>
                  <View style={{ flex: 1, flexDirection: "row" }}>
                    <FormPicker
                      enabled={editing}
                      emptyValue={lang.NO_SELECTED_VALUE}
                      label={lang.AGE_GROUP}
                      name="age_group"
                      onValueChange={(data) => onUpdate({ age_group: data })}
                      selectedValue={values["age_group"]}
                      valueList={UIdata["age_groups"].map((el) => el.label)}
                      {...styles.form.picker}
                    />
                    <View style={{ width: 4 }} />
                    <FormPicker
                      disabledStyle={styles.form.pickerDisabled}
                      enabled={editing && !member.category}
                      emptyValue={lang.NO_SELECTED_VALUE}
                      label={lang.CATEGORY}
                      name="category"
                      onValueChange={(data) => onUpdate({ category: data })}
                      selectedValue={values["category"]}
                      valueList={UIdata["categories"].map((el) => el.label)}
                      {...styles.form.picker}
                    />
                  </View>
                </View>
              );
            }}
          </Formik>
        </View>
      </View>
    );
  else
    return (
      <View style={[isDividerVisible ? styles.divider : null]}>
        <Swipeable
          childrenContainerStyle={styles.swipeable.childContainerStyle}
          containerStyle={{ width: "100%" }}
          renderRightActions={() => (
            <View style={styles.swipeable.delete}>
              <Icon {...styles.delete} onPress={() => onDelete(member)} />
            </View>
          )}
        >
          <View style={styles.containerContracted}>
            <View
              style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
            >
              <Avatar
                {...styles.avatar}
                avatar={avatar || member.displayName}
                size={36}
              />
              <Text {...styles.textProps}>{member.displayName}</Text>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              {member.isGuest ? (
                <View style={styles.guestWrapperStyle}>
                  <Text {...styles.guestText}>{lang.GUEST}</Text>
                </View>
              ) : null}
            </View>
          </View>

          {member.isGuest ? (
            <Button {...styles.expand} onPress={() => setExpanded(true)} />
          ) : null}
          <Icon {...styles.swipeLeftToCancelProps} />
        </Swipeable>
      </View>
    );
}
