import React from "react";
import { View, Text } from "react-native";
import Product from "./Product";

const lessonImage = require("../../../styles/assets/Artboard 1mdpi.png");

export default function Store({ basket, updateBasket, props, lang, products }) {
  return (
    <View style={props.wrapperStyle}>
      <View style={props.headerStyle}>
        <Text {...props.headerText}>{lang.BUY_CREDITS}</Text>
      </View>
      <View style={props.product.containerStyle}>
        {products
          ? Object.entries(products).map((product, index) => {
              if (
                isNaN(product[1]["price"]) ||
                Number(product[1]["price"]) <= 0
              )
                return null;
              return (
                <View key={product[0]}>
                  <Product
                    data={product[1]}
                    lang={lang}
                    props={props.product}
                    image={lessonImage}
                    qty={(basket || {})[product[0]]}
                    setQty={(qty) => {
                      updateBasket(product[0], qty);
                    }}
                  />
                  {index < Object.keys(products).length - 1 ? (
                    <View style={props.product.divider} />
                  ) : null}
                </View>
              );
            })
          : null}
      </View>
    </View>
  );
}
