import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
//import { Picker } from "@react-native-community/picker";
import { Picker } from "@react-native-picker/picker";
import { useFormikContext } from "formik";

export default function FormPicker({
  containerStyle,
  disabledStyle = {},
  emptyValue = "no selected value",
  enabled,
  itemStyle,
  label,
  labelContainerStyle,
  labelStyle,
  name,
  onValueChange,
  pickerContainerStyle,
  style,
  valueList = [],
  valueLabelList = {},
}) {
  const { setFieldValue, values } = useFormikContext();

  return (
    <View
      style={[containerStyle, !enabled ? disabledStyle.containerStyle : {}]}
    >
      <View
        style={[
          labelContainerStyle,
          !enabled ? disabledStyle.labelContainerStyle : {},
        ]}
      >
        <Text style={[labelStyle, !enabled ? disabledStyle.labelStyle : {}]}>
          {label}
        </Text>
      </View>
      <View
        style={[
          pickerContainerStyle,
          !enabled ? disabledStyle.pickerContainerStyle : {},
        ]}
      >
        {enabled ? (
          <Picker
            enabled={enabled}
            onValueChange={(data) => {
              if (onValueChange) onValueChange(data);
              setFieldValue(name, data, false);
            }}
            itemStyle={itemStyle}
            prompt={label}
            selectedValue={values[name]}
            style={style}
          >
            {Array.from(
              new Set([values[name] || emptyValue, ...valueList, emptyValue])
            ).map((item) => {
              return (
                <Picker.Item
                  label={valueLabelList[item] || item}
                  value={item}
                  key={item}
                />
              );
            })}
          </Picker>
        ) : (
          <Text style={disabledStyle.style}>{values[name] || emptyValue}</Text>
        )}
      </View>
    </View>
  );
}
