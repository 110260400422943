import React from "react";
import { View, Text } from "react-native";
import { Icon } from "react-native-elements";
import { TouchableOpacity } from "react-native"; // -gesture-handler

export default function Button({
  activeBackgroundColor = "gray",
  activeOpacity = 0.5,
  borderStyle,
  containerStyle = {},
  iconProps,
  inactiveBackgroundColor = "white",
  onPress,
  title,
  titleStyle,
}) {
  return (
    <View style={[containerStyle, { backgroundColor: activeBackgroundColor }]}>
      <TouchableOpacity onPress={onPress} activeOpacity={activeOpacity}>
        <View
          style={[
            borderStyle,
            {
              backgroundColor: inactiveBackgroundColor,
              borderRadius: containerStyle.borderRadius || 0,
              height: containerStyle.height,
              width: containerStyle.width,
              flexDirection: "row",
              justifyContent: "center",
            },
          ]}
        >
          {iconProps ? <Icon {...iconProps} /> : null}
          {title ? <Text style={titleStyle}>{title}</Text> : null}
        </View>
      </TouchableOpacity>
    </View>
  );
}
