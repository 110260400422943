import checkmarkAnimation from "../../styles/assets/check9.json";
import loadingAnimation from "../../styles/assets/loading_1.json";
import errorAnimation from "../../styles/assets/warning_1.json";

export default ({ colors }) => ({
  animations: {
    WORKING_ON_IT: {
      animation: { source: loadingAnimation, loop: true },
      overlapStyle: { backgroundColor: colors.darkTranslucent },
    },
    CHECKMARK: {
      animation: { source: checkmarkAnimation, loop: false, time: 2000 },
      overlapStyle: { backgroundColor: colors.darkTranslucent },
    },
    ERROR: {
      animation: { source: errorAnimation, loop: false, time: 2000 },
      overlapStyle: { backgroundColor: colors.darkTranslucent },
    },
  },
});
