//libraries
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/customParseFormat"));
dayjs.extend(require("dayjs/plugin/relativeTime"));

// UI components
import { Alert, Platform, ScrollView, View, Text } from "react-native";
import { Icon } from "react-native-elements";
import { Avatar, Button, OverlayBox } from "../../components";
import FormPlayersList from "./components/FormPlayersList";
import Loading from "../Loading";

// Forms
import { Formik } from "formik";
import * as Yup from "yup";
import { FormInput, FormPicker, FormTimePicker } from "../../components/forms";

// App data
import { LanguageContext, StylesContext } from "../../data/contexts";
import routes from "../../navigation/routes";

// API
import sessionsAPI from "../../data/sessions";

const emptyRecord = {
  date: dayjs().format("YYMMDD"),
  location: "",
  court: "",
  category: "",
  age_group: "",
  coach: "",
  details: "",
  max_players: 0,
  max_waitlist: 0,
  players: [],
  cancelled: false,
  time: "",
  until: "",
  session_type: "",
  cost: 0,
};

const validationSchema = Yup.object().shape({
  cost: Yup.number().integer().positive(),
});

export default function SessionDetails({ navigation, route }) {
  const [players, setPlayers] = useState([]);
  const [initData, setInitData] = useState(emptyRecord);
  const [isNew, setIsNew] = useState(true);
  const [overlayMessage, setOverlayMessage] = useState({ visible: false });
  const [sessionDate, setSessionDate] = useState(dayjs());
  const [canAdd, setCanAdd] = useState(false);
  const [topMessage, setTopMessage] = useState(null);

  const lang = useContext(LanguageContext);
  const { SessionDetails: props } = useContext(StylesContext);

  const UIdata = useSelector((state) => state.user["UIdata"] || false);
  const user = useSelector((state) => state.user["details"]);
  const granted = useSelector((state) => state.user["rules"] || {});

  useEffect(() => {
    console.log("SessionDetails useEffect on route called.");
    if (route.params) {
      console.log("SessionDetails dealing with new params :", route.params);
      setInitData({
        ...emptyRecord,
        ...route.params.data,
      });
      setIsNew(route.params.isNew);
      setSessionDate(sessionsAPI.sessionDateToDayjs(route.params.data.date));
      setPlayers((route.params.data || {}).players || []);
    }
  }, [route]);

  useEffect(() => {
    console.log("SessionDetails useEffect on user/uidata/initData called.");
    if (UIdata && user) {
      console.log("SessionDetails dealing with new user :", user);
      let _canAdd =
        isInTime(initData["date"], initData["time"]) &&
        (((UIdata["categories"].find((el) => el.label === initData["category"])
          ?.order || 0) <=
          (UIdata["categories"].find((el) => el.label === user["category"])
            ?.order || 10e9) &&
          (UIdata["age_groups"].find((el) => el.label === initData["age_group"])
            ?.order || 0) <=
            (UIdata["age_groups"].find((el) => el.label === user["age_group"])
              ?.order || 10e9)) ||
          (user["dependants"] || []).length);

      setCanAdd(_canAdd);
    }
  }, [initData, UIdata, user]);

  const selectPlayer = useCallback(
    (initialValues) => {
      let usersList = {};
      // build an array of the current enrolled players
      const playersList = players.reduce((acc, cv) => {
        acc.push(cv.uid);
        return acc;
      }, []);

      // Build list of users that may be added by the user to this session
      if (granted.ROLE_TO_EDIT_PLAYER) {
        usersList = Object.keys(UIdata.users || {}).reduce((acc, cv) => {
          if (cv) {
            const _user = UIdata.users[cv];
            acc[cv] = {
              ..._user,
              parentName:
                UIdata.users[cv.slice(0, cv.indexOf("_"))]?.displayName,
              highlight:
                _user.category === initialValues.category &&
                _user.age_group === initialValues.age_group
                  ? props.usersListMatch
                  : null,
            };
          }
          return acc;
        }, {});
      } else {
        if (!playersList.includes(user.uid))
          usersList[user.uid] = {
            displayName: user.displayName,
            highlight:
              user.category === initialValues.category &&
              user.age_group === initialValues.age_group
                ? props.usersListMatch
                : null,
          };

        if (user.dependants)
          user.dependants.forEach((member) => {
            if (!playersList.includes(member.uid))
              usersList[member.uid] = {
                displayName: member.displayName,
                parentName: user.displayName,
                isGuest: member.isGuest,
                highlight:
                  member.category === initialValues.category &&
                  member.age_group === initialValues.age_group
                    ? props.usersListMatch
                    : null,
              };
          });
      }

      switch (Object.keys(usersList).length) {
        case 0:
          console.warn(
            "already enrolled. No more people in your tribe to add to this sesssion."
          );
          setTopMessage(lang.ALREADY_ENROLLED);
          break;
        case 1:
          // if current user then add else show selection
          if (Object.keys(usersList)[0] == user.uid) {
            addPlayer(
              initialValues,
              user,
              players,
              setPlayers,
              setOverlayMessage,
              props,
              lang,
              setTopMessage,
              user,
              granted
            );
            return;
          }
        default:
          navigation.navigate(routes.USERS_LIST, {
            onSelect: (player) =>
              addPlayer(
                initialValues,
                player,
                players,
                setPlayers,
                setOverlayMessage,
                props,
                lang,
                setTopMessage,
                user,
                granted
              ),
            users: usersList,
          });
      }
    },
    [players, user, navigation, granted, UIdata]
  );

  if (!UIdata || !user) return <Loading />;
  return (
    <>
      <View
        style={{ alignSelf: "center", flex: 1, width: "100%", maxWidth: 400 }}
      >
        <Formik
          enableReinitialize
          initialValues={initData}
          validationSchema={validationSchema}
        >
          {({
            isValid,
            dirty,
            values,
            resetForm,
            initialValues,
            setFieldValue,
          }) => {
            let timeLabel;
            const sessionDateTime = sessionsAPI.sessionDateToDayjs(
              values["date"],
              values["time"]
            );
            if (dayjs().isBefore(sessionDateTime))
              timeLabel = lang.EVENT_STARTS + sessionDateTime.fromNow();
            else timeLabel = lang.EVENT_WAS + sessionDateTime.fromNow();

            return (
              <>
                {dirty || topMessage ? (
                  <View style={props.topMessage.wrapperStyle}>
                    {granted.ROLE_TO_EDIT_SESSION &&
                    isValid &&
                    values.location &&
                    values.time ? (
                      <Button
                        {...props.topMessage.buttonSave}
                        title={"save"}
                        onPress={() => {
                          saveSession(
                            isNew,
                            initialValues,
                            values,
                            granted,
                            setOverlayMessage,
                            props,
                            lang,
                            setIsNew,
                            resetForm,
                            setTopMessage
                          );
                        }}
                      />
                    ) : null}

                    <Text {...props.topMessage.text}>
                      {dirty ? lang.CHANGES_NOT_SAVED : topMessage}
                    </Text>

                    {!dirty ? (
                      <Button
                        {...props.topMessage.buttonClose}
                        onPress={() => setTopMessage(null)}
                      />
                    ) : null}
                  </View>
                ) : null}
                <ScrollView
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                >
                  <View style={props.container}>
                    <View style={props.header.containerStyle}>
                      <View style={props.date.wrapperStyle}>
                        <Text style={props.date.monthStyle}>
                          {sessionDate.format("MMM").toUpperCase()}
                        </Text>
                        <Text style={props.date.numStyle}>
                          {sessionDate.format("D")}
                        </Text>
                        <Text {...props.date.dayProps}>
                          {sessionDate.format("dddd")}
                        </Text>
                      </View>

                      <View style={props.upperRight.wrapperStyle}>
                        {!granted.ROLE_TO_EDIT_SESSION ||
                        values["cancelled"] ||
                        players.length ? (
                          <Text style={props.upperRight.titleStyle}>
                            {values["session_type"]}
                          </Text>
                        ) : (
                          <FormPicker
                            enabled={true}
                            emptyValue={lang.SESSION_TYPE_UNDEFINED}
                            name="session_type"
                            selectedValue={values["session_type"]}
                            valueList={Object.keys(UIdata["session_types"])}
                            onValueChange={(session_type) =>
                              setFieldValue(
                                "cost",
                                (UIdata["session_types"] || {})[session_type]
                                  ?.value || 0
                              )
                            }
                            {...props.upperRight.sessionTypePicker}
                          />
                        )}
                        <View style={props.upperRight.fromTo.wrapperStyle}>
                          <View style={props.upperRight.fromTo.container}>
                            <Text style={props.upperRight.fromTo.labelStyle}>
                              {lang.FROM}
                            </Text>
                            <FormTimePicker
                              disabled={
                                !granted.ROLE_TO_EDIT_SESSION ||
                                values["cancelled"]
                              }
                              name="time"
                              value={values["time"]}
                              onValueChange={(data) =>
                                setFieldValue(
                                  "time",
                                  sessionsAPI.timeFrontToBack(data)
                                )
                              }
                              buttonProps={props.upperRight.fromTo.buttonProps}
                              pickerProps={props.upperRight.fromTo.pickerProps}
                              {...props.upperRight.fromTo.timeInput}
                            />
                          </View>
                          <View style={props.upperRight.fromTo.container}>
                            <Text style={props.upperRight.fromTo.labelStyle}>
                              {lang.TO}
                            </Text>
                            <FormTimePicker
                              disabled={
                                !granted.ROLE_TO_EDIT_SESSION ||
                                values["cancelled"]
                              }
                              name="until"
                              value={values["until"]}
                              onValueChange={(data) =>
                                setFieldValue(
                                  "until",
                                  sessionsAPI.timeFrontToBack(data)
                                )
                              }
                              buttonProps={props.upperRight.fromTo.buttonProps}
                              pickerProps={props.upperRight.fromTo.pickerProps}
                              {...props.upperRight.fromTo.timeInput}
                            />
                          </View>
                          <View style={props.upperRight.credits.containerStyle}>
                            <Text {...props.upperRight.credits.labelProps}>
                              {lang.CREDITS}
                            </Text>
                            {granted.ROLE_TO_EDIT_SESSION &&
                            !values["cancelled"] &&
                            !players.length ? (
                              <FormInput
                                name="cost"
                                value={values["cost"]}
                                {...props.upperRight.credits.input}
                              />
                            ) : (
                              <Text {...props.upperRight.credits.creditsProps}>
                                {values["cost"]}
                              </Text>
                            )}
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={props.form.wrapperStyle}>
                      <View style={props.form.timeLabel}>
                        <Text {...props.form.timeLabelText}>{timeLabel}</Text>
                      </View>
                      {values["cancelled"] ? (
                        <Text {...props.form.cancelledSessionText}>
                          {lang.SESSION_CANCELLED}
                        </Text>
                      ) : null}

                      <View style={{ flex: 1 }}>
                        {!granted.ROLE_TO_EDIT_SESSION ? (
                          <View style={props.location.containerStyle}>
                            <Icon iconProps={props.location.icon} />
                            <View
                              style={{
                                flex: 1,
                                alignItems: "center",
                              }}
                            >
                              <Text {...props.location.text}>
                                {values["location"]}
                              </Text>
                              <Text {...props.location.courtText}>
                                {values["court"]
                                  ? "( court: " + values["court"] + " )"
                                  : ""}
                              </Text>
                            </View>
                          </View>
                        ) : (
                          <View style={[props.form.rowStyle, { marginTop: 0 }]}>
                            <View style={{ flex: 3 }}>
                              <FormPicker
                                disabledStyle={props.pickerDisabled}
                                emptyValue={lang.NO_SELECTED_VALUE}
                                enabled={
                                  granted.ROLE_TO_EDIT_SESSION &&
                                  !values["cancelled"] && !players.length
                                }
                                label={lang.LOCATION}
                                name="location"
                                selectedValue={values["location"]}
                                valueList={Object.keys(UIdata["locations"])}
                                {...props.picker}
                              />
                            </View>
                            <View style={{ flex: 2 }}>
                              <FormPicker
                                disabledStyle={props.pickerDisabled}
                                emptyValue={lang.NO_SELECTED_VALUE}
                                enabled={
                                  granted.ROLE_TO_EDIT_SESSION &&
                                  !values["cancelled"]
                                }
                                label={lang.COURT}
                                name="court"
                                selectedValue={values["court"]}
                                valueList={
                                  UIdata["locations"][values["location"]]
                                }
                                {...props.picker}
                              />
                            </View>
                          </View>
                        )}
                        <View style={props.form.rowStyle}>
                          <View style={{ flex: 1 }}>
                            <FormPicker
                              disabledStyle={props.pickerDisabled}
                              emptyValue={lang.UNDEFINED}
                              enabled={
                                granted.ROLE_TO_EDIT_SESSION &&
                                !values["cancelled"]
                              }
                              label={lang.AGE_GROUP}
                              name="age_group"
                              selectedValue={values["age_group"]}
                              valueList={UIdata["age_groups"].map(
                                (el) => el.label
                              )}
                              {...props.picker}
                            />
                          </View>
                          <View style={{ flex: 1 }}>
                            <FormPicker
                              disabledStyle={props.pickerDisabled}
                              emptyValue={lang.UNDEFINED}
                              enabled={
                                granted.ROLE_TO_EDIT_SESSION &&
                                !values["cancelled"]
                              }
                              label={lang.CATEGORY}
                              name="category"
                              selectedValue={values["category"]}
                              valueList={UIdata["categories"].map(
                                (el) => el.label
                              )}
                              {...props.picker}
                            />
                          </View>
                        </View>

                        <View style={props.form.rowStyle}>
                          <View style={{}}>
                            <Avatar
                              avatar={
                                ((UIdata["coaches"] || {})[values["coach"]] ||
                                  {})["avatar"]
                              }
                              size={42}
                              {...props.miniAvatar}
                            />
                          </View>
                          <View
                            style={{
                              flex: 1,
                              marginLeft: 20,
                              marginVertical: 6,
                            }}
                          >
                            <FormPicker
                              disabledStyle={props.pickerDisabled}
                              emptyValue={lang.UNDEFINED}
                              enabled={
                                granted.ROLE_TO_EDIT_SESSION &&
                                !values["cancelled"]
                              }
                              label={lang.COACH}
                              name="coach"
                              selectedValue={values["coach"]}
                              valueList={Object.keys(UIdata["coaches"] || {})}
                              valueLabelList={Object.keys(
                                UIdata["coaches"] || {}
                              ).reduce((acc, cv) => {
                                acc[cv] = UIdata["coaches"][cv].displayName;
                                return acc;
                              }, {})}
                              {...props.picker}
                            />
                          </View>
                        </View>

                        <View style={props.form.rowStyle}>
                          <FormInput
                            enabled={
                              granted.ROLE_TO_EDIT_SESSION &&
                              !values["cancelled"]
                            }
                            label={lang.SESSION_DETAILS}
                            name="details"
                            value={values["details"]}
                            {...props.details.input}
                          />
                        </View>

                        {granted.ROLE_TO_EDIT_SESSION ? (
                          <>
                            <FormInput
                              disabledStyle={props.inputDisabled}
                              enabled={
                                granted.ROLE_TO_EDIT_SESSION &&
                                !values["cancelled"]
                              }
                              label={lang.MAX_PLAYERS}
                              name="max_players"
                              {...props.form.numberInput}
                              value={values["max_players"]}
                            />
                            {/* 
                          <View style={{ flex: 1 }}>
                            <FormInput
                              disabledStyle={props.inputDisabled}
                              enabled={granted.ROLE_TO_EDIT_SESSION}
                              label={lang.MAX_WAITLIST}
                              name="max_waitlist"
                              {...props.form.numberInput}
                              value={values["max_waitlist"]}
                            />
                          </View>
                          */}
                          </>
                        ) : null}

                        <View style={[props.form.playersList.containerStyle]}>
                          <View
                            style={props.form.playersList.header.containerStyle}
                          >
                            <Text
                              style={
                                props.form.playersList.header.nbPlayerStyle
                              }
                            >
                              {`${lang.PLAYERS}: ${Math.min(
                                players.length,
                                values["max_players"]
                              )}/${values["max_players"]}`}
                            </Text>
                            {/* {values["max_waitlist"] ? (
                            <Text
                              style={
                                props.form.playersList.header.nbWaitingStyle
                              }
                            >
                              {`(${lang.WAITING_LIST}: ${Math.max(
                                0,
                                players.length - values["max_players"]
                              )}/${values["max_waitlist"]})`}
                            </Text>
                          ) : null}                        
                              */}
                          </View>

                          <FormPlayersList
                            props={props.form.playersList}
                            isPlayersEditor={granted.ROLE_TO_EDIT_PLAYER}
                            maxWaitlist={values["max_waitlist"] || 0}
                            maxNumPlayers={values["max_players"] || 0}
                            onAddPlayer={() => selectPlayer(initialValues)}
                            onRemovePlayer={(player) =>
                              removePlayer(
                                initialValues,
                                player,
                                players,
                                setPlayers,
                                setOverlayMessage,
                                props,
                                lang,
                                setTopMessage
                              )
                            }
                            onViewPlayer={(player) =>
                              showPlayerDetails(navigation, player)
                            }
                            playerCanCancel={
                              !dirty &&
                              (isInTime(
                                values["date"],
                                values["time"],
                                granted.PLAYER_CANCEL_FULL_REFUND_DELAY
                              ) ||
                                granted.ROLE_TO_EDIT_PLAYER) &&
                              !values["cancelled"]
                            }
                            playerCanAdd={
                              !dirty &&
                              (canAdd || granted.ROLE_TO_EDIT_PLAYER) &&
                              !values["cancelled"]
                            }
                            players={players}
                            session={values}
                            user={user}
                          />
                        </View>
                      </View>
                    </View>
                  </View>

                  {granted.ROLE_TO_CANCEL_SESSION &&
                  !dirty &&
                  !isNew &&
                  !values["cancelled"] ? (
                    <Button
                      title={lang.CANCEL_SESSION}
                      {...props.cancelSessionButton}
                      onPress={() => {
                        confirmCancellation(
                          initialValues,
                          setOverlayMessage,
                          props,
                          lang,
                          resetForm,
                          navigation,
                          setIsNew,
                          setTopMessage
                        );
                      }}
                    />
                  ) : null}
                </ScrollView>
              </>
            );
          }}
        </Formik>
      </View>

      <OverlayBox {...overlayMessage} />
    </>
  );
}

// **********************************************************************************
//   External functions
// **********************************************************************************

const showPlayerDetails = (navigation, player) => {
  navigation.navigate(routes.ACCOUNT_DETAILS, {
    player,
  });
};

const isInTime = (date, time = "2359", delayMinutes = 0) => {
  const currentTime = new dayjs();
  const timeLimit = dayjs(date + time, "YYMMDDHHmm").subtract(
    delayMinutes,
    "minute"
  );
  return currentTime.isBefore(timeLimit);
};

const addDuration = (time, duration) => {
  let newTime = time + duration;
  if (time > 2359) newTime -= 2400;
  return newTime;
};

// resetForm({ values: { ...values } })
const saveSession = (
  isNew,
  oldSession,
  newSession,
  granted,
  setOverlayMessage,
  props,
  lang,
  setIsNew,
  resetForm,
  setTopMessage
) => {
  setOverlayMessage({ ...props.animations.WORKING_ON_IT, visible: true });
  const newValues = { ...newSession };
  if (!newValues["until"])
    newValues["until"] = addDuration(
      newValues["time"],
      granted.DEFAULT_SESSION_DURATION
    );

  let promise;
  if (isNew) promise = sessionsAPI.newSession(newSession);
  else promise = sessionsAPI.updateSession(oldSession, newSession);

  promise
    .then(() => {
      setIsNew(false);
      resetForm({ values: { ...newSession } });
      setOverlayMessage({
        ...props.animations.CHECKMARK,
        callback: () => setOverlayMessage({ visible: false }),
        time: 1000,
        visible: true,
      });
    })
    .catch((err) => {
      console.warn("Session save failed. ", err);
      setOverlayMessage({
        ...props.animations.ERROR,
        callback: () => setOverlayMessage({ visible: false }),
        time: 1000,
        visible: true,
      });
      setTopMessage(lang.SAVE_FAILED);
    });
};

const confirmCancellation = (
  session,
  setOverlayMessage,
  props,
  lang,
  resetForm,
  navigation,
  setIsNew,
  setTopMessage
) => {
  if (Platform.OS === "web") {
    if (confirm(lang.CANCEL_CONFIRM_MESSAGE))
      cancelSession(
        session,
        setOverlayMessage,
        props,
        lang,
        resetForm,
        navigation,
        setIsNew,
        setTopMessage
      );
  } else
    Alert.alert(lang.CANCEL_CONFIRM_TITLE, lang.CANCEL_CONFIRM_MESSAGE, [
      {
        text: lang.CANCEL_CONFIRM_BTN_YES,
        onPress: () =>
          cancelSession(
            session,
            setOverlayMessage,
            props,
            lang,
            resetForm,
            navigation,
            setIsNew,
            setTopMessage
          ),
      },
      { text: lang.CANCEL_CONFIRM_BTN_NO, onPress: () => false },
    ]);
};

const cancelSession = (
  session,
  setOverlayMessage,
  props,
  lang,
  resetForm,
  navigation,
  setIsNew,
  setTopMessage
) => {
  setOverlayMessage({ ...props.animations.WORKING_ON_IT, visible: true });
  const newSession = { ...session };
  newSession["cancelled"] = true;
  sessionsAPI
    .cancelSession(newSession)
    .then(() => {
      setIsNew(false);
      resetForm({ values: { ...newSession } });
      setOverlayMessage({
        ...props.animations.CHECKMARK,
        callback: () => {
          setOverlayMessage({ visible: false });
          const nbPlayers =
            (session?.players?.length || 0) + (session?.waitlist?.length || 0);
          if (nbPlayers === 0) navigation.goBack(); // goback as session has been deleted
        },
        time: 1000,
        visible: true,
      });
    })
    .catch((err) => {
      setOverlayMessage({
        ...props.animations.ERROR,
        callback: () => setOverlayMessage({ visible: false }),
        time: 1000,
        visible: true,
      });
      setTopMessage(lang.CANCELLATION_FAILED);
    });
};

const addPlayer = (
  session,
  playerToAdd,
  players,
  setPlayers,
  setOverlayMessage,
  props,
  lang,
  setTopMessage,
  user,
  granted
) => {
  if (
    !granted.ROLE_TO_EDIT_PLAYER &&
    (isNaN(user.balance) || isNaN(session.cost) || user.balance < session.cost)
    //!hasEnoughCredits(user, session.session_type, session_types)
  ) {
    setTopMessage(lang.NOT_ENOUGH_CREDITS);
    return;
  }

  let newPlayers = [...players];
  newPlayers.push({
    uid: playerToAdd.uid,
    displayName: playerToAdd.displayName,
  });
  setOverlayMessage({ ...props.animations.WORKING_ON_IT, visible: true });
  sessionsAPI
    .addPlayer(session, playerToAdd)
    .then(() => {
      setPlayers(newPlayers);
      setOverlayMessage({
        ...props.animations.CHECKMARK,
        callback: () => setOverlayMessage({ visible: false }),
        time: 1000,
        visible: true,
      });
    })
    .catch((e) => {
      console.warn("Adding player failed. ", e);
      setOverlayMessage({
        ...props.animations.ERROR,
        callback: () => setOverlayMessage({ visible: false }),
        time: 1000,
        visible: true,
      });
      setTopMessage(lang.ADD_PLAYER_FAILED);
    });
};

const removePlayer = (
  session,
  playerToRemove,
  players,
  setPlayers,
  setOverlayMessage,
  props,
  lang,
  setTopMessage
) => {
  let newPlayers = players.filter(
    (_player) => _player.uid !== playerToRemove.uid
  );
  setOverlayMessage({ ...props.animations.WORKING_ON_IT, visible: true });
  sessionsAPI
    .removePlayer(session, playerToRemove)
    .then(() => {
      setPlayers(newPlayers);
      setOverlayMessage({
        ...props.animations.CHECKMARK,
        callback: () => setOverlayMessage({ visible: false }),
        time: 1000,
        visible: true,
      });
    })
    .catch((e) => {
      console.warn("Removing player failed. ", e);
      setOverlayMessage({
        ...props.animations.ERROR,
        callback: () => setOverlayMessage({ visible: false }),
        time: 1000,
        visible: true,
      });
      setTopMessage(lang.REMOVE_PLAYER_FAILED);
    });
};

/*
const hasEnoughCredits = (user, sessionType, session_types) => {
  if (
    session_types[sessionType]?.value === null ||
    session_types[sessionType]?.value === undefined
  )
    return false;
  return user.balance >= session_types[sessionType]?.value;
};
*/
