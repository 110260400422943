import React, { useCallback, useContext, useEffect, useState } from "react";
import { View, Text } from "react-native";
import { useSelector } from "react-redux";
import { OverlayBox, Webview } from "../../components";
import Loading from "../Loading";

// API
import storeAPI from "../../data/store";

// data
import { LanguageContext, StylesContext } from "../../data/contexts";

export default function HitPayPayment({ navigation, route }) {
  const [basket, setBasket] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [overlayMessage, setOverlayMessage] = useState({ visible: false });

  const lang = useContext(LanguageContext);
  const { HitPayPayment: props } = useContext(StylesContext);

  const products = useSelector(
    (state) => (state.user["UIdata"] || {})["store"]
  );

  useEffect(() => {
    if (route.params && route.params.basket && !basket) {
      storeAPI
        .getPaymentRequest(
          route.params.basket,
          route.params.method,
          afterPaymentResult
        )
        .then((newPR) => {
          setPaymentRequest(newPR);
          setBasket(route.params.basket);
        })
        .catch((e) => {
          console.warn("Failed to get payment request.", e);
          setOverlayMessage({
            ...props.animations.ERROR,
            callback: () => {
              setOverlayMessage({ visible: false });
              navigation.goBack();
            },
            message: lang.PAYMENT_REQUEST_FAILED,
            messageStyle: { color: "white", fontSize: 20 },
            time: 3000,
            visible: true,
          });
        });
    }
    return () => {
      storeAPI.endHitPay();
    };
  }, [route]);

  const afterPaymentResult = (data) => {
    // show success or failed / go back
    setOverlayMessage({
      ...(data.status === "fulfilled"
        ? props.animations.CHECKMARK
        : props.animations.ERROR),
      callback: () => {
        setOverlayMessage({ visible: false });
        navigation.pop(2);
      },
      message:
        data.status === "fulfilled"
          ? lang.PAYMENT_SUCCEEDED
          : lang.PAYMENT_FAILED,
      messageStyle: { color: "white", fontSize: 20 },
      time: 2000,
      visible: true,
    });
  };


  if (overlayMessage?.visible && !paymentRequest)
    return (
      <View style={{ height: "100%", width: "100%" }}>
        <OverlayBox {...overlayMessage} />
      </View>
    );

  if (!products || !basket || !paymentRequest) return <Loading />;

  return (
    <>
      <View style={props.containerStyle}>
        <View style={props.cardForm.webviewWrapperStyle}>
          <Webview
            source={{
              uri: paymentRequest.url,
            }}
            style={{ flex: 1 }}
          />
        </View>
      </View>
      <OverlayBox {...overlayMessage} />
    </>
  );
}
