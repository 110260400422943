import React from "react";
import { View, Text } from "react-native";
import { Button } from "../../../components";
import Member from "./Member";

import { useFormikContext } from "formik";

import routes from "../../../navigation/routes";

export default function FormTribe({
  avatars,
  editing,
  lang,
  name,
  navigation,
  styles,
  UIdata,
  user,
}) {
  const { values, setFieldValue } = useFormikContext();

  const onAddMember = (member) => {
    if (member.uid === user.uid) {
      console.warn("Cannot add yourself to your tribe.");
      return false;
    }
    if ((values[name] || []).length > 20) {
      console.warn(
        "Number of dependants is limited to 20. You can't add more members to your tribe."
      );
      return false;
    }

    const accountsLinked = [...(values[name] || [])];
    // check adding member is not already in tribe members
    if (
      !accountsLinked.every((item) => item.displayName !== member.displayName)
    ) {
      console.warn(
        "cannot add the same member twice or have two members of same name."
      );
      return false;
    }

    accountsLinked.push({
      ...member,
      uid: member.isGuest
        ? user.uid + "_" + member.displayName// .replace(/\s/g, "")
        : member.uid,
    });
    setFieldValue(name, accountsLinked);
    return true;
  };

  const onUpdateMember = (index, update) => {
    const accountsLinked = [...(values[name] || [])];
    accountsLinked[index] = { ...values[name][index], ...update };
    if (accountsLinked[index].isGuest)
      accountsLinked[index].uid =
        user.uid + "_" + accountsLinked[index].displayName;
    setFieldValue(name, accountsLinked);
  };

  const onRemoveMember = (member) => {
    const accountsLinked = [...(values[name] || [])].filter(
      (item) => item.displayName !== member.displayName
    );
    setFieldValue(name, accountsLinked);
  };

  const showSelectPlayer = () => {
    // list users excluding existing dependants and user itself
    const dependants = values[name]?.map((member) => member.uid) || [];
    const usersList = Object.keys(UIdata.users || {}).reduce((acc, cv) => {
      if (cv && cv !== user.uid && !dependants.includes(cv)) {
        const _user = UIdata.users[cv];
        if (_user.isGuest && cv.indexOf("_")) {
          acc[cv] = {
            ..._user,
            parentName:
              UIdata.users[cv.slice(0, cv.indexOf("_"))]?.displayName,
          };
        } else acc[cv] = { ..._user };
      }
      return acc;
    }, {});
    navigation.navigate(routes.USERS_LIST, {
      onSelect: onAddMember,
      displayAddGuest: true,
      showSearch: true,
      users: usersList,
    });
  };

  return (
    <>
      <View style={styles.containerStyle}>
        <View style={styles.header.wrapperStyle}>
          <Text {...styles.header.textProps}>{lang.TRIBE_TITLE}</Text>
          {editing ? (
            <Button
              title={lang.TRIBE_ADD}
              onPress={showSelectPlayer}
              {...styles.header.addButton}
            />
          ) : null}
        </View>
        <View style={styles.members.wrapperStyle}>
          {values[name] && values[name].length > 0 ? (
            values[name].map((member, index) => {
              return (
                <Member
                  key={index}
                  avatar={
                    member.isGuest ? member.miniAvatar : avatars[member.uid]
                  }
                  editing={editing && member.uid.startsWith(user.uid)}
                  isDividerVisible={values[name].length - 1 > index}
                  lang={lang}
                  member={member}
                  onDelete={onRemoveMember}
                  onUpdate={(update) => onUpdateMember(index, update)}
                  styles={styles.members}
                  UIdata={UIdata}
                />
              );
            })
          ) : (
            <View style={styles.members.infoStyle}>
              <Text {...styles.members.infoText}>{lang.TRIBE_INFO}</Text>
            </View>
          )}
        </View>
        <View style={styles.footer} />
      </View>
    </>
  );
}
