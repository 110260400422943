// Libraries
import React, { useContext, useState, useEffect } from "react";

// UI components
import { Text, View } from "react-native";
import { Button } from "react-native-elements";
import OverlayBox from "../../components/OverlayBox";
import { ScrollView } from "react-native-gesture-handler";
import checkmarkAnimation from "../../styles/assets/check9.json";

// Forms
import * as Yup from "yup";
import { Formik } from "formik";
import {
  FormArray,
  FormCheckBox,
  FormInput,
  FormSubmit,
} from "../../components/forms";

// App data
import { LanguageContext, StylesContext } from "../../data/contexts";

// API
import adminApi from "../../data/admin";

export default function DataEditor({ navigation, route }) {
  const [overlayMessage, setOverlayMessage] = useState({ visible: false });
  const [isNew] = useState(!(route.params && route.params.data));
  const [dataset] = useState(route?.params?.dataset);
  const [schema] = useState(route?.params?.schema);
  const [formData, setFormData] = useState(null);

  const lang = useContext(LanguageContext);
  const { DataEditor: props } = useContext(StylesContext);

  useEffect(() => {
    navigation.setOptions({
      title: lang.EDITING,
    });
  }, []);

  useEffect(() => {
    if (route.params)
      setFormData(
        initializeFormFromSchema(
          route.params.metadata,
          route.params.schema,
          route.params.data
            ? { ...route.params.data, documentID: route.params.documentID }
            : {}
        )
      );
  }, [route]);

  const saveItem = (data) => {
    setOverlayMessage({ visible: false });
    adminApi
      .saveRecord(dataset, data)
      .then((docRef) => {
        setOverlayMessage({
          animation: { source: checkmarkAnimation, loop: false },
          callback: () => {
            setOverlayMessage({ visible: false });
            navigation.goBack();
          },
          time: 1000,
          visible: true,
        });
      })
      .catch((error) => {
        console.error("DataEditor, saveItem call failed :", error);
        setOverlayMessage({
          callback: () => {
            setOverlayMessage({ visible: false });
          },
          message: lang.SAVE_FAILED,
          time: 2000,
          visible: true,
        });
      });
  };

  if (!schema || !dataset || !formData) return null;
  return (
    <>
      <View style={props.containerStyle}>
        <ScrollView
          style={{
            alignSelf: "center",
            width: "100%",
          }}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          <View style={{ height: 10, width: "100%" }}></View>
          <Formik
            initialValues={formData.initialRecord}
            onSubmit={(values) => {
              saveItem(values);
            }}
            validationSchema={formData.validationSchema}
          >
            {({ values, handleChange, dirty }) => {
              const renderedFields = [];
              formData.orderedFields.map((item) => {
                switch (schema[item].type) {
                  // implement boolean, datetime, reference, array
                  case "boolean":
                    renderedFields.push(
                      <FormCheckBox
                        key={item}
                        label={
                          schema[item].localized_name
                            ? schema[item].localized_name
                            : item
                        }
                        labelStyle={props.checkBox.labelStyle}
                        name={item}
                        {...props.checkBox}
                      />
                    );
                    break;
                  case "datetime":
                    console.warn(
                      "DataEditor, datetime data type not yet implemented."
                    );
                    renderedFields.push(
                      <Text key={item}>{item} not implemented</Text>
                    );
                    break;
                  case "reference":
                    console.warn(
                      "DataEditor, reference data type not yet implemented."
                    );
                    renderedFields.push(
                      <Text key={item}>{item} not implemented</Text>
                    );
                    break;
                  case "array":
                    renderedFields.push(
                      <FormArray
                        key={item}
                        label={
                          schema[item].localized_name
                            ? schema[item].localized_name
                            : item
                        }
                        name={item}
                        style={{
                          ...props.formArray,
                          //input: { ...props.input },
                        }}
                      />
                    );
                    break;
                  default:
                    renderedFields.push(
                      <FormInput
                        autoCapitalize="none"
                        autoCorrect={false}
                        enabled={
                          schema[item].type === "id" && !isNew ? false : true
                        }
                        key={item}
                        label={
                          schema[item].localized_name
                            ? schema[item].localized_name
                            : item
                        }
                        multiline={true}
                        numberOfLines={2}
                        name={item}
                        placeholder={
                          schema[item].localized_placeholder
                            ? schema[item].localized_placeholder
                            : null
                        }
                        {...props.input}
                        {...(schema[item].type === "id" && !isNew
                          ? props.inputDisabled
                          : null)}
                        value={values[item]}
                      />
                    );
                }
              });
              renderedFields.push(
                <FormSubmit
                  key="submitButton"
                  title={isNew ? lang.SAVE_BUTTON_NEW : lang.SAVE_BUTTON_UPDATE}
                  {...props.buttonSave}
                />
              );
              return <View>{renderedFields}</View>;
            }}
          </Formik>
        </ScrollView>
      </View>
      <OverlayBox {...overlayMessage} />
    </>
  );
}

const initializeFormFromSchema = (metadata, schema, data) => {
  let yupValidationObject = {};
  let idField;
  let requiredFields = [];
  let otherFields = [];
  let initialRecord = {};
  let typeDefaultValue;
  const highlight = metadata.highlight ? metadata.highlight : "";

  for (const item in schema) {
    let yupField;
    const field = schema[item];
    // manage type
    if (field.type == undefined) {
      console.error("Schema erroneous. Each field needs a type.");
      return;
    }

    switch (field.type) {
      case "text":
        yupField = Yup.string();
        typeDefaultValue = "";
        break;
      case "string":
        yupField = Yup.string();
        typeDefaultValue = "";
        break;
      case "id":
        yupField = Yup.string();
        typeDefaultValue = adminApi.uuidv4();
        idField = item;
        break;
      case "number":
        yupField = Yup.number();
        typeDefaultValue = undefined;
        break;
      case "boolean":
        yupField = Yup.boolean();
        typeDefaultValue = undefined;
        break;
      case "array":
        yupField = Yup.array();
        typeDefaultValue = [];
        break;
      case "datetime":
        yupField = Yup.date();
        typeDefaultValue = undefined;
        break;
      case "reference":
        yupField = Yup.mixed();
        typeDefaultValue = undefined;
        break;
      case "email":
        yupField = Yup.string().email();
        typeDefaultValue = "";
        break;
      case "url":
        yupField = Yup.string().url();
        typeDefaultValue = "";
        break;
      default:
        yupField = Yup.string();
        typeDefaultValue = "";
        console.warn(
          "Unknown data type from backend source. Use string by default."
        );
    }
    // manage required
    if (field.required !== undefined && field.required == true) {
      yupField = yupField.required ? yupField.required() : yupField;
      if (field.type != "id") {
        if (item == highlight) requiredFields.unshift(item);
        else requiredFields.push(item);
      }
    } else {
      if (item == highlight) otherFields.unshift(item);
      else otherFields.push(item);
    }

    // manage default
    if (field.default !== undefined) {
      if (yupField.default) yupField = yupField.default(field.default);
      initialRecord[item] =
        data[item] !== undefined ? data[item] : field.default;
    } else {
      initialRecord[item] =
        data[item] !== undefined ? data[item] : typeDefaultValue;
    }

    // manage min, max
    if (["number", "string", "datetime", "array"].includes(field.type)) {
      yupField = field.min ? yupField.min(field.min) : yupField;
      yupField = field.max ? yupField.max(field.max) : yupField;
    }

    // manage match
    if (field.match !== undefined && field.type == "string")
      yupField = yupField.matches(new RegExp(field.match));

    yupValidationObject[item] = yupField;
  }

  const orderedFields = requiredFields.concat(otherFields);
  orderedFields.unshift(idField);
  return {
    validationSchema: Yup.object().shape(yupValidationObject),
    orderedFields: orderedFields,
    initialRecord,
  };
};
