export default ({ colors, iconButton, typos }) => ({
  buttons: {
    common: {
      ...iconButton,
      containerStyle: {
        ...iconButton.containerStyle,
        height: 24,
        width: 24,
      },
      inactiveBackgroundColor: colors.background,
      activeBackgroundColor: colors.subText,
    },
    expandIconProps: {
      color: colors.darkPrimary,
      name: "unfold-more",
      size: 24,
      type: "material",
    },
    shrinkIconProps: {
      color: colors.darkPrimary,
      name: "unfold-less",
      size: 24,
      type: "material",
    },
  },
  info: {
    wrapperStyle: {
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderColor: colors.darkPrimary,
    },
    headerStyle: {
      backgroundColor: colors.primary,
      flexDirection: "row",
      height: 28,
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: 10,
    },
    headerText: {
      numberOfLines: 1,
      style: {
        ...typos.titleText,
        color: colors.offText,
      },
    },
    contentWrapperStyle: {
      backgroundColor: colors.background,
      height: 0,
      overflow: "hidden",
    },
    content: { style: { flex: 1 } },
  },
  expanded: { flex: 1, backgroundColor: colors.background },
});
