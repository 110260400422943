// Librairies
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// UI components
import {
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Button, LottieView, MessageBox } from "../../components";
import Information from "../users/Information";

// App data
import { LanguageContext, StylesContext } from "../../data/contexts";

// Forms
import * as Yup from "yup";
import { Formik } from "formik";
import { FormInput } from "../../components/forms";

// API
import userAPI from "../../data/user";



export default function Welcome() {
  const { Welcome: props } = useContext(StylesContext);
  const [animBox, setAnimBox] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [timer, setTimer] = useState(null);
  const [resetPassword, setResetPassword] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  const lang = useContext(LanguageContext);
  const user = useSelector((state) => (state.user || {}).details || {});

  // validation schemas
  const signUpValidationSchema = Yup.object().shape({
    displayName: Yup.string()
      .trim()
      .required(lang.DISPLAY_NAME_ERROR_REQUIRED)
      .matches(
        /^[a-zA-Z0-9\u00C0-\u017F]+([\s-]?[a-zA-Z0-9\u00C0-\u017F])*$/,
        lang.DISPLAY_NAME_ERROR_MATCHES
      )
      .min(3, lang.DISPLAY_NAME_ERROR_MIN),
    email: Yup.string()
      .required(lang.DISPLAY_NAME_ERROR_REQUIRED)
      .email(lang.EMAIL_ERROR),
    password: Yup.string()
      .required(lang.DISPLAY_NAME_ERROR_REQUIRED)
      .min(8, lang.PASSWORD_ERROR_MIN),
  });

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string().required().email(),
    password: Yup.string().required().min(8),
  });

  useEffect(() => {
    if (user && user.uid && !user.emailVerified) setShowWelcome(true);
    return () => {
      if (user && user.uid && user.emailVerified) {
        setAnimBox(false);
        if (timer) clearTimeout(timer);
      }
    };
  }, [user]);

  const onLogin = useCallback((values) => {
    setAnimBox(props.animations.WORKING_ON_IT);
    userAPI
      .logUser(values.email, values.password)
      .then((user) => {
        if (!user.emailVerified) setAnimBox(false);
        // setAnimBox(false);  Commented as it trigger a flickering when logging in
        console.log("Login succeeded: ", user);
      })
      .catch((error) => {
        setAnimBox(false);
        setErrorMessage(lang.ERROR_LOGIN);
        console.warn("Login failed: ", error);
      });
  }, []);

  const onRegister = useCallback((values) => {
    setAnimBox(props.animations.WORKING_ON_IT);
    userAPI
      .createUser(values.displayName, values.email, values.password)
      .then((user) => {
        setAnimBox(props.animations.CHECKMARK);
        setTimer(
          setTimeout(() => {
            setAnimBox(false);
            setIsSignUp(false);
          }, 2000)
        );
        console.log("Create user succeded: ", user);
      })
      .catch((error) => {
        setAnimBox(false);
        switch (error.message) {
          case "displayName-already-exists":
            setErrorMessage(lang.ERROR_REGISTER_DISPLAYNAME);
            break;
          case "email-already-exists":
            setErrorMessage(lang.ERROR_REGISTER_EMAIL);
            break;
          default:
            setErrorMessage(lang.ERROR_REGISTER_OTHER);
        }
        console.warn("Create user failed: ", error);
      });
  }, []);

  const onEmailVerification = useCallback(() => {
    setAnimBox(props.animations.WORKING_ON_IT);
    userAPI
      .sendVerificationEmail()
      .then(() => {
        console.log("Succesfully send verification email.");
        setAnimBox(false);
        closeUnverified();
      })
      .catch((e) => {
        console.warn("Failed to send verification email.");
        setAnimBox(false);
      });
  }, []);

  const toggleAuth = useCallback(() => {
    setErrorMessage(false);
    setIsSignUp(!isSignUp);
  }, [isSignUp]);

  const closeUnverified = useCallback(() => {
    userAPI.logoutUser();
    setShowWelcome(false);
    setErrorMessage(false);
    setIsSignUp(false);
  }, []);

  const forgotPassword = useCallback((email) => {
    setShowWelcome(false);
    setErrorMessage(false);
    setAnimBox(props.animations.WORKING_ON_IT);
    userAPI
      .resetPassword(email)
      .then(() => {
        setResetPassword({ email });
        setAnimBox(false);
      })
      .catch((error) => {
        if (error.code == "auth/user-not-found")
          setErrorMessage(lang.ERROR_USER_NOT_FOUND);
        else {
          console.warn(
            "Forgotten password action raised an unexpected error. ",
            error
          );
          setErrorMessage(lang.ERROR_UNEXPECTED);
        }
        setAnimBox(false);
      });
  }, []);

  const closeResetPwd = useCallback(() => {
    setErrorMessage(false);
    setResetPassword(null);
  }, []);

  return (
    <View style={props.fullScreen}>
      <View style={props.screen}>
        <ImageBackground {...props.background}>
          <View {...props.logoWrapper}>
            <Image {...props.logo} />
            <View style={{ transform: [{ rotate: "-10deg" }] }}>
              <Text
                style={[
                  props.tagLine.style,
                  // { fontSize: size[props.tagLine.scaledFontSize] },
                ]}
              >
                {lang.TAGLINE}
              </Text>
            </View>
          </View>

          <View
            style={{
              height: 320,
              justifyContent: "flex-end",
              overflow: "hidden",
            }}
          >
            {showInfo ? (
              <Information
                containerStyle={{
                  paddingBottom: 30,
                  flex: 1,
                  justifyContent: "flex-end",
                }}
              />
            ) : (
              <View style={props.auth.containerStyle}>
                {(() => {
                  if (animBox)
                    return (
                      <View style={props.animBox.wrapperStyle}>
                        <LottieView {...animBox.animation} />
                      </View>
                    );
                  else if (showWelcome || resetPassword)
                    return (
                      <View style={props.actionbox.wrapperStyle}>
                        <Button
                          {...props.actionbox.closeButton}
                          onPress={() =>
                            showWelcome ? closeUnverified() : closeResetPwd()
                          }
                        />
                        {showWelcome ? (
                          <View style={props.actionbox.textWrapper}>
                            <Text style={props.actionbox.welcomeTextStyle}>
                              {lang.UNVERIFIED_WELCOME +
                                (user.displayName || "") +
                                " !"}
                            </Text>
                            <Text style={props.actionbox.textStyle}>
                              {lang.UNVERIFIED_GREETINGS}
                            </Text>
                            <Text style={props.actionbox.textStyle}>
                              {lang.UNVERIFIED_EXPLAIN}
                            </Text>
                            <Text style={props.actionbox.textStyle}>
                              {lang.UNVERIFIED_RESEND}
                            </Text>
                          </View>
                        ) : (
                          <View style={props.actionbox.textWrapper}>
                            <Text
                              style={[
                                props.actionbox.textStyle,
                                { marginTop: 20, textAlign: "center" },
                              ]}
                            >
                              {lang.RESET_PWD_TEXT}
                            </Text>
                          </View>
                        )}

                        {showWelcome ? (
                          <Button
                            {...props.actionbox.resendButton}
                            onPress={() => onEmailVerification()}
                            title={lang.UNVERIFIED_RESEND_BUTTON}
                          />
                        ) : null}
                      </View>
                    );

                  // default
                  return (
                    <View style={{ padding: 10 }}>
                      <MessageBox
                        message={errorMessage}
                        textStyle={props.auth.errorMessage.textStyle}
                        wrapperStyle={props.auth.errorMessage.wrapperStyle}
                      />
                      <Formik
                        initialValues={{
                          displayName: "",
                          email: "",
                          password: "",
                        }}
                        onSubmit={(values) =>
                          isSignUp ? onRegister(values) : onLogin(values)
                        }
                        validationSchema={
                          isSignUp
                            ? signUpValidationSchema
                            : loginValidationSchema
                        }
                      >
                        {({ values, setFieldTouched, submitForm }) => (
                          <>
                            <View style={props.auth.form.wrapperStyle}>
                              {isSignUp ? (
                                <FormInput
                                  leftIcon={props.auth.form.iconAccount}
                                  name="displayName"
                                  placeholder={lang.USERNAME_PLACEHOLDER}
                                  {...props.input}
                                />
                              ) : null}
                              <FormInput
                                leftIcon={props.auth.form.iconEmail}
                                name="email"
                                placeholder={lang.EMAIL_PLACEHOLDER}
                                {...props.input}
                              />
                              <View style={props.auth.form.passwordWrapper}>
                                <FormInput
                                  leftIcon={props.auth.form.iconPassword}
                                  name="password"
                                  placeholder={lang.PASSWORD_PLACEHOLDER}
                                  secureTextEntry={true}
                                  {...props.input}
                                  {...props.auth.form.passwordInput}
                                />
                                {isSignUp ? null : (
                                  <Button
                                    onPress={async () => {
                                      if (
                                        !(await setFieldTouched("email"))[
                                          "email"
                                        ]
                                      )
                                        forgotPassword(values["email"]);
                                    }}
                                    title={lang.FORGOTTEN_PASSWORD}
                                    {...props.auth.buttons.passwordForgotten}
                                  />
                                )}
                              </View>
                            </View>
                            <View style={props.auth.buttons.wrapperStyle}>
                              <Button
                                onPress={submitForm}
                                title={isSignUp ? lang.SIGNUP : lang.LOGIN}
                                {...props.auth.buttons.valid}
                              />
                              <Button
                                onPress={toggleAuth}
                                title={
                                  isSignUp
                                    ? lang.SWITCH_TO_LOGIN
                                    : lang.SWITCH_TO_SIGNUP
                                }
                                {...props.auth.buttons.switch}
                              />
                            </View>
                          </>
                        )}
                      </Formik>
                    </View>
                  );
                })()}
              </View>
            )}
          </View>
          {isSignUp ? (
            <TouchableOpacity
              style={props.conditions.messageContainerStyle}
              onPress={() => setShowInfo(!showInfo)}
            >
              <Text {...props.conditions.messageText}>
                {showInfo ? lang.CONDITIONS_END : lang.CONDITIONS}
              </Text>
            </TouchableOpacity>
          ) : null}
        </ImageBackground>
      </View>
    </View>
  );
}
