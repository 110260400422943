import React, { useEffect } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Constants from "expo-constants";

import { Text, View } from "react-native";
import AdminHome from "../screens/admin/AdminHome";
import DataList from "../screens/admin/DataList";
import DataEditor from "../screens/admin/DataEditor";
import UsersList from "../screens/users/UsersList";
import AccountDetails from "../screens/users/AccountDetails";

// App data
import routes from "../navigation/routes";

const Stack = createStackNavigator();

const AdminNavigator = () => {
  return (
    <Stack.Navigator
      initialRouteName={routes.ADMIN_HOME}
      screenOptions={{
        cardStyle: { backgroundColor: "transparent" },
        headerStyle: {
          backgroundColor: "#00bcd4",
          height: 40,
          borderBottomWidth: 2,
          borderTopWidth: 2,
          borderColor: "white",
          borderBottomColor: "white",
        },
        headerTitleStyle: { color: "white" },
        headerShown: true,
        headerTitleAlign: "center",
        title: "Beach Tribes",
      }}
    >
      <Stack.Screen
        name={routes.ADMIN_HOME}
        component={AdminHome}
        options={{
          headerLeft: null,
          // title: `App Management ${Constants.manifest.extra.buildFor}`,
          headerTitle: () => (
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 20, color: "white", lineHeight: 20 }}>
                App Management{" "}
              </Text>
              <Text style={{ fontSize: 12, color: "white", lineHeight: 20 }}>
                {Constants.manifest.extra.buildFor}
              </Text>
            </View>
          ),
        }}
      />
      <Stack.Screen
        name={routes.DATA_LIST}
        component={DataList}
        options={{
          headerShown: true,
          title: "Beach Tribes",
        }}
      />
      <Stack.Screen
        name={routes.DATA_EDITOR}
        component={DataEditor}
        options={{
          headerShown: true,
          title: "Beach Tribes",
        }}
      />
      <Stack.Screen
        name={routes.ADMIN_USERS_LIST}
        component={UsersList}
        options={{
          headerShown: true,
          title: "Users",
        }}
      />
      <Stack.Screen
        name={routes.ADMIN_ACCOUNT_DETAILS}
        component={AccountDetails}
        options={{
          headerShown: true,
          title: "User editing",
        }}
      />
    </Stack.Navigator>
  );
};

export default AdminNavigator;
