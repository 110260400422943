export default ({ colors, typos }) => ({
  dataSetItem: {
    dimmedColor: "#999999",
    fullColor: "#333333",
    separatorColor: "#666666",
  },
  dataWrapper: {
    style: {
      borderColor: "#666666",
      borderTopWidth: 1,
      marginTop: 0,
    },
  },
  addNewButton: {
    containerStyle: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingRight: 5,
      paddingVertical: 3,
      width: "100%",
    },
    iconProps: {
      name: "plus",
      type: "simple-line-icon",
      size: 24,
      color: "gray",
      style: { width: 24 },
    },
    textStyle: {
      color: "gray",
      paddingRight: 20,
    },
    touchableProps: {
      activeColor: "gray",
      backgroundColor: colors.lighter,
      // containerStyle: { borderBottomWidth: 1 },
      separatorColor: "none",
    },
  },
  screen: {
    style: {
      backgroundColor: "#f0f0f0",
      flex: 1,
      flexDirection: "column",
      width: "100%",
    },
  },
});

