export default ({ colors }) => ({
  containerStyle: {
    flex: 1,
  },
  credits: {
    containerStyle: {
      height: 40,
      borderBottomWidth: 1,
      borderColor: colors.darkPrimary,
      backgroundColor: colors.primary,
    },
  },
  messages: {
    containerStyle: {
      padding: 16,
    },
    webContainerStyle: {
      backgroundColor: "white",
      padding: 4,
      borderRadius: 6,
    },
  },
});
