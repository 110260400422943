export default ({
  button,
  colors,
  downArrowIcon,
  iconButton,
  input,
  miniAvatar,
  picker,
  shadows,
  typos,
  upArrowIcon,
}) => ({
  addGuest: {
    buttonAdd: {
      ...button,
      containerStyle: {
        ...button.containerStyle,
        marginBottom: 3,
      },
    },
    buttonUpDown: {
      ...iconButton,
      borderStyle: {
        ...iconButton.borderStyle,
        borderWidth: 0,
      },
      iconProps: {
        ...iconButton.iconProps,
        ...downArrowIcon,
        name: "plus",
        color: colors.offText,
        size: 24,
      },
      inactiveBackgroundColor: colors.accent,
    },
    buttonUp: {
      iconProps: {
        ...iconButton.iconProps,
        ...upArrowIcon,
        color: colors.offText,
        size: 24,
      },
    },
    container: {
      backgroundColor: colors.lightPrimary,
      borderBottomColor: colors.divider,
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      paddingRight: 12,
      paddingLeft: 20,
      paddingBottom: 16,
      paddingTop: 20,
      width: "100%",
    },
    divider: {
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
    },

    form: {
      avatar: {
        avatarStyle: {
          borderRadius: 20,
          borderWidth: 0,
        },
        containerStyle: {
          ...shadows.lightAura,
          justifyContent: "center",
          borderWidth: 3,
          borderColor: colors.offText,
          borderRadius: 20,
          backgroundColor: colors.divider,
          height: 42,
          width: 42,
          zIndex: 4,
        },
        overlayContainerStyle: {},
        placeholderStyle: {},
        pictureSize: 30,
        rounded: true,
        size: 28,
      },
      input: {
        ...input,
        containerStyle: {
          ...input.containerStyle,
          flex: 1,
          height: 24,
        },
        errorStyle: { height: 0 },
        inputContainerStyle: {
          ...input.inputContainerStyle,
          backgroundColor: colors.transparent,
        },
        inputStyle: {
          ...input.inputStyle,
          backgroundColor: colors.background,
          borderColor: colors.divider,
          borderWidth: 1,
          borderRadius: 8,
          paddingHorizontal: 12,
          width: "100%",
        },
      },
      picker: {
        ...picker,
        containerStyle: {
          flex: 1,
          marginVertical: 4,
          paddingRight: 10,
        },
        labelContainerStyle: { left: 2 },
        labelStyle: {
          ...picker.labelStyle,
          borderColor: colors.transparent,
          size: 12,
          color: colors.subText,
        },
        style: {
          ...picker.style,
          size: 10,
          backgroundColor: colors.background,
        },
      },
      wrapperStyle: {
        flex: 1,
        // width: "100%",
      },
    },

    textInfoNew: {
      style: {
        ...typos.titleText,
        color: colors.text,
        fontSize: 14,
      },
    },
    textInfoSelect: {
      style: {
        ...typos.titleText,
        color: colors.text,
        fontSize: 14,
      },
    },
    textInfoDetails: {
      style: {
        ...typos.subTitleText,
        color: colors.subText,
        fontSize: 11,
      },
    },
    textWrapper: {
      alignItems: "center",
      marginLeft: 10,
      flex: 1,
    },
  },

  avatarProps: {
    ...miniAvatar,
    containerStyle: {
      ...miniAvatar.containerStyle,
      marginHorizontal: 2,
      marginVertical: 4,
    },
    size: 36,
  },

  headStyle: {
    backgroundColor: colors.background,
    justifyContent: "center",
    height: 40,
  },

  iconProps: {
    color: colors.dark,
    name: "close",
    size: 24,
    type: "antdesign",
  },

  scrollViewStyle: {
    backgroundColor: colors.translucent,
    borderColor: colors.white,
    flex: 1,
    paddingHorizontal: 6,
  },

  scrollViewContentDivider: {
    borderTopWidth: 1,
    borderColor: colors.lighterTranslucent,
    height: 0,
    paddingHorizontal: 4,
  },

  searchInputProps: {
    ...input,
    errorStyle: { height: 0, margin: 0, padding: 0 },
    leftIcon: { color: colors.accent, name: "search1", type: "antdesign" },
  },

  userWrapperStyle: {
    borderBottomColor: colors.lighterTranslucent,
    borderBottomWidth: 1,
    paddingBottom: 0,
    // marginBottom: 4,
    paddingHorizontal: 4,
    width: "100%",
  },

  usernameStyle: {
    ...typos.titleText,
    color: colors.darkText,
    // fontFamily: "Roboto",
    // fontSize: 14,
    marginLeft: 20,
  },

  guestWrapperStyle: {
    borderColor: colors.darkPrimary,
    borderRadius: 4,
    borderWidth: 1,
    marginLeft: 12,
    marginTop: 4,
  },

  guestParentNameStyle: {
    ...typos.regular,
    marginHorizontal: 12,
  },

  guestTextStyle: {
    ...typos.regularText,
    color: colors.darkPrimary,
  },
});
