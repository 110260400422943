import React, { createContext, useContext } from "react";

import * as firebase from "firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import "@firebase/functions";
import "@firebase/analytics";
import "@firebase/performance";

import userAPI from "./user";
import sessionsAPI from "./sessions";
import adminAPI from "./admin";
import storeAPI from "./store";

import { ConfigContext } from "./contexts";

const BackendContext = createContext(null);
export { BackendContext };

export default ({ storeDispatch, children }) => {
  const { firebaseConfig, region } = useContext(ConfigContext);

  let backend = {
    app: null,
    database: null,
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);

    userAPI.initiateUser(storeDispatch, firebase, region);
    adminAPI.initiateAdmin(storeDispatch, firebase, region);
    sessionsAPI.initiateSessions(storeDispatch, firebase, region);
    storeAPI.initiateStore(storeDispatch, firebase, region);

    backend = {
      app: firebase,
      database: firebase.firestore,
    };
  }

  return (
    <BackendContext.Provider value={backend}>
      {children}
    </BackendContext.Provider>
  );
};
