import React from "react";
import { Text, View } from "react-native";
import { CheckBox } from "react-native-elements";
import { useFormikContext } from "formik";

const bool = (val) => {
  return typeof val == "boolean" ? val : val == "true";
};

export default function FormCheckBox({
  label,
  name,
  labelStyle,
  ...otherProps
}) {
  const { setFieldValue, values } = useFormikContext();
  return (
    <View style={{ flexDirection: "row"}}>
      <CheckBox
        checked={bool(values[name])}
        onPress={() => setFieldValue(name, !bool(values[name]))}
        {...otherProps}
        title={label}
        textStyle={labelStyle}
      />
      
    </View>
  );
}
