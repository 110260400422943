import React, { useState, useEffect } from "react";
import { Avatar as InitialAvatar } from "react-native-elements";

const getInitials = (name) => {
  if (typeof name !== "string") return "";
  let initials = name.match(/\b\w/g) || [];
  return (
    (initials.shift() || "").toUpperCase() + (initials.pop() || name[1] || "")
  );
};

export function Avatar({ avatar, username, picture, ...otherProps }) {
  const [source, setSource] = useState(null);
  const [shortName, setShortName] = useState(null);

  useEffect(() => {
    if (avatar && typeof avatar === "string") {
      if (avatar.startsWith("data:")) setSource(avatar);
      else setShortName(avatar.substr(0, 2));
    } else {
      setSource(null);
      setShortName(null);
    }
  }, [avatar]);

  return (
    <InitialAvatar
      rounded
      title={picture ? null : username ? getInitials(username) : shortName}
      source={picture || source ? { uri: picture || source } : null}
      {...otherProps}
    />
  );
}
