import React from "react";
import { useFormikContext } from "formik";
import { Avatar } from "../Avatar";
import * as ImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";

const maxPixelRatio = 2;

export default function FormAvatar({
  enabled = true,
  miniAvatarFieldName,
  avatarFieldName,
  usernameFieldName,
  getMiniOnly = false,
  miniPictureSize = 48,
  onValueChange,
  pictureSize = 192,
  ...otherAvatarProps
}) {
  const { values, setFieldValue } = useFormikContext();

  const selectPicture = async () => {
    try {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        allowsMultipleSelection: false,
        base64: true,
      });
      if (!result.cancelled) {
        const { sizedPicture, miniPicture } = await resizePicture(
          result,
          pictureSize,
          miniPictureSize,
          0.8
        );
        if (!getMiniOnly) setFieldValue(avatarFieldName, sizedPicture);
        setFieldValue(miniAvatarFieldName, miniPicture);
        if (onValueChange) {
          if (!getMiniOnly) onValueChange(miniPicture, sizedPicture);
          else onValueChange(miniPicture);
        }
      }
    } catch (error) {
      console.warn("Error accessing picture.", error);
    }
  };

  return (
    <Avatar
      onPress={enabled ? selectPicture : null}
      size={pictureSize}
      username={values[usernameFieldName]}
      picture={values[getMiniOnly ? miniAvatarFieldName : avatarFieldName]}
      {...otherAvatarProps}
      overlayContainerStyle={{ backgroundColor: null }}
      placeholderStyle={{ backgroundColor: null }}
    />
  );
}

const resizePicture = async (picture, size, miniSize, compression = 0.6) => {
  const cropSize = Math.min(picture.width, picture.height) - 1;
  const originX = (picture.width - cropSize) / 2;
  const originY = (picture.height - cropSize) / 2;

  const targetSize = size * maxPixelRatio;
  const targetMiniSize = miniSize * maxPixelRatio;

  const cropPicture = await ImageManipulator.manipulateAsync(picture.uri, [
    {
      crop: {
        originX,
        originY,
        height: cropSize, //- originY,
        width: cropSize, //- originX,
      },
    },
  ]).catch((e) => console.warn(e));

  let sizedPicture = await ImageManipulator.manipulateAsync(
    cropPicture.uri,
    [{ resize: { height: targetSize, width: targetSize } }],
    {
      base64: true,
      compress: compression,
      format: ImageManipulator.SaveFormat.JPEG,
    }
  );

  let miniPicture = await ImageManipulator.manipulateAsync(
    cropPicture.uri,
    [{ resize: { height: targetMiniSize, width: targetMiniSize } }],
    {
      base64: true,
      compress: compression,
      format: ImageManipulator.SaveFormat.JPEG,
    }
  );

  sizedPicture = sizedPicture.uri.startsWith("data:image/jpeg;base64,")
    ? sizedPicture.base64
    : "data:image/jpeg;base64," + sizedPicture.base64;

  miniPicture = miniPicture.uri.startsWith("data:image/jpeg;base64,")
    ? miniPicture.base64
    : "data:image/jpeg;base64," + miniPicture.base64;

  return { sizedPicture, miniPicture };
};
