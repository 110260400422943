// librairies
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";

// components
import { View, ScrollView, Text, TouchableOpacity } from "react-native";
import { Avatar } from "../../components";
import { Input } from "react-native-elements";
import Loading from "../Loading";
import NewGuest from "./components/NewGuest";

// App data
import { LanguageContext, StylesContext } from "../../data/contexts";

// expected in route.params:
//    onSelect - function -  called when a user is pressed.
//    displayAddGuest - boolean - true if you want to be able to add a guest
//    showSearch - boolean - display search input if true
//    users - object - containing a property for each user.
export default function UsersList({ navigation, route }) {
  const [searchText, setSearchText] = useState("");

  const lang = useContext(LanguageContext);
  const { UsersList: props } = useContext(StylesContext);

  const avatars = useSelector((state) => state.user["avatars"] || {});

  const onPressPlayer = (user) => {
    const newUser = { ...user[1], uid: user[0] };
    delete newUser.userID;
    route.params.onSelect(newUser);
    if (!route.params.ignoreGoBack) navigation.goBack();
  };

  const onNewGuest = (guest) => {
    const newGuest = { ...guest, isGuest: true };
    delete newGuest.avatar;
    route.params.onSelect(newGuest);
    if (!route.params.ignoreGoBack) navigation.goBack();
  };

  if (!avatars || !route.params) return <Loading />;
  else
    return (
      <View style={{ flex: 1 }}>
        {route.params.displayAddGuest ? (
          <NewGuest
            styles={props.addGuest}
            lang={lang}
            onNewGuest={onNewGuest}
          />
        ) : null}
        {route.params.showSearch ? (
          <View style={props.headStyle}>
            <Input
              {...props.searchInputProps}
              onChangeText={(text) => setSearchText((text || "").toLowerCase())}
              placeholder={lang.SEARCH_USER}
            />
          </View>
        ) : null}
        <View style={props.scrollViewStyle}>
          <ScrollView
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            <View style={{ flex: 1 }}>
              <View style={props.scrollViewContentDivider}></View>
              {Object.entries(route.params.users || {}).map((entry, index) => {
                const displayName = (entry[1]?.displayName || "").toLowerCase();
                const fullName = (entry[1]?.fullName || "").toLowerCase();

                if (
                  !searchText ||
                  displayName.includes(searchText) ||
                  fullName.includes(searchText)
                )
                  return (
                    <TouchableOpacity
                      key={index}
                      onPress={() => onPressPlayer(entry)}
                    >
                      <View
                        style={[
                          { flexDirection: "row", alignItems: "center" },
                          props.userWrapperStyle,
                          entry[1].highlight,
                        ]}
                      >
                        <Avatar
                          avatar={
                            avatars[entry[0]]
                              ? avatars[entry[0]]
                              : entry[1].displayName
                          }
                          {...props.avatarProps}
                        />
                        <Text style={props.usernameStyle}>
                          {entry[1].displayName}
                        </Text>
                        {entry[1].isGuest ? (
                          <>
                            <View style={props.guestWrapperStyle}>
                              <Text style={props.guestTextStyle}>
                                {lang.GUEST}
                              </Text>
                            </View>
                            {entry[1].parentName ? (
                              <Text
                                style={props.guestParentNameStyle}
                              >{`( ${entry[1].parentName} )`}</Text>
                            ) : null}
                          </>
                        ) : null}
                      </View>
                    </TouchableOpacity>
                  );
                else return null;
              })}
            </View>
          </ScrollView>
        </View>
      </View>
    );
}
