import React, { useContext } from "react";
import { useSelector } from "react-redux";

// components
import { Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import { Avatar, Button } from "../../../components";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import Swipeable from "react-native-gesture-handler/Swipeable";
import { LanguageContext } from "../../../data/contexts";

export default function FormPlayersList({
  avatarSize = 36,
  isPlayersEditor = false,
  maxNumPlayers,
  maxWaitlist,
  onAddPlayer,
  onRemovePlayer,
  onViewPlayer,
  playerCanAdd = false,
  playerCanCancel = false,
  players = [],
  props,
  session,
  user,
}) {
  const avatars = useSelector((state) => state.user["avatars"] || {});
  const UIdata = useSelector((state) => state.user["UIdata"] || false);
  const lang = useContext(LanguageContext);

  return (
    <View style={props.wrapperStyle}>
      {onAddPlayer &&
      (maxWaitlist || 0) + maxNumPlayers > players.length &&
      playerCanAdd ? (
        <Button {...props.addButtonProps} onPress={onAddPlayer} />
      ) : null}
      {(() => {
        if (Array.isArray(players))
          return players.map((player, index) => {
            const borderColor = getBorderColor(
              props.avatarProps,
              isPlayersEditor,
              player,
              session,
              UIdata,
              user
            );

            return (
              <View key={index} style={props.playerWrapper}>
                <Swipeable
                  childrenContainerStyle={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  containerStyle={{ width: "100%" }}
                  renderRightActions={() => {
                    if (
                      (isPlayersEditor ||
                        player.uid === user.uid ||
                        (user.dependants || []).find(
                          (member) => member.uid == player.uid
                        )) &&
                      playerCanCancel
                    )
                      return (
                        <View style={props.swipeStyle}>
                          <Icon
                            {...props.removeButtonStyle}
                            onPress={() => onRemovePlayer(player)}
                          />
                        </View>
                      );
                    else return null;
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flex: -1,
                    }}
                  >
                    <Avatar
                      avatar={avatars[player.uid] || player.displayName || "?"}
                      size={avatarSize}
                      {...props.avatarProps}
                      containerStyle={{
                        ...props.avatarProps.containerStyle,
                        borderColor,
                      }}
                      onPress={() => {
                        if (isPlayersEditor && onViewPlayer)
                          onViewPlayer(player);
                      }}
                    />
                    <Text {...props.displayNameProps}>
                      {player.displayName}
                    </Text>
                    <Text {...props.fullNameProps}>
                      {player.parentName
                        ? ` (guest of ${player.parentName}) `
                        : player.fullName
                        ? ` (${player.fullName}) `
                        : null}
                    </Text>
                  </View>
                  {(isPlayersEditor ||
                    player.uid === user.uid ||
                    (user.dependants || []).find(
                      (member) => member.uid == player.uid
                    )) &&
                  playerCanCancel ? (
                    <Icon {...props.swipeLeftToCancelProps} />
                  ) : null}
                </Swipeable>
              </View>
            );
          });
      })()}
    </View>
  );
}

const getBorderColor = (
  avatarProps,
  isPlayersEditor,
  player,
  session,
  UIdata,
  user
) => {
  let borderColor = avatarProps.playerColors.other;
  const isUser = player.uid == user.uid;
  const guestMember = (user.dependants || []).find(
    (member) => member.uid == player.uid
  );

  if (isUser) borderColor = avatarProps.playerColors.user;
  if (guestMember) borderColor = avatarProps.playerColors.tribe;

  if (isUser || guestMember || isPlayersEditor) {
    // color it
    const playerDetail =
      guestMember && guestMember.isGuest
        ? guestMember
        : (UIdata.users || {})[player.uid] || {};

    if (
      (session.age_group &&
        playerDetail.age_group &&
        session.age_group !== playerDetail.age_group) ||
      (session.category &&
        playerDetail.category &&
        session.category !== playerDetail.category)
    )
      borderColor = avatarProps.playerColors.unfit;
  }
  return borderColor;
};
