import { createStore, combineReducers } from "redux";

// import uiReducer from "./ui";
import { userReducer } from "./user";
import { networkReducer } from "./network";
import { adminReducer } from "./admin";
import { sessionReducer } from "./sessions";

const rootReducer = combineReducers({
  admin: adminReducer,
  user: userReducer,
  network: networkReducer,
  sessions: sessionReducer,
});

export default function () {
  return createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
}
