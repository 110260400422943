import React, { useCallback, useContext } from "react";
import { useSelector } from "react-redux";

//ui components
import { View, Text } from "react-native";
import MenuItem from "../../components/MenuItem";
import { ScrollView } from "react-native-gesture-handler";

// App data
import routes from "../../navigation/routes";
import { LanguageContext, StylesContext } from "../../data/contexts";

export default function AdminHome({ navigation }) {
  const lang = useContext(LanguageContext);
  const { AdminHome: props } = useContext(StylesContext);
  const UIdata = useSelector((state) => state.user["UIdata"] || false);

  const goTo = useCallback(
    (dataSet) => {
      navigation.navigate(routes.DATA_LIST, { ...dataSet });
    },
    [navigation]
  );

  const goToUsers = useCallback(() => {
    const usersList = Object.keys(UIdata.users || {}).reduce((acc, cv) => {
      if (cv) {
        const _user = UIdata.users[cv];
        acc[cv] = {
          ..._user,
          parentName: UIdata.users[cv.slice(0, cv.indexOf("_"))]?.displayName,
        };
      }
      return acc;
    }, {});
    navigation.navigate(routes.ADMIN_USERS_LIST, {
      ignoreGoBack: true,
      onSelect: (player) => {
        navigation.navigate(routes.ADMIN_ACCOUNT_DETAILS, {
          player,
          //viewGuest: player.uid.endsWith(player.displayName),
          viewGuest: player.isGuest
        });
      },
      showSearch: true,
      users: usersList,
    });
  });

  return (
    <ScrollView
      style={{ flex: 1 }}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      <View {...props.titleWrapper}>
        <Text {...props.title}>{lang.USERS_TITLE}</Text>
      </View>
      <MenuItem
        {...props.menuItem}
        onPress={goToUsers}
        target={{ table: "users", title: lang.USERS }}
      >
        <Text {...props.link}>{lang.USERS}</Text>
      </MenuItem>
      <View {...props.titleWrapper}>
        <Text {...props.title}>{lang.ACTIVITY_TITLE}</Text>
      </View>
      <MenuItem
        {...props.menuItem}
        onPress={goTo}
        target={{ table: "age_groups", title: lang.AGE_GROUPS }}
      >
        <Text {...props.link}>{lang.AGE_GROUPS}</Text>
      </MenuItem>

      <MenuItem
        {...props.menuItem}
        onPress={goTo}
        target={{ table: "categories", title: lang.CATEGORIES }}
      >
        <Text {...props.link}>{lang.CATEGORIES}</Text>
      </MenuItem>

      <MenuItem
        {...props.menuItem}
        onPress={goTo}
        target={{ table: "locations", title: lang.LOCATIONS }}
      >
        <Text {...props.link}>{lang.LOCATIONS}</Text>
      </MenuItem>

      <MenuItem
        {...props.menuItem}
        onPress={goTo}
        target={{ table: "rules", title: lang.RULES }}
      >
        <Text {...props.link}>{lang.RULES}</Text>
      </MenuItem>

      <MenuItem
        {...props.menuItem}
        onPress={goTo}
        target={{ table: "session_types", title: lang.SESSION_TYPES }}
      >
        <Text {...props.link}>{lang.SESSION_TYPES}</Text>
      </MenuItem>
    </ScrollView>
  );
}
