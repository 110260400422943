import React, { useContext } from "react";
import { View } from "react-native";
import { LottieView } from "../components";

import { StylesContext } from "../data/contexts";

export default function Loading() {
  const { Loading: props } = useContext(StylesContext);

  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        height: 280,
        padding: 10,
        paddingTop: 0,
      }}
    >
      <LottieView {...props.animations.WORKING_ON_IT.animation} />
    </View>
  );
}
