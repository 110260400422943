export default ({
  animations,
  button,
  colors,
  closeIcon,
  accountIcon,
  emailIcon,
  passwordIcon,
  iconButton,
  input,
  fullScreen,
  shadows,
  screen,
  typos,
}) => ({
  animations,
  actionbox: {
    closeButton: {
      ...iconButton,
      containerStyle: {
        ...iconButton.containerStyle,
        alignSelf: "flex-end",
        right: -4,
        top: 5,
      },
      iconProps: {
        ...iconButton.iconProps,
        ...closeIcon,
      },
    },
    resendButton: {
      ...button,
      containerStyle: {
        ...button.containerStyle,
        ...shadows.lightShadow,
        justifyContent: "space-evenly",
        width: "100%",
      },
      // linearGradient: colors.gradient,
      // scaledFontSize: "h5",
      titleStyle: {
        ...button.titleStyle,
        color: colors.offText,
        fontFamily: "Roboto",
        fontSize: 16,
      },
    },
    textStyle: {
      color: colors.text,
      fontFamily: "Roboto",
      fontSize: 14,
      //margin: 5,
      marginHorizontal: 30,
      textAlign: "justify",
    },
    textWrapper: {
      alignItems: "center",
      flex: 1,
      marginTop: -10,
      marginBottom: 20,
      marginHorizontal: 5,
      justifyContent: "space-evenly",
    },
    welcomeTextStyle: {
      color: colors.text,
      textAlign: "center",
      fontFamily: "RobotoBold",
      fontSize: 16,
      marginBottom: 10,
      marginHorizontal: 30,
      // marginLeft: 10,
      // flex: 1,
    },
    wrapperStyle: {
      alignItems: "center",
      backgroundColor: colors.translucent,
      borderRadius: 12,
      justifyContent: "space-between",
      height: 280,
      padding: 10,
      paddingTop: 0,
    },
  },

  animBox: {
    wrapperStyle: {
      // backgroundColor: "white",
      alignItems: "center",
      justifyContent: "center",
      height: 280,
      padding: 10,
      paddingTop: 0,
      zIndex: 2,
    },
  },

  auth: {
    buttons: {
      passwordForgotten: {
        ...button,
        containerStyle: {
          ...button.containerStyle,
          borderWidth: 0,
          shadowOpacity: 0,
          elevation: 0,
          marginLeft: 20,
          width: "40%",
        },
        activeBackgroundColor: colors.transparent, //"rgba(128,100,80,0)",
        inactiveBackgroundColor: colors.transparent, //"rgba(128,100,80,0)", //colors.lighter,
        titleStyle: {
          ...button.titleStyle,
          color: colors.offText,
          fontFamily: "RobotoMedium",
          fontSize: 14,
          textShadowColor: colors.text,
          textShadowRadius: 10,
        },
      },

      switch: {
        ...button,
        containerStyle: {
          ...button.containerStyle,
          borderWidth: 0,
          shadowOpacity: 0,
          elevation: 0,
          marginTop: 20,
        },
        activeBackgroundColor: colors.transparent, // "rgba(128,128,128,0)",
        inactiveBackgroundColor: colors.transparent, // "rgba(128,128,128,0)",
        titleStyle: {
          ...button.titleStyle,
          color: colors.offText,
          fontFamily: "RobotoMedium",
          fontSize: 14,
          textShadowColor: colors.text,
          textShadowRadius: 10,
        },
      },

      valid: {
        ...button,
        containerStyle: {
          ...button.containerStyle,
          marginHorizontal: 8,
        },
      },
      wrapperStyle: { paddingVertical: 15 },
    },

    containerStyle: {
      alignContent: "center",
      backgroundColor: null,
      borderRadius: 20,
      justifyContent: "space-evenly",
      marginHorizontal: 20,
      marginBottom: 30,
      // padding: 10,
    },

    errorMessage: {
      wrapperStyle: {
        backgroundColor: colors.error,
        //borderTopLeftRadius: 12,
        //borderTopRightRadius: 12,
        //borderBottomLeftRadius: 2,
        //borderBottomRightRadius: 2,
        borderRadius: 6,
        height: 32,
        justifyContent: "center",
        marginHorizontal: 10,
        marginBottom: 16,
        overflow: "hidden",
        // width: "100%",
      },

      textStyle: {
        alignSelf: "center",
        color: colors.offText,
        fontFamily: "RobotoMedium",
        fontSize: 12,
      },
    },

    form: {
      iconAccount: {
        ...accountIcon,
      },
      iconEmail: {
        ...emailIcon,
      },
      iconPassword: {
        ...passwordIcon,
      },
      passwordWrapper: {
        flexDirection: "row",
        width: "100%",
        paddingHorizontal: 10,
      },
      passwordInput: {
        containerStyle: {
          ...input.containerStyle,
          includeFontPadding: false,
          width: "60%",
          paddingHorizontal: 0,
        },
        inputStyle: {
          ...input.inputStyle,
          width: "100%",
        },
      },
      wrapperStyle: { paddingTop: 10 },
    },
  },

  background: {
    blurRadius: 3,
    resizeMode: "cover",
    source: require("../../styles/assets/background.jpeg"),
    style: {
      backgroundColor: "black",
      flex: 1,
      flexDirection: "column",
    },
  },

  conditions: {
    messageContainerStyle: {
      position: "absolute",
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.7)",
      width: "100%",
      padding: 4,
      height: 30,
    },
    messageText: {
      style: {
        ...typos.annotation,
        color: colors.lightText,
        textAlign: "center",
      },
    },
  },

  input: {
    ...input,
    autoCapitalize: "none",
    autoCorrect: false,
    containerStyle: {
      ...input.containerStyle,
      includeFontPadding: false,
      paddingBottom: 0,
    },
    errorStyle: {
      ...input.errorStyle,
      textShadowColor: "white",
      textShadowOffset: { width: 1, height: 1 },
      textShadowRadius: 3,
      fontWeight: "bold",
    },
    inputContainerStyle: {
      ...input.inputContainerStyle,
      ...shadows.lightShadow,
      borderRadius: 6,
    },
  },

  logo: {
    source: require("../../styles/assets/logo.png"),
    style: {
      height: "80%",
      resizeMode: "contain",
      width: "80%",
    },
  },

  logoWrapper: {
    style: {
      alignItems: "center",
      flex: 1,
      top: 10,
    },
  },

  tagLine: {
    style: {
      color: colors.tagline,
      fontFamily: "Rendang",
      marginTop: "-20%",
      textShadowColor: colors.taglineShadow,
      textShadowOffset: { width: 2, height: 2 },
      fontSize: 28,
      textShadowRadius: 4,
    },
  },

  screen,
  fullScreen,

  sideScreen: {
    backgroundColor: colors.text,
    height: "100%",
    flex: 1,
    overflow: "hidden",
  },
});
