import React from "react";

// components
import { TouchableOpacity, Text, View } from "react-native";
import Session from "./Session";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";

export default function DayLocalSessions({
  court,
  scheduling,
  isSessionEditor,
  isPlayersEditor,
  onSessionCopy,
  onSelectSession,
  sessionProps,
  sessions,
  slots = [],
}) {
  return (
    <View style={{ flex: 1 }}>
      <Text {...sessionProps.courtProps}>
        {court && court !== "_" ? `(${court})` : ""}
      </Text>
      {slots.map((key, index) => {
        if (!sessions[key])
          return <View key={index} style={sessionProps.emptySlotStyle}></View>;
        if (isSessionEditor && scheduling)
          return (
            <Menu key={index} style={{ flex: 1 }}>
              <MenuTrigger
                customStyles={{
                  triggerOuterWrapper: {},
                }}
              >
                <Session
                  isSessionEditor={isSessionEditor}
                  isPlayersEditor={isPlayersEditor}
                  time={key}
                  session={sessions[key]}
                  sessionProps={sessionProps}
                />
              </MenuTrigger>
              <MenuOptions
                customStyles={{
                  optionsContainer: {
                    borderRadius: 6,
                    borderWidth: 1,
                    borderColor: "white",
                  },
                  optionWrapper: {
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    borderColor: "rgba(0, 0, 0, 0.3)",
                    borderBottomWidth: 1,
                    marginHorizontal: 3,
                    paddingVertical: 6,
                  },
                }}
              >
                <MenuOption
                  text="copy"
                  onSelect={() => {
                    onSessionCopy(sessions[key]);
                  }}
                />
                <MenuOption
                  text="details"
                  onSelect={() => {
                    onSelectSession(sessions[key]);
                  }}
                />
              </MenuOptions>
            </Menu>
          );
        else
          return (
            <TouchableOpacity
              key={index}
              onPress={() => {
                onSelectSession(sessions[key]);
              }}
              style={{ flex: 1 }}
            >
              <Session
                isSessionEditor={isSessionEditor}
                isPlayersEditor={isPlayersEditor}
                scheduling={scheduling}
                time={key}
                session={sessions[key]}
                sessionProps={sessionProps}
              />
            </TouchableOpacity>
          );
      })}
    </View>
  );
}
