export default ({ colors, shadows }) => ({
  avatar: {
    containerStyle: {
      marginHorizontal: 2,
      marginVertical: 4,
    },
  },

  miniAvatar: {
    playerColors: {
      user: colors.accent,
      tribe: colors.lightAccent,
      other: colors.offText,
      unfit: colors.error,
    },
    containerStyle: {
      backgroundColor: colors.divider,
      borderColor: colors.offText,
      borderWidth: 2,
      ...shadows.lightAura,
    },
    titleStyle: {
      fontFamily: "Droid",
    },
  },
});
