import fonts from "./fonts";

// themes
import themes from "./themes";

// base styles
import getBorderStyles from "./bases/borders";
import getShadowsStyles from "./bases/shadows";
import getTyposStyles from "./bases/typos";

// shared styles
import getButtonsStyles from "./shared/buttons";
import getFormsStyles from "./shared/forms";
import getScreensStyles from "./shared/screens";
import getAvatarsStyles from "./shared/avatars";
import getIconsStyles from "./shared/icons";
import getAnimationsStyles from "./shared/animations";

// components import => Add to getThemedStyle
import getWelcomeStyle from "../screens/auth/Welcome.props.js";
import getAppScreenStyle from "../screens/AppScreen.props.js";
import getHeaderStyle from "../screens/Header.props.js";
import getAgendaStyle from "../screens/sessions/Agenda.props.js";
import getAccountDetailsStyle from "../screens/users/AccountDetails.props.js";
import getSessionDetailsStyle from "../screens/sessions/SessionDetails.props.js";
import getUsersListStyle from "../screens/users/UsersList.props.js";
import getLoadingStyle from "../screens/Loading.props.js";
import getBuyCreditsStyle from "../screens/store/BuyCredits.props.js";
import getHitPayPaymentStyle from "../screens/store/HitPayPayment.props.js";
import getHomeStyle from "../screens/users/Home.props.js";
import getFooterStyle from "../screens/Footer.props.js";
import getAdminHomeStyle from "../screens/admin/AdminHome.props.js";
import getDataEditorStyle from "../screens/admin/DataEditor.props.js";
import getDataListStyle from "../screens/admin/DataList.props.js";
import getInformationStyle from "../screens/users/Information.props.js";

// go through themes and apply them to styles
const styles = {};
Object.entries(themes).forEach((theme) => {
  const baseStyles = {
    ...theme[1],
    borders: getBorderStyles(theme[1]),
    shadows: getShadowsStyles(theme[1]),
    typos: getTyposStyles(theme[1]),
  };

  const sharedStyles = {
    ...baseStyles,
    ...getAvatarsStyles(baseStyles),
    ...getButtonsStyles(baseStyles),
    ...getFormsStyles(baseStyles),
    ...getScreensStyles(baseStyles),
    ...getIconsStyles(baseStyles),
    ...getAnimationsStyles(baseStyles),
  };

  const style = {};
  style["Welcome"] = getWelcomeStyle(sharedStyles);
  style["AppScreen"] = getAppScreenStyle(sharedStyles);
  style["Agenda"] = getAgendaStyle(sharedStyles);
  style["Header"] = getHeaderStyle(sharedStyles);
  style["AccountDetails"] = getAccountDetailsStyle(sharedStyles);
  style["SessionDetails"] = getSessionDetailsStyle(sharedStyles);
  style["UsersList"] = getUsersListStyle(sharedStyles);
  style["Loading"] = getLoadingStyle(sharedStyles);
  style["BuyCredits"] = getBuyCreditsStyle(sharedStyles);
  style["HitPayPayment"] = getHitPayPaymentStyle(sharedStyles);
  style["Home"] = getHomeStyle(sharedStyles);
  style["Footer"] = getFooterStyle(sharedStyles);
  style["Information"] = getInformationStyle(sharedStyles);

  style["AdminHome"] = getAdminHomeStyle(sharedStyles);
  style["DataEditor"] = getDataEditorStyle(sharedStyles);
  style["DataList"] = getDataListStyle(sharedStyles);

  styles[theme[0]] = style; //getThemedStyle(theme[1]))
});

export { fonts, styles };
