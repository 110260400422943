import React from "react";
import { View, TouchableWithoutFeedback, Image } from "react-native";

export default function Overlap({
  backgroundColor = "white",
  containerStyle,
  onPress,
  children,
  zIndex = 1,
}) {
  const handlePress = (e) => {
    if (onPress) onPress();
  };

  return (
    <TouchableWithoutFeedback onPress={handlePress}>
      <View
        style={{
          backgroundColor: backgroundColor,
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: zIndex + 1,
        }}
      >
        <TouchableWithoutFeedback>
          <View style={containerStyle}>{children}</View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  );
}
