import React, { useEffect, useRef, useState } from "react";
import { View, Text } from "react-native";
import { Webview } from "../../../components";

import { Platform } from "react-native";

export default function WebMessage({ messages, urlFirst = true, styles }) {
  const [messageURL, setMessageURL] = useState(null);
  const [messageContent, setMessageContent] = useState(null);
  // const webwidth = useRef(null);
  // const [webheight, setWebheight] = useState(0);

  useEffect(() => {
    // extract first message
    if (messages) {
      const selectedMessage =
        messages[Object.keys(messages).find((key) => !messages[key].inactive)];
      if (selectedMessage) {
        if (selectedMessage.url && (urlFirst || !selectedMessage.content)) {
          setMessageContent(null);
          setMessageURL(selectedMessage.url);
        } else {
          setMessageURL(null);
          setMessageContent(selectedMessage.content);
        }
      }
    }
  }, [messages]);

  if (!(messageURL || messageContent)) return null;
  return (
    <View style={styles.containerStyle}>
      <View style={styles.webContainerStyle}>
        <Webview
          style={{ flex: Platform.OS === "android" ? 0 : 1, width: "100%" }}
          originWhitelist={["*"]}
          source={
            messageURL
              ? {
                  uri: messageURL,
                }
              : {
                  html: messageContent,
                }
          }
          scalesPageToFit={false}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          textZoom={100}
        />
      </View>
    </View>
  );
}

// baseUrl: new URL("https://www.lemonde.fr/").origin,

/*

    <View style={styles.containerStyle}>
      <View style={styles.webContainerStyle}>

<Webview
          originWhitelist={["*"]}
          source={{ html: "<h1>Hello world</h1>" }}
          style={{ marginTop: 20 }}
        />


 <Webview
          originWhitelist={["*"]}
          source={
            messageURL
              ? {
                  html: `
              <html>
                <head>
                  <meta http-equiv="refresh" content="0; URL='${messageURL}'" />
                </head>
              </html>
              `,
                  baseUrl: new URL(messageURL).origin,
                }
              : {
                  html: messageContent,
                }
          }
        />

*/
