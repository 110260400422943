import React, { useContext } from "react";
// components
import { ImageBackground, StatusBar, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
// App data
import { LanguageContext, StylesContext } from "../data/contexts";
import Footer from "./Footer";

export default function AppScreen({ children }) {
  const lang = useContext(LanguageContext);
  const { AppScreen: props } = useContext(StylesContext);

  return (
    <View style={props.fullScreen}>
      <View style={props.screen}>
        <SafeAreaView style={props.usedScreen}>
          <StatusBar {...props.statusBar} />
          <ImageBackground {...props.background}>
            <View
              style={{
                flex: 1,
                width: "100%",
                height: "100%",
              }}
            >
              {children}
            </View>
          </ImageBackground>
          <Footer />
        </SafeAreaView>
      </View>
    </View>
  );
}
