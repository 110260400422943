// librairies
import React, { useContext } from "react";
import { useSelector } from "react-redux";

// components
import { createStackNavigator } from "@react-navigation/stack";
import HeaderComponent from "../screens/Header";
import { Animated } from "react-native";

// screens
import AccountDetails from "../screens/users/AccountDetails";
import AdminNavigator from "./AdminNavigator";
import SessionDetails from "../screens/sessions/SessionDetails";
import Agenda from "../screens/sessions/Agenda";
import Home from "../screens/users/Home";
import Booking from "../screens/users/Booking";
import BuyCredits from "../screens/store/BuyCredits";
import UsersList from "../screens/users/UsersList";
import HitPayPayment from "../screens/store/HitPayPayment";
import Information from "../screens/users/Information";

// routes
import routes from "./routes";

// App data
import { StylesContext } from "../data/contexts";

const Stack = createStackNavigator();
const getHeader = ({ scene, navigation }) => {
  return <HeaderComponent scene={scene} navigation={navigation} />;
};

const HomeNavigator = () => {
  const { Header: props } = useContext(StylesContext);
  const user = useSelector((state) => (state.user || {}).details);

  return (
    <Stack.Navigator
      headerMode="float"
      headerStyle={{ height: props.containerStyle.height }}
      initialRouteName={
        user.role === "player" &&
        (!user.fullName || !user.phoneNumber || !user.displayName)
          ? routes.ACCOUNT_DETAILS
          : routes.HOME
      }
      screenOptions={{
        cardStyle: { backgroundColor: "transparent" },
        cardStyleInterpolator: forSlide,
        header: getHeader,
        headerShown: true,
        title: "Beach Tribes",
      }}
    >
      <Stack.Screen name={routes.HOME} component={Home} />
      <Stack.Screen name={routes.BUY_CREDITS} component={BuyCredits} />
      <Stack.Screen name={routes.HITPAY_PAYMENT} component={HitPayPayment} />
      <Stack.Screen name={routes.SESSION_DETAILS} component={SessionDetails} />
      <Stack.Screen name={routes.USERS_LIST} component={UsersList} />
      <Stack.Screen name={routes.AGENDA} component={Agenda} />
      <Stack.Screen name={routes.BOOKING} component={Booking} />
      <Stack.Screen name={routes.ACCOUNT_DETAILS} component={AccountDetails} />
      <Stack.Screen name={routes.ADMIN_NAVIGATOR} component={AdminNavigator} />
      <Stack.Screen
        name={routes.INFORMATION}
        component={Information}
        options={{
          cardStyle: { paddingTop: 20, backgroundColor: "transparent" },
        }}
      />
    </Stack.Navigator>
  );
};

export default HomeNavigator;

const forSlide = ({ current, next, inverted, layouts: { screen } }) => {
  const progress = Animated.add(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
      extrapolate: "clamp",
    }),
    next
      ? next.progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 1],
          extrapolate: "clamp",
        })
      : 0
  );

  return {
    cardStyle: {
      transform: [
        {
          translateX: Animated.multiply(
            progress.interpolate({
              inputRange: [0, 1, 2],
              outputRange: [
                screen.width, // Focused, but offscreen in the beginning
                0, // Fully focused
                -screen.width, // Fully unfocused
              ],
              extrapolate: "clamp",
            }),
            inverted
          ),
        },
      ],
    },
  };
};
