// Librairies
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
//import dayjs from "dayjs";
//dayjs.extend(require("dayjs/plugin/customParseFormat"));

// components
import { View, Text } from "react-native";
import PlayersList from "./PlayersList";
import { Avatar } from "../../../components";

// App data
import { LanguageContext } from "../../../data/contexts";

// API
import sessionsAPI from "../../../data/sessions";

export default function Session({
  isPlayersEditor,
  scheduling,
  session,
  sessionProps,
}) {
  const [message, setMessage] = useState("");
  const [mayAdd, setMayAdd] = useState(false);
  const [playersWidth, setPlayersWidth] = useState(null);

  const UIdata = useSelector((state) => state.user["UIdata"] || false);
  const user = useSelector((state) => state.user["details"]);

  const lang = useContext(LanguageContext);

  useEffect(() => {
    // set mayAdd
    let _mayAdd = true;
    if (!session.max_players) {
      setMessage(lang.NO_SPOT_LEFT);
      _mayAdd = false;
    } else {
      const nbPlayers = (session.players || []).length;
      const spots_left = session.max_players - nbPlayers;
      const waiting_left = (session.max_waitlist || 0) + spots_left;
      if (spots_left > 0) {
        if (spots_left == 1) setMessage(lang.ONE_SPOT_LEFT);
        else setMessage(spots_left + lang.SEVERAL_SPOT_LEFT);
      } else {
        if (waiting_left > 0) setMessage(lang.WAITING_LIST);
        else {
          setMessage(lang.NO_SPOT_LEFT);
          _mayAdd = false;
        }
      }
    }
    setMayAdd(_mayAdd);
  }, [session]);

  if (!user || !UIdata) return null;
  return (
    <View
      style={[
        sessionProps.containerStyle,
        !mayAdd ? sessionProps.sessionFull : null,
        sessionsAPI.isPast(session.date + session.time)
          ? sessionProps.pastSessionStyle
          : null,
        session.cancelled ? sessionProps.cancelledSession : null,
      ]}
    >
      <View style={{ flex: 1, flexDirection: "column" }}>
        <View
          style={{
            // flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            height: 32,
          }}
        >
          <Text {...sessionProps.timeProps}>
            {sessionsAPI.timeBackToFront(session.time)}
          </Text>
          <Text {...sessionProps.sessionTypeProps}>
            {UIdata?.session_types[session.session_type]?.short_label ||
              session.session_type}
          </Text>
          <View
            style={{
              justifyContent: "flex-end",
              flex: 2,
              flexDirection: "row",
              flexWrap: "nowrap",
              marginTop: 2,
            }}
          >
            {session.age_group || session.category ? (
              <View
                style={[
                  sessionProps.ageGroup.wrapperStyle,
                  {
                    backgroundColor:
                      (
                        UIdata["categories"].find(
                          (el) => el.label === session.category
                        ) || {}
                      ).color ||
                      sessionProps.ageGroup.wrapperStyle.defaultBackgroundColor,
                  },
                ]}
              >
                <Text {...sessionProps.ageGroup.textProps}>
                  {
                    (
                      UIdata["age_groups"].find(
                        (el) => el.label === session.age_group
                      ) || {}
                    ).short_label
                  }
                </Text>
              </View>
            ) : null}

            {/*session.category ? (
              <View style={sessionProps.category.wrapperStyle}>
                <Text {...sessionProps.category.textProps}>
                  {
                    (
                      UIdata["categories"].find(
                        (el) => el.label === session.category
                      ) || {}
                    ).short_label
                  }
                </Text>
              </View>
                ) : null*/}
          </View>
        </View>

        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {session.coach && session.coach !== "undefined" ? (
            <View
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: 1,
                zIndex: 2,
              }}
            >
              <Avatar
                avatar={
                  ((UIdata["coaches"] || {})[session.coach] || {})["avatar"]
                }
                size={30}
                {...sessionProps.coachAvatar}
              />
              <Text {...sessionProps.coachLabel}>
                {((UIdata["coaches"] || {})[session.coach] || {})["displayName"]}
              </Text>
            </View>
          ) : null}

          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <View
              onLayout={(event) =>
                setPlayersWidth(event.nativeEvent?.layout.width)
              }
              style={{
                flexDirection: "row",
                height: 30,
                width: "100%",
              }}
            >
              <PlayersList
                avatarProps={sessionProps.avatarProps}
                avatarSize={30}
                isPlayersEditor={isPlayersEditor}
                fitInParentWidth={playersWidth}
                maxNumPlayers={session.max_players}
                players={session.players}
                session={session}
                user={user}
                wrapperStyle={sessionProps.playersWrapper}
              />
            </View>
            <View style={{ width: "100%" }}>
              <Text {...sessionProps.message}>{message}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
/*
const isBeforeNow = (dateTimeString) => {
  // set past Session granted.PLAYER_CANCEL_FULL_REFUND_DELAY
  // get and format current time
  const currentTime = new dayjs();
  const cancelTime = dayjs(dateTimeString, "YYMMDDHHmm")
  return currentTime.isAfter(cancelTime);
};
*/
