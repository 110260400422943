import React from "react";
import { View } from "react-native";
import { Player } from "@lottiefiles/react-lottie-player";

export default function LottieView({
  source,
  autoplay = true,
  height = 200,
  loop = true,
  width = 200,
}) {
  return (
    <View>
      <Player
        autoplay={autoplay}
        loop={loop}
        src={source}
        style={{ height: height, width: width }}
      />
    </View>
  );
}
