import React, { createContext } from "react";
import Constants from "expo-constants";

//import { _env } from "../config/backendConfig";

import { appConfig } from "../config/appConfig";
import { styles } from "../styles";
import { useColorScheme } from "react-native-appearance";
import languages from "../config/languages";

const lang = languages[appConfig.language];

const ConfigContext = createContext(null);
const LanguageContext = createContext(null);
const StylesContext = createContext(null);

const ContextsProvider = ({ children }) => {
  let themeMode = useColorScheme();

  console.log("contexts : ", Constants.manifest.extra.buildFor);
  return (
    <>
      <LanguageContext.Provider value={lang}>
        <ConfigContext.Provider
          value={{
            appConfig,
            firebaseConfig: Constants.manifest.extra.firebaseConfig, //_env.firebaseConfig,
            region: Constants.manifest.extra.region, //_env.region,
          }}
        >
          <StylesContext.Provider value={styles[themeMode] || styles.default}>
            {children}
          </StylesContext.Provider>
        </ConfigContext.Provider>
      </LanguageContext.Provider>
    </>
  );
};

export { ContextsProvider, ConfigContext, LanguageContext, StylesContext };
