export default ({
  animations,
  button,
  colors,
  deleteIcon,
  downArrowIcon,
  iconButton,
  infoIcon,
  input,
  miniAvatar,
  picker,
  pickerDisabled,
  shadows,
  swipeLeftIcon,
  upArrowIcon,
  typos,
}) => ({
  animations,

  avatar: {
    avatarStyle: {
      borderRadius: 60,
      borderWidth: 0,
    },
    buttonsProps: {
      ...button,
      containerStyle: {
        ...button.containerStyle,
        marginBottom: 20,
        marginTop: 16,
        marginHorizontal: 24,
      },
      titleStyle: {
        ...button.titleStyle,
        margin: 8,
        fontSize: 20,
      },
    },
    containerStyle: {
      ...shadows.lightAura,
      justifyContent: "center",
      borderWidth: 3,
      borderColor: colors.offText,
      borderRadius: 60,
      backgroundColor: colors.lightPrimary,
      height: 102,
      marginLeft: 16, //marginTop: 10,
      marginTop: -24,
      width: 102,
      zIndex: 4,
    },
    pictureSize: 90,
    rounded: true,
    size: 88,
    // titleStyle: { color: "red" },
  },

  buttonLogOut: {
    ...button,
    containerStyle: {
      ...button.containerStyle,
      marginBottom: 20,
      marginTop: 16,
      marginHorizontal: 24,
    },
  },

  changed: {
    buttonSave: {
      ...button,
      titleStyle: {
        ...button.titleStyle,
        paddingHorizontal: 20,
      },
    },
    text: {
      style: {
        ...typos.titleText,
        color: colors.darkAccent,
        fontSize: 14,
      },
    },
    wrapperStyle: {
      alignItems: "center",
      backgroundColor: colors.background,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
      // height: 52,
      // width: "100%",
      flexDirection: "row",
      justifyContent: "space-around",
      padding: 20,
      // marginHorizontal: 20,
      // paddingTop: 8,
    },
  },

  credits: {
    body: {
      containerStyle: {
        padding: 10,
      },
      labelStyle: {
        ...input.labelStyle,
        borderBottomWidth: 0,
      },
      inputStyle: {
        ...input,
        containerStyle: {
          marginBottom: 3,
          marginRight: 26,
          width: 40,
        },
        errorStyle: { height: 0 },
        inputStyle: {
          ...input.inputStyle,
          borderColor: colors.divider,
          borderWidth: 1,
          borderRadius: 8,
          paddingHorizontal: 6,
          marginTop: 8,
          width: 52,
        },
        maxLength: 4,
      },
      inputComment: {
        ...input,
        multiline: true,
        numberOfLines: 2,
        containerStyle: {
          ...input.containerStyle,
          marginBottom: 3,
        },
        errorStyle: { height: 0 },
        inputContainerStyle: {
          ...input.inputContainerStyle,
          height: "auto",
        },
        inputStyle: {
          ...input.inputStyle,
          borderColor: colors.divider,
          borderWidth: 1,
          borderRadius: 8,
          paddingHorizontal: 6,
          paddingVertical: 2,
          marginTop: 8,
          maxHeight: "auto",
        },
      },
    },
    button: { ...button },
    containerStyle: {
      borderRadius: 6,
      backgroundColor: colors.background,
      flex: 1,
      marginHorizontal: 20,
      marginBottom: 20,
    },
    headerStyle: {
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      flexDirection: "row",
      backgroundColor: colors.primary,
      alignItems: "center",
      height: 28,
      justifyContent: "space-between",
    },
    titleStyle: {
      ...typos.titleText,
      color: colors.offText,
      paddingHorizontal: 10,
    },
    balanceStyle: {
      ...typos.regularText,
      borderRadius: 20,
      height: 26,
      width: 26,
      backgroundColor: colors.lighterPrimary,
      color: colors.darkPrimary,
      textAlign: "center",
      lineHeight: 26,
      marginHorizontal: 10,
    },
  },
  //creditsContainer: {
  //  marginHorizontal: 20,
  //  marginBottom: 10,
  //},

  formWrapperStyle: {
    backgroundColor: colors.background,
    borderRadius: 8,
    flex: 1,
    margin: 5,
    padding: 5,
    paddingVertical: 0,
    marginTop: 15,
  },

  input: {
    ...input,
    containerStyle: {
      ...input.containerStyle,
      marginBottom: 3,
    },
    errorStyle: { height: 0 },
    inputDisabledStyle: {
      ...input.inputDisabledStyle,
      borderColor: colors.transparent,
    },
    inputStyle: {
      ...input.inputStyle,
      borderColor: colors.divider,
      borderWidth: 1,
      borderRadius: 8,
      paddingHorizontal: 6,
      marginTop: 8,
    },
    labelStyle: { ...input.labelStyle, borderColor: colors.transparent },
  },

  inputAbout: {
    ...input,
    containerStyle: {
      ...input.containerStyle,
      marginBottom: 3,
    },
    errorStyle: { height: 0 },
    inputContainerStyle: {
      ...input.inputContainerStyle,
      height: "auto",
    },
    inputDisabledStyle: {
      ...input.inputDisabledStyle,
      borderColor: colors.transparent,
    },
    inputStyle: {
      ...input.inputStyle,
      borderColor: colors.divider,
      borderWidth: 1,
      borderRadius: 8,
      paddingHorizontal: 6,
      paddingVertical: 2,
      marginTop: 8,
      maxHeight: "auto",
    },
    labelStyle: { ...input.labelStyle, borderColor: colors.transparent },
  },

  inputDisabled: {
    labelStyle: { ...input.labelStyle, borderColor: colors.divider },
  },

  inputErrorStyle: {
    ...input.errorStyle,
    height: 8,
    textAlign: "right",
    paddingTop: 2,
  },

  picker: {
    ...picker,
    labelStyle: {
      ...picker.labelStyle,
      borderColor: colors.transparent,
    },
    containerStyle: {
      ...picker.containerStyle,
      marginBottom: 10,
    },
  },
  pickerDisabled: {
    ...pickerDisabled,
    labelStyle: { ...picker.labelStyle, borderColor: colors.transparent },
    containerStyle: { ...picker.containerStyle, marginBottom: 10 },
  },

  tribe: {
    containerStyle: {
      marginHorizontal: 20,
      marginBottom: 20,
    },
    footer: {
      backgroundColor: colors.background,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
      height: 6,
      width: "100%",
    },
    header: {
      addButton: {
        ...button,
        containerStyle: {
          ...button.containerStyle,
          height: 20,
          marginVertical: 4,
          marginHorizontal: 8,
          // flex: 0,
        },
        titleStyle: {
          ...button.titleStyle,
          color: colors.primary,
          fontSize: 14,
          paddingHorizontal: 6,
        },
        inactiveBackgroundColor: colors.background,
        activeBackgroundColor: colors.subText,
      },
      //iconProps: { ...infoIcon,color: colors.offText,size: 24,style: {marginTop: 2,},},
      wrapperStyle: {
        backgroundColor: colors.primary,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        flexDirection: "row",
        justifyContent: "space-between",
        height: 28,
        width: "100%",
      },
      textProps: {
        numberOfLines: 1,
        style: {
          ...typos.titleText,
          color: colors.offText,
          lineHeight: 28,
          paddingHorizontal: 12,
        },
      },
    },

    members: {
      avatar: {
        ...miniAvatar,
        containerStyle: {
          ...miniAvatar.containerStyle,
          marginRight: 10,
          marginVertical: 5,
        },
      },
      delete: {
        iconProps: { ...deleteIcon },
      },
      contract: {
        ...iconButton,
        containerStyle: {
          borderRadius: 14,
          justifyContent: "center",
          height: 20,
          marginRight: 2,
          width: 20,
        },
        iconProps: {
          containerStyle: {
            alignItems: "center",
            borderColor: colors.primary,
            borderWidth: 2,
            borderRadius: 12,
            height: 20,
            width: 20,
          },
          style: {
            height: 20,
            justifyContent: "center",
            top: -2,
            width: 20,
          },
          ...upArrowIcon,
          color: colors.primary,
          size: 24,
        },
        activeBackgroundColor: colors.background,
        inactiveBackgroundColor: colors.background,
      },
      expand: {
        ...iconButton,
        containerStyle: {
          borderRadius: 14,
          justifyContent: "center",
          height: 20,
          marginLeft: 4,
          marginRight: 12,
          width: 20,
        },
        iconProps: {
          containerStyle: {
            alignItems: "center",
            borderColor: colors.primary,
            borderWidth: 2,
            borderRadius: 12,
            height: 20,
            width: 20,
          },
          style: {
            height: 20,
            justifyContent: "center",
            top: -2,
            width: 20,
          },
          ...downArrowIcon,
          color: colors.primary,
          size: 24,
        },
        activeBackgroundColor: colors.background,
        inactiveBackgroundColor: colors.background,
      },
      divider: {
        borderBottomWidth: 1,
        borderColor: colors.divider,
      },

      containerExpanded: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 6,
      },
      containerContracted: {
        alignItems: "center",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
      },

      swipeable: {
        childContainerStyle: {
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          flex: 1,
          marginLeft: 20,
          marginRight: 10,
        },
        delete: {
          backgroundColor: colors.lightPrimary,
          width: 60,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        },
      },
      form: {
        avatar: {
          containerStyle: {
            ...shadows.lightAura,
            justifyContent: "center",
            borderWidth: 2,
            borderColor: colors.offText,
            borderRadius: 20,
            backgroundColor: colors.divider,
            marginTop: 5,
            zIndex: 4,
          },
          overlayContainerStyle: {},
          placeholderStyle: {},
          rounded: true,
          size: 36,
          titleStyle: {
            fontFamily: "Droid",
          },
        },
        input: {
          ...input,
          containerStyle: {
            ...input.containerStyle,
            flex: 1,
            height: 24,
          },
          errorStyle: { height: 0 },
          inputContainerStyle: {
            ...input.inputContainerStyle,
            backgroundColor: colors.transparent,
          },
          inputStyle: {
            ...input.inputStyle,
            backgroundColor: colors.background,
            borderColor: colors.divider,
            borderWidth: 1,
            borderRadius: 8,
            paddingHorizontal: 6,
            width: "100%",
          },
        },
        picker: {
          ...picker,
          containerStyle: {
            flex: 1,
            marginBottom: 4,
          },
          labelContainerStyle: { left: 2 },
          labelStyle: {
            ...picker.labelStyle,
            borderColor: colors.transparent,
            size: 12,
            color: colors.subText,
          },
          style: { ...picker.style, paddingHorizontal: 4 },
        },
        pickerDisabled: { ...pickerDisabled },
        wrapperStyle: {
          flex: 1,
          width: "100%",
        },
      },

      guestText: {
        style: {
          ...typos.regularText,
          color: colors.primary,
        },
      },
      guestWrapperStyle: {
        borderColor: colors.primary,
        borderRadius: 4,
        borderWidth: 1,
        marginLeft: 10,
        marginTop: 2,
      },
      infoStyle: { ackgroundColor: colors.background, minHeight: 24 },
      infoText: {
        ...typos.titleText,
        style: {
          color: colors.lightText,
          textAlign: "center",
          padding: 4,
        },
      },
      swipeLeftToCancelProps: {
        containerStyle: { alignSelf: "center" },
        iconProps: { ...swipeLeftIcon },
      },

      textProps: {
        numberOfLines: 1,
        style: {
          ...typos.inputText,
          color: colors.text,
          flex: 1,
        },
      },
      wrapperStyle: {
        backgroundColor: colors.background,
        width: "100%",
      },
    },

    memberType: {
      buttonsStyle: {
        ...button,
        containerStyle: {
          ...button.containerStyle,
          marginVertical: 15,
        },
        titleStyle: {
          ...button.titleStyle,
          paddingHorizontal: 12,
        },
      },
      buttonWrapperStyle: { flexDirection: "column" },
      containerStyle: {
        backgroundColor: colors.transparent,
      },
      overlapStyle: {
        backgroundColor: colors.darkTranslucent,
      },
    },
  },

  userCard: {
    backgroundColor: colors.background,
    borderRadius: 8,
    flex: -1,
    margin: 20,
    marginTop: 40,
  },

  userIds: {
    containerStyle: {
      flex: 1,
      justifyContent: "center",
      paddingHorizontal: 8,
      paddingVertical: 6,
    },
    /*displayName: {
      ellipsizeMode: "tail",
      numberOfLines: 1,
      style: {
        color: colors.offText,
        flex: 1,
        fontFamily: "Roboto",
        fontSize: 18,
        textAlign: "center",
      },
    },
    */
    displayName: {
      ...input,
      containerStyle: {
        ...input.containerStyle,
        marginBottom: 0,
        flex: 1,
      },
      errorStyle: { backgroundColor: "red", height: 0 },
      inputContainerStyle: {
        ...input.inputContainerStyle,
        backgroundColor: "transparent",
        height: 18,
      },
      inputDisabledStyle: {
        ...input.inputDisabledStyle,
        borderColor: colors.transparent,
      },
      inputStyle: {
        ...input.inputStyle,
        // backgroundColor: "purple",
        color: colors.offText,
        borderColor: colors.clearTranslucent,
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: 6,
        marginTop: 8,
        fontFamily: "Roboto",
        lineHeight: 18,
        fontSize: 18,
        width: "100%",
        textAlign: "center",
      },
      labelStyle: { ...input.labelStyle, borderColor: colors.transparent },
    },

    displayNameWrapper: {
      borderBottomColor: colors.offText,
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    email: {
      ellipsizeMode: "tail",
      numberOfLines: 1,
      style: {
        textAlign: "center",
        color: colors.offText,
        fontFamily: "RobotoLight",
        fontSize: 10,
        marginLeft: 16,
      },
    },
    iconAccount: {
      color: colors.offText,
      name: "user",
      type: "antdesign",
      size: 18,
    },
    wrapperStyle: {
      backgroundColor: colors.primary,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      flexDirection: "row",
      height: 70,
    },
  },
});
