// Libraries
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// UI components
import { Icon } from "react-native-elements";
import { ActivityIndicator, Text, View } from "react-native";
import DataSetItem from "../../components/DataSetItem";
import MenuItem from "../../components/MenuItem";
import { ScrollView } from "react-native-gesture-handler";

// App data
import routes from "../../navigation/routes";
import { LanguageContext, StylesContext } from "../../data/contexts";

// API
import adminApi from "../../data/admin";

export default function DataList({ navigation, route }) {
  const [dataLoading, setDataLoading] = useState(false);
  const [metadataLoaded, setMetadataLoaded] = useState(false);
  const [schemaLoaded, setSchemaLoaded] = useState(false);

  const [metadata, setMetadata] = useState(undefined);
  const [schema, setSchema] = useState(undefined);

  const lang = useContext(LanguageContext);
  const { DataList: props } = useContext(StylesContext);

  const data = useSelector((state) => {
    return state.admin[route.params.table];
  });

  useEffect(() => {
    if (route?.params) {
       loadDatasetInfo(route.params.table);
      navigation.setOptions({ title: route.params.title });
    }
  }, [route]);

  const loadDatasetInfo = useCallback(async (dataSet) => {
    setDataLoading(true);
    setMetadataLoaded(false);
    setSchemaLoaded(false);
    adminApi
      .getDataSet(dataSet)
      .then(() => {
        setDataLoading(false);
      })
      .catch((error) => {
        console.error("Datalist (getDataSet): ", error);
      });
    adminApi
      .getMetaData(dataSet)
      .then((data) => {
        setMetadata(data);
        setMetadataLoaded(true);
      })
      .catch((error) => {
        console.error("Datalist (getMetaData): ", error);
      });
    adminApi
      .getSchema(dataSet)
      .then((data) => {
        setSchema(data);
        setSchemaLoaded(true);
      })
      .catch((error) => {
        console.error("Datalist (getSchema): ", error);
      });
  });

  const onPressItem = (documentID) => {
    navigation.navigate(routes.DATA_EDITOR, {
      data: data[documentID],
      dataset: route.params.table,
      documentID,
      metadata,
      schema,
    });
  };

  if (!(metadataLoaded && schemaLoaded))
    return (
      <View {...props.screen}>
        <ActivityIndicator size="large" />
      </View>
    );
  else
    return (
      <ScrollView
        style={{ width: "100%" }}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <MenuItem {...props.addNewButton.touchableProps} onPress={onPressItem}>
          <View style={props.addNewButton.containerStyle}>
            <Text style={props.addNewButton.textStyle}>
              {lang.ADD_NEW_BUTTON}
            </Text>
            <Icon {...props.addNewButton.iconProps} />
          </View>
        </MenuItem>

        <View {...props.dataWrapper}>
          {data
            ? Object.entries(data).map((item, index) => {
                return (
                  <DataSetItem
                    key={index}
                    id={item[0]}
                    label={item[1][metadata.highlight] || item[0]}
                    onPress={onPressItem}
                    style={props.dataSetItem}
                    tags={metadata.tags.map((tag) => {
                      return item[1][tag.field] ? { ...tag } : null;
                    })}
                    tagStyle={props.tag}
                    touchableProps={props.touchable}
                  />
                );
              })
            : null}
        </View>
      </ScrollView>
    );
}
