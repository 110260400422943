// libraries
import React, { useContext } from "react";
import { useSelector } from "react-redux";

// components
import { Image, Text, View } from "react-native";
import { Button } from "../components";

// App data
import { LanguageContext, StylesContext } from "../data/contexts";
import routes from "../navigation/routes";

export default function Header(allprops) {
  const lang = useContext(LanguageContext);
  const { Header: props } = useContext(StylesContext);
  const user = useSelector((state) => state.user["details"]);
  const rules = useSelector((state) => state.user["rules"]);

  if (!user) return null;
  return (
    <View style={props.containerStyle}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {allprops.navigation.canGoBack() ? (
          <Button
            {...props.buttonBack.buttonProps}
            title="back"
            onPress={() => allprops.navigation.goBack()}
          />
        ) : null}

        <View style={props.logo.containerStyle}>
          <Image {...props.logo.imageProps} />
          <View style={{ transform: [{ rotate: "-6deg" }] }}>
            <Text {...props.logo.siteProps}>{lang.LOGO_TEXT}</Text>
          </View>
        </View>
      </View>

      {false ? <Text {...props.userText}>{user.displayName}</Text> : null}

      <View style={props.credits.containerStyle}>
        {!rules.isCoach ? (
          <>
            <Text {...props.credits.label}>credits</Text>
            <Button
              {...props.credits.buttonProps}
              title={`${user.balance || 0}`}
              onPress={() => {
                allprops.navigation.navigate(routes.BUY_CREDITS);
              }}
            />
          </>
        ) : null}
      </View>
    </View>
  );
}
