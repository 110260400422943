import { Platform } from "react-native";

// API
const api = (() => {
  const AVATARS_PATH = "all/users/avatars/";
  const UI_DATA_PATH = "all/ui";
  const UI_COACHES = "all/coaches";
  const UI_MESSAGES = "all/messages";
  const UI_USERS = "all/users";
  const USERS_DETAILS_PATH = "users/";
  const USERS_RESTRICTED_DETAILS_PATH = "users_restricted/";
  const USERS_CREDITS_PATH = "users_credits/";
  const STORE_PATH = "store/products";
  let dispatch;
  let firebase;
  let auth;
  let firestore;
  let region;
  let creditsListener;
  let messagesListener;
  let UIListener;

  const initiateUser = (storeDispatch, backend, _region) => {
    firebase = backend;
    region = _region;
    auth = firebase.auth();
    firestore = firebase.firestore();

    if (Platform.OS === "web")
      firestore.enablePersistence({ synchronizeTabs: true });
    else firestore.enablePersistence();

    if (!storeDispatch) {
      console.warn(
        "Initializing Auth listener failed, no dispatch function provided."
      );
      return;
    }
    if (!dispatch) {
      dispatch = storeDispatch;
      auth.onAuthStateChanged((user) => {
        console.log("initiateAuth set user :", user?.displayName);
        loadRemoteData(user);
      });
    } else console.warn("Auth listener already initialised");
  };

  const createUser = async (displayName, email, password) => {
    return firebase
      .app()
      .functions(region)
      .httpsCallable("newUserPreRegistering")({
        displayName,
        email,
        password,
      })
      .then(() => {
        return new Promise((resolve, reject) => {
          const retryLogUser = (retries = 10, delay = 1000) => {
            console.log("createUser, retryLogUser, retries:", retries);
            if (!retries) {
              console.warn("createUser, Reached retries threshold.", retries);
              reject("CreateUser, unable to log after creation.");
            }
            logUser(email, password)
              .then((res) => {
                console.log("createUser, Succeded at logging in.");
                resolve(res);
              })
              .catch((e) => {
                console.warn("createUser, failed at logging in: ", retries, e);
                if (e.code === "auth/user-disabled") {
                  setTimeout(() => {
                    retryLogUser(retries - 1, delay);
                  }, delay);
                } else reject(e.code);
              });
          };
          return retryLogUser(5, 1000);
        });
      })
      .then((res) => {
        console.log("Sending Verification Email.", res);
        return sendVerificationEmail();
      })
      .catch((err) => {
        console.warn("Ending in error while creating user.", err);
        throw err;
      });
  };

  const loadUIdata = async () => {
    let promises = [];
    promises.push(
      new Promise((resolve, reject) => {
        if (!UIListener)
          UIListener = firestore.doc(UI_DATA_PATH).onSnapshot(
            (uiSnapshot) => {
              const data = uiSnapshot.data();
              dispatch(addUIdata(data, true));
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
      })
    );

    promises.push(
      firestore
        .doc(UI_COACHES)
        .get()
        .then((coaches) => {
          dispatch(addUIdata({ coaches: coaches.data() }));
        })
        .catch((e) => {
          console.warn("Failed UI COACHES");
          throw e;
        })
    );

    promises.push(
      new Promise((resolve, reject) => {
        if (!messagesListener)
          messagesListener = firestore.doc(UI_MESSAGES).onSnapshot(
            (messages) => {
              dispatch(addUIdata({ messages: messages.data() }));
              resolve(true);
            },
            (error) => {
              reject(error);
            }
          );
      })
    );

    promises.push(
      firestore
        .doc(STORE_PATH)
        .get()
        .then((itemsCategory) => {
          dispatch(addUIdata({ store: itemsCategory.data() }));
        })
        .catch((e) => {
          console.warn("Failed UI STORE");
          throw e;
        })
    );

    promises.push(
      firestore
        .doc(UI_USERS)
        .get()
        .then((users) => {
          dispatch(addUIdata({ users: users.data() }));
        })
        .catch((e) => {
          console.warn("Failed UI USERS");
          throw e;
        })
    );

     firestore
      .doc(AVATARS_PATH + "files")
      .get()
      .then((docSnapshot) => {
        const maxAvatarsFile = docSnapshot.data()["__current_file"] || 1;
        for (let num = 1; num <= maxAvatarsFile; num++) {
          promises.push(
            firestore
              .doc(AVATARS_PATH + "avatars_" + num)
              .get()
              .then((avatars) => {
                dispatch(addAvatars(avatars.data()));
              })
          );
        }
      })
      .catch((e) => {
        console.warn("Failed UI AVATARS");
        throw e;
      });

    return Promise.all(promises).then((values) => ({
      roles: values[0].roles,
      rules: values[0].rules,
    }));
  };

  const calculateRules = (userClaims, roles, rules) => {
    const rolesGranted = roles.slice(0, roles.indexOf(userClaims.role) + 1);
    let userGrants = {};

    Object.entries(rules).forEach(([ruleKey, ruleValue]) => {
      if (ruleKey.startsWith("ROLE_"))
        userGrants[ruleKey] = rolesGranted.includes(ruleValue);
      else userGrants[ruleKey] = ruleValue;
    });

    return userGrants;
  };

 
  const loadRemoteData = (user) => {
    if (user && user.emailVerified) {
      loadUIdata()
        .then((data) => {
          console.log("LoadUIdata ended successfully.");
          return loadUserDetails(user, data.roles, data.rules);
        })
        .then(() => {
          console.log("LoadRemoteData ended successfully.");
        })
        .catch((err) => {
          console.warn("Failed to load remote data.", err);
        });
    } else {
      dispatch(
        loadUserDetailsToStore({
          uid: user ? user.uid : null,
          emailVerified: false,
          claimedRole: "undefined",
          isAnonymous: true,
        })
      );
      dispatch(loadUserRules({}));
      return false;
    }
  };

  const loadPlayerDetails = async (player) => {
    const promises = [];
    let details = {};

    // manage guest
    let targetUid = _getRefUserID(player);

    // load role
    promises.push(
      firestore
        .doc(USERS_RESTRICTED_DETAILS_PATH + targetUid)
        .get()
        .then((doc) => {
          details = { ...details, ...doc.data() };
          return true;
        })
        .catch((e) =>
          console.warn("Failed to load role for player: ", player, e)
        )
    );

    // load credits/balance
    promises.push(
      firestore
        .doc(USERS_CREDITS_PATH + targetUid)
        .get()
        .then((doc) => {
          details = { ...details, ...doc.data() };
          return true;
        })
        .catch((e) =>
          console.warn("Failed to load credits for player: ", player, e)
        )
    );

    promises.push(
      firestore
        .doc(USERS_DETAILS_PATH + targetUid)
        .get()
        .then((doc) => {
          details = { ...details, ...doc.data(), uid: targetUid };
          return true;
        })
        .catch((e) =>
          console.warn("Failed to load details for player: ", player, e)
        )
    );

    return Promise.all(promises).then(() => details);
  };

  const loadUserDetails = async (user, roles, rules) => {
    const claims = await user.getIdTokenResult().then((token) => token.claims);

    // load rules
    dispatch(
      loadUserRules({ ...calculateRules(claims, roles, rules), ...claims })
    );

    let userData = {
      claimedRole: claims.role,
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      isAnonymous: user.isAnonymous,
      uid: user.uid,
    };
    // load restricted data
    userData = await firestore
      .doc(USERS_RESTRICTED_DETAILS_PATH + userData.uid)
      .get()
      .then((doc) => ({ ...userData, ...doc.data() }))
      .catch((error) => {
        console.warn("Loading restricted users details failed.", error);
        return userData;
      });

    // load other data (category)
    userData = await firestore
      .doc(USERS_DETAILS_PATH + userData.uid)
      .get()
      .then((doc) => ({ ...userData, ...doc.data() }))
      .catch((error) => {
        console.warn("Loading Users details failed.", error);
        return userData;
      });
    dispatch(loadUserDetailsToStore(userData));
    _loadCredits(user);
    return true;
  };

  const _loadCredits = (user) => {
    if (creditsListener) creditsListener();
    creditsListener = firestore.doc(USERS_CREDITS_PATH + user.uid).onSnapshot(
      (doc) => {
        dispatch(
          updateUserDetailsToStore({ ...(doc.data() || { balance: 0 }) })
        );
      },
      (error) => {
        dispatch(updateUserDetailsToStore({ balance: 0 }));
        console.warn("Failed to load credits: ", user, error);
      }
    );
  };

  /*
  const reloadUserDetails = (player) => {
    try {
      firestore
        .doc(USERS_CREDITS_PATH + _getRefUserID(player))
        .get()
        .then((doc) =>
          updateUserDetailsToStore({ ...(doc.data() || { balance: 0 }) })
        )
        .catch((error) => {
          throw error;
        });
    } catch (err) {
      console.warn("Reloading credits users details failed.", err);
    }
  };
*/

  const _getRefUserID = (player) => {
    //if (player.uid.endsWith(player.displayName))
    //  return player.uid.slice(0, -(player.displayName.length + 1));
    if(player.isGuest)
      return player.uid.slice(0,player.uid.indexOf("_"));
    else return player.uid;
  };

  const saveUserDetails = async (data) => {
    // const promises = [];
    // get only valid field and split fields in restricted and other categories
    //   remove balance and credits which are occasionnaly provided but shouldn't be saved in user details
    const validData = Object.entries(data)
      .filter(
        (item) =>
          item[1] !== undefined && !["balance", "orders"].includes(item[0])
      )
      .reduce((acc, cv) => {
        acc[cv[0]] = cv[1];
        return acc;
      }, {});

    return firebase
      .app()
      .functions(region)
      .httpsCallable("saveUser")(validData)
      .then(() => {
        if (data.uid === auth.currentUser.uid) {
          dispatch(
            updateUserDetailsToStore({
              ...validData,
            })
          );
          dispatch(
            updateUserUIdata(data.uid, {
              age_group: data.age_group,
              category: data.category,
              displayName: data.displayName,
              dependants: data.dependants,
              email: data.email,
              fullName: data.fullName,
              phoneNumber: data.phoneNumber,
            })
          );
        } else {
          loadUIdata();
        }
      });
  };

  const logUser = (email, password) => {
    return auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        return auth
          .signInWithEmailAndPassword(email, password)
          .then((response) => {
            return response.user;
          })
          .catch((e) => {
            throw e;
          });
      })
      .catch((e) => {
        throw e;
      });
  };

  const logoutUser = () => {
    if (creditsListener) creditsListener();
    creditsListener = null;
    if (messagesListener) messagesListener();
    messagesListener = null;
    if (UIListener) UIListener();
    UIListener = null;
    return auth.signOut();
  };

  const resetPassword = (email) => {
    return auth.sendPasswordResetEmail(email);
  };

  const confirmResetPassword = (code, newPwd) => {
    return auth.confirmPasswordReset(code, newPwd);
  };

  const updatePassword = (newPassword) => {
    return auth.currentUser.updatePassword(newPassword);
    // may need firebase.User.reauthenticateWithCredential.
  };

  const sendVerificationEmail = async () => {
    const user = auth.currentUser;
    if (user) return user.sendEmailVerification();
    else
      throw new Error("No user logged. Failed to send a verification email.");
  };

  const updateCredits = async (player, delta, details) => {
    if (!player.uid || isNaN(delta) || delta === 0 || !details.length)
      throw new Error("Erroneous data provided to update credits");

    return firebase.app().functions(region).httpsCallable("updateCredits")({
      userID: player.uid,
      delta,
      details,
    });
  };

  return {
    confirmResetPassword,
    createUser,
    initiateUser,
    loadPlayerDetails,
    logUser,
    logoutUser,
    // refreshData,
    resetPassword,
    saveUserDetails,
    sendVerificationEmail,
    updatePassword,
    // reloadUserDetails,
    updateCredits,
  };
})();
export default api;

// THE REDUX PART
// ********************************
// actions types
const ADD_AVATARS = "addAvatars";
const ADD_UI_DATA = "addUIdata";
const LOAD_USER_DETAILS = "loadUserDetails";
const LOAD_USER_RULES = "loadUserRules";
const UPDATE_USER_DETAILS = "updateUserDetails";
const UPDATE_USER_UIDATA = "updateUserUIData";

// action creator
const addAvatars = (avatars) => ({
  type: ADD_AVATARS,
  payload: avatars,
});
const addUIdata = (UIdata, sort = false) => ({
  type: ADD_UI_DATA,
  payload: { UIdata, sort },
});
const loadUserDetailsToStore = (userDetails) => ({
  type: LOAD_USER_DETAILS,
  payload: { userDetails },
});
const loadUserRules = (rules) => ({
  type: LOAD_USER_RULES,
  payload: rules,
});
const updateUserDetailsToStore = (data) => ({
  type: UPDATE_USER_DETAILS,
  payload: data,
});
const updateUserUIdata = (userID, data) => {
  const payload = {};
  payload[userID] = data;
  return {
    type: UPDATE_USER_UIDATA,
    payload,
  };
};

// reducer
export const userReducer = (state = {}, action) => {
  const orderingUIdata = (data) => {
    const sorted = {};
    let orderFound = false;
    for (var el in data) {
      let idx;
      if (data[el].order && data[el].order > 0) {
        orderFound = true;
        idx = data[el].order;
      } else idx = 0;
      if (!sorted[idx]) sorted[idx] = [];
      sorted[idx].push({ label: el, ...data[el] });
    }
    if (orderFound)
      return Object.keys(sorted)
        .sort()
        .reduce((acc, idx) => acc.concat(sorted[idx]), []);
    else return data;
  };

  switch (action.type) {
    case ADD_AVATARS: {
      return { ...state, avatars: { ...state.avatars, ...action.payload } };
    }
    case ADD_UI_DATA: {
      if (action.payload.sort) {
        const newData = {};
        for (var dataset in action.payload.UIdata) {
          newData[dataset] = orderingUIdata(action.payload.UIdata[dataset]);
        }
        return { ...state, UIdata: { ...state.UIdata, ...newData } };
      } else
        return {
          ...state,
          UIdata: { ...state.UIdata, ...action.payload.UIdata },
        };
    }
    case LOAD_USER_RULES: {
      return { ...state, rules: { ...action.payload } };
    }
    case LOAD_USER_DETAILS: {
      return { ...state, details: action.payload.userDetails };
    }
    case UPDATE_USER_DETAILS: {
      return { ...state, details: { ...state.details, ...action.payload } };
    }
    case UPDATE_USER_UIDATA: {
      return {
        ...state,
        UIdata: {
          ...state.UIdata,
          users: { ...state.UIdata.users, ...action.payload },
        },
      };
    }
    default:
      return state;
  }
};
