// librairies
import React, { useContext } from "react";
// UI components
import Agenda from "../sessions/Agenda";
import { View } from "react-native";

// App data
import { LanguageContext } from "../../data/contexts";

export default function Home() {
  const lang = useContext(LanguageContext);
  // marginTop:36
  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <Agenda
        scheduling={true}
        filtering={true}
        title={lang.BOOK_SESSION_TITLE}
      />
    </View>
  );
}
