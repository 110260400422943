import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";

import Button from "./Button";
import Overlap from "./Overlap";
import LottieView from "./LottieView";

export const TIME_OUT = "timeOut";
export const PRESSED_OUTSIDE = "pressedOut";

export default function OverlayBox({
  animation = undefined, // object containing LottieView props
  buttons = [], // array of button titles, this value is also returned while the button is pressed
  buttonsStyle,
  buttonWrapperStyle,
  containerStyle,
  callback = undefined,
  handlePressOutside = false,
  message,
  messageStyle,
  overlapStyle = {}, //backgroundColor, fontSize, color
  time = 0,
  visible,
}) {
  const [timer, setTimer] = useState(null);
  const handleEvent = (buttonClicked) => {
    clearTimeout(timer);
    if (buttonClicked && callback) callback(buttonClicked);
  };

  useEffect(() => {
    clearTimeout(timer);
    if (time > 0) {
      setTimer(
        setTimeout(() => {
          handleEvent(TIME_OUT);
        }, time)
      );
      return clearTimeout(timer);
    }
  }, [time, visible]);

  if (!visible) return null;
  return (
    <Overlap
      backgroundColor={overlapStyle.backgroundColor}
      containerStyle={containerStyle}
      onPress={handlePressOutside ? () => handleEvent(PRESSED_OUTSIDE) : null}
    >
      <Text style={messageStyle}>{message}</Text>
      {animation ? <LottieView {...animation} /> : null}
      <View
        style={[{ flexDirection: "row", width: "100%" }, buttonWrapperStyle]}
      >
        {buttons.map((button) => {
          return (
            <Button
              {...buttonsStyle}
              {...button.props}
              title={button.title}
              key={button.key}
              onPress={() => {
                clearTimeout(timer);
                handleEvent(button.key);
              }}
            />
          );
        })}
      </View>
    </Overlap>
  );
}
