export default ({ colors, shadows, typos }) => ({
  button: {
    containerStyle: {
      //borderWidth: 1,
      // borderColor: colors.subText,
      borderRadius: 6,
      height: 28,
      ...shadows.lightAura,
    },
    titleStyle: {
      alignSelf: "center",
      color: colors.offText,
      fontFamily: "Roboto",
      fontSize: 16,
    },
    inactiveBackgroundColor: colors.accent,
  },

  iconButton: {
    containerStyle: {
      alignItems: "center",
      //borderColor: colors.offText,
      borderRadius: 20,
      justifyContent: "center",
      height: 32,
      width: 32,
      ...shadows.lightAura,
    },
    iconProps: {
      containerStyle: {
        height: 32,
        width: 32,
      },
      color: colors.offText,
      size: 20,
      style: { height: 32, justifyContent: "center", width: 32 },
    },
    activeBackgroundColor: colors.subText,
    inactiveBackgroundColor: colors.accent, //colors.transparent,
  },

  smallIconButton: {
    containerStyle: {
      alignItems: "center",
      //borderColor: colors.offText,
      borderRadius: 20,
      justifyContent: "center",
      height: 26,
      width: 26,
      ...shadows.lightAura,
    },
    iconProps: {
      containerStyle: {
        height: 26,
        width: 26,
      },
      color: colors.offText,
      size: 20,
      style: { height: 26, justifyContent: "center", width: 26 },
    },
    activeBackgroundColor: colors.subText,
    inactiveBackgroundColor: colors.accent, //colors.transparent,
  },

  addButton: {
    containerStyle: {
      borderRadius: 20,
      height: 38,
      width: 38,
      ...shadows.lightAura,
    },
    title: "+",
    titleStyle: {
      ...typos.buttonText,
      color: colors.offText,
      fontSize: 16,
      paddingHorizontal: 10,
    },
    inactiveBackgroundColor: colors.accent,
  },
});
