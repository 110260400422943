// Libraries
import React, { useCallback, useContext, useState } from "react";
import { useAssets } from "expo-asset";

// components
import { View } from "react-native";
import WebDrawer from "./components/WebDrawer";

// data
import { LanguageContext, StylesContext } from "../../data/contexts";

// TC : Terms and Conditions
// PP : Privacy Policy
// DPP : Data Protection Policy
// RP : Refund Policy
const defaultExpanded = { TC: false, PP: false, DPP: false, RP: false };

export default function Information({ containerStyle }) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const lang = useContext(LanguageContext);
  const { Information: props } = useContext(StylesContext);
  const [assets] = useAssets([
    require("../../../assets/legal/TermsAndConditions.html"),
    require("../../../assets/legal/PrivacyPolicy.html"),
    require("../../../assets/legal/Data-Protection-Policy.html"),
  ]);

  const switchDrawer = useCallback(
    (key) => {
      const newValue = { ...defaultExpanded };
      newValue[key] = !expanded[key];
      setExpanded(newValue);
    },
    [expanded]
  );

  if (!assets) return null;
  return (
    <View style={containerStyle || { marginTop: 0, flex: 1 }}>
      <WebDrawer
        props={props}
        expanded={expanded.TC}
        switchExpanded={() => switchDrawer("TC")}
        title={lang.LEGAL.TC.label}
        content={assets[0].localUri}
      />

      <WebDrawer
        props={props}
        expanded={expanded.PP}
        switchExpanded={() => switchDrawer("PP")}
        title={lang.LEGAL.PP.label}
        content={assets[1].localUri}
      />

      <WebDrawer
        props={props}
        expanded={expanded.DPP}
        switchExpanded={() => switchDrawer("DPP")}
        title={lang.LEGAL.DPP.label}
        content={assets[2].localUri}
      />

      <WebDrawer
        props={props}
        expanded={expanded.RP}
        switchExpanded={() => switchDrawer("RP")}
        title={lang.LEGAL.RP.label}
        content={lang.LEGAL.RP.content}
      />
    </View>
  );
}
