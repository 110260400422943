export default ({ colors }) => ({
  accountIcon: {
    color: colors.primary,
    name: "user",
    type: "antdesign",
  },
  closeIcon: {
    name: "close",
    type: "antdesign",
  },
  deleteIcon: {
    color: colors.error,
    name: "remove-circle-outline",
  },
  downArrowIcon: {
    name: "chevron-down",
    type: "material-community",
  },
  emailIcon: {
    color: colors.accent,
    name: "mail",
    type: "antdesign",
  },
  infoIcon: {
    color: colors.text,
    name: "info-outline",
  },
  locationIcon: {
    color: colors.primary,
    name: "location-on",
    type: "antdesign",
  },
  passwordIcon: {
    color: colors.error,
    name: "lock",
    type: "antdesign",
  },
  upArrowIcon: {
    name: "chevron-up",
    type: "material-community",
  },
  swipeLeftIcon: {
    color: colors.error,
    name: "arrow-left",
    type: "antdesign",
  },
});
