import React, { useEffect, useRef, useState } from "react";
import { View, Text } from "react-native";
import { Icon, Input } from "react-native-elements";
import { useFormikContext } from "formik";

export default function FormArray({ label, name, style }) {
  const { setFieldValue, values } = useFormikContext();
  const [textToAdd, setTextToAdd] = useState("");
  const inputComponent = useRef(null);

  useEffect(() => {
    if (typeof values[name] == "string") setFieldValue(name, [values[name]]);
    else if (!values[name] || values[name].length < 1) setFieldValue(name, []);
  }, []);

  const addText = () => {
    if (textToAdd.length > 0 && !values[name].includes(textToAdd)) {
      let newValue = [...values[name]];
      newValue.push(textToAdd);
      setFieldValue(name, newValue);
      inputComponent.current.clear();
      setTextToAdd("");
    }
  };

  const delItem = (item) => {
    setFieldValue(
      name,
      values[name].filter((value) => value != item)
    );
  };

  return (
    <View style={style.container}>
      <View style={style.addContainer}>
        <Input
          {...style.input}
          label={label}
          ref={inputComponent}
          onChangeText={(value) => setTextToAdd(value)}
        />
        <Icon {...props.addIcon} {...style.addIcon} onPress={addText} />
      </View>
      <View style={style.itemsContainer}>
        {values[name] &&
        typeof values[name] != "string" &&
        values[name].length > 0
          ? values[name].map((item, index) => {
              return (
                <View style={style.itemContainer} key={item}>
                  <Text style={style.itemText}>{item}</Text>
                  <Icon
                    {...props.delIcon}
                    {...style.delIcon}
                    onPress={() => delItem(item)}
                  />
                </View>
              );
            })
          : null}
      </View>
    </View>
  );
}

const props = {
  addContainer: {},
  addIcon: {
    name: "plus",
    type: "simple-line-icon",
    size: 24,
    color: "gray",
    style: { width: 24 },
  },
  container: {},
  delIcon: {
    name: "minus",
    type: "simple-line-icon",
    size: 24,
    color: "red",
    style: { width: 24 },
  },
  input: {},
  itemsContainer: {},
  itemText: {},
};
