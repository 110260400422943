export default ({ colors, typos }) => ({
  adminMenu: {
    style: {
      backgroundColor: colors.white,
      flex: 1,
      alignItems: "center",
      width: "100%",
    },
  },
  menuItem: {
    activeColor: colors.secondary,
    activeOpacity: 0.8,
    backgroundColor: colors.white,
    color: colors.light,
    containerStyle: {
      width: "100%",
      alignSelf: "center",
    },
    height: 30,
    separatorColor: colors.lighter,
  },

  title: {
    style: {
      ...typos.titleText,
      color: colors.offText,
      paddingHorizontal: 10,
    },
  },
  titleWrapper: {
    style: {
      backgroundColor: colors.primary,
      height: 30,
      paddingLeft: 2,
      paddingRight: 8,
      marginTop: 30,
      width: "100%",
      justifyContent: "center",
    },
  },

  link: {
    style: {
      ...typos.inputText,
      paddingLeft: 20,
      color: colors.text,
    },
  },
});
