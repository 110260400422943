// Librairies
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// UI components
import { View, Text } from "react-native";
import { Button, OverlayBox } from "../../components";
import { ScrollView } from "react-native-gesture-handler";
import { Icon } from "react-native-elements";
import FormTribe from "./components/FormTribe";
import Credits from "./components/Credits";

// Forms
import { Formik } from "formik";
import { FormAvatar, FormInput, FormPicker } from "../../components/forms";
import * as Yup from "yup";

// API
import userAPI from "../../data/user";

// App data
import { LanguageContext, StylesContext } from "../../data/contexts";

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string().matches(
    / *\+?([ -]?[0-9]{1,4}){1,5} *$/,
    "invalid phone number"
  ),
});

//

export default function AccountDetails({ navigation, route }) {
  const [overlayMessage, setOverlayMessage] = useState({ visible: false });
  const [userShown, setUserShown] = useState(null);
  const [scrollViewRef, setScrollViewRef] = useState(null);

  const avatars = useSelector((state) => state.user["avatars"] || {});
  const UIdata = useSelector((state) => state.user["UIdata"] || false);
  const user = useSelector((state) => state.user["details"]);

  const granted = useSelector((state) => state.user["rules"] || {});

  const lang = useContext(LanguageContext);
  const { AccountDetails: props } = useContext(StylesContext);

  useEffect(() => {
    if (route?.params?.player) {
      (async () =>
        setUserShown(await getPlayerDetails(route.params.player)))().then(
        () => {
          if (scrollViewRef && route.params?.viewGuest)
            scrollViewRef.scrollToEnd();
        }
      );
    } else if (!userShown && user) setUserShown(user);
  }, [scrollViewRef, route, user]);

  const onSavePress = useCallback((values) => {
    setOverlayMessage({
      ...props.animations.WORKING_ON_IT,
      visible: true,
    });
    return userAPI
      .saveUserDetails(values)
      .then(() => {
        setUserShown(values);
        setOverlayMessage({
          ...props.animations.CHECKMARK,
          callback: () => setOverlayMessage({ visible: false }),
          time: 1000,
          visible: true,
        });
        return;
      })
      .catch((error) => {
        console.warn("Save user data failed:", error);
        setOverlayMessage({
          ...props.animations.ERROR,
          callback: () => setOverlayMessage({ visible: false }),
          message: lang.SAVE_FAILED,
          time: 1000,
          visible: true,
        });
        throw error;
      });
  }, []);

  if (!UIdata || !userShown || !user) return null;
  // <Loading />;
  else
    return (
      <>
        <View
          style={{ alignSelf: "center", flex: 1, width: "100%", maxWidth: 400 }}
        >
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              age_group: userShown["age_group"],
              avatar: userShown["avatar"],
              category: userShown["category"],
              dependants: userShown["dependants"],
              displayName: userShown["displayName"],
              email: userShown["email"],
              fullName: userShown["fullName"] || "",
              // intro: userShown["intro"],
              miniAvatar: userShown["miniAvatar"],
              role: userShown["role"],
              uid: userShown["uid"],
              balance: userShown["balance"],
              orders: userShown["orders"],
              phoneNumber: userShown["phoneNumber"] || "",
              notes: userShown["notes"],
            }}
          >
            {({ dirty, values, resetForm, setFieldValue }) => {
              return (
                <>
                  {dirty ||
                  (!granted.ROLE_TO_EDIT_ROLE &&
                    (!values["displayName"] ||
                      !values["fullName"] ||
                      !values["phoneNumber"])) ? (
                    <View style={props.changed.wrapperStyle}>
                      {dirty ? (
                        <Button
                          {...props.changed.buttonSave}
                          title={"save"}
                          onPress={() => {
                            onSavePress(values).then(() =>
                              resetForm({ values: { ...values } })
                            );
                          }}
                        />
                      ) : null}
                      <Text {...props.changed.text}>
                        {dirty ? lang.CHANGES_NOT_SAVED : lang.FILL_ACCOUNT}
                      </Text>
                    </View>
                  ) : null}
                  <ScrollView
                    ref={(ref) => setScrollViewRef(ref)}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                  >
                    <View style={props.userCard}>
                      <View style={props.userIds.wrapperStyle}>
                        <FormAvatar
                          newShotLabel={lang.AVATAR_NEW_SHOT}
                          libraryLabel={lang.AVATAR_LIBRARY}
                          enabled={
                            user.uid === userShown.uid ||
                            granted.ROLE_TO_EDIT_FULL_USER
                          }
                          avatarFieldName="avatar"
                          miniAvatarFieldName="miniAvatar"
                          usernameFieldName="displayName"
                          {...props.avatar}
                        />
                        <View style={props.userIds.containerStyle}>
                          <View style={props.userIds.displayNameWrapper}>
                            <Icon {...props.userIds.iconAccount} />

                            <FormInput
                              autoCapitalize="none"
                              autoCorrect={false}
                              enabled={
                                user.uid === userShown.uid ||
                                granted.ROLE_TO_EDIT_FULL_USER
                              }
                              name="displayName"
                              placeholder={lang.DISPLAY_NAME_PLACEHOLDER}
                              {...props.userIds.displayName}
                              disabledStyle={props.inputDisabled}
                              value={values["displayName"]}
                            />
                          </View>
                          <Text {...props.userIds.email}>
                            {values["email"]}
                          </Text>
                        </View>
                      </View>

                      <View style={props.formWrapperStyle}>
                        <View style={{ flex: 1 }}>
                          <FormInput
                            autoCapitalize="none"
                            autoCorrect={false}
                            enabled={
                              user.uid === userShown.uid ||
                              granted.ROLE_TO_EDIT_FULL_USER
                            }
                            label={lang.USER_NAME_LABEL}
                            name="fullName"
                            placeholder={lang.USER_NAME_PLACEHOLDER}
                            {...props.input}
                            disabledStyle={props.inputDisabled}
                            value={values["fullName"]}
                          />

                          <FormInput
                            autoCapitalize="none"
                            autoCorrect={false}
                            enabled={
                              user.uid === userShown.uid ||
                              granted.ROLE_TO_EDIT_FULL_USER
                            }
                            label={lang.PHONE_NUMBER_LABEL}
                            name="phoneNumber"
                            placeholder={lang.PHONE_NUMBER_PLACEHOLDER}
                            {...props.input}
                            disabledStyle={props.inputDisabled}
                            value={values["phoneNumber"]}
                            errorStyle={props.inputErrorStyle}
                          />

                          <FormPicker
                            disabledStyle={props.pickerDisabled}
                            emptyValue={lang.NO_SELECTED_VALUE}
                            enabled={
                              user.uid === userShown.uid ||
                              granted.ROLE_TO_EDIT_FULL_USER
                            }
                            label={lang.AGE_GROUP}
                            name="age_group"
                            selectedValue={values["age_group"]}
                            valueList={UIdata["age_groups"].map(
                              (el) => el.label
                            )}
                            {...props.picker}
                          />
                          <FormPicker
                            disabledStyle={props.pickerDisabled}
                            emptyValue={lang.NO_SELECTED_VALUE}
                            enabled={
                              granted.ROLE_TO_EDIT_FULL_USER ||
                              granted.ROLE_TO_EDIT_CATEGORY ||
                              !userShown["category"]
                            }
                            label={lang.CATEGORY}
                            name="category"
                            selectedValue={values["category"]}
                            valueList={UIdata["categories"].map(
                              (el) => el.label
                            )}
                            {...props.picker}
                          />

                          {granted.ROLE_TO_EDIT_ROLE ? (
                            <FormPicker
                              disabledStyle={props.pickerDisabled}
                              emptyValue={lang.NO_SELECTED_VALUE}
                              enabled
                              label={lang.ROLE}
                              name="role"
                              selectedValue={values["role"]}
                              valueList={UIdata["roles"]}
                              {...props.picker}
                            />
                          ) : null}
                          {granted.isCoach ? (
                            <FormInput
                              autoCapitalize="none"
                              autoCorrect={false}
                              enabled={granted.isCoach}
                              label={lang.NOTES_LABEL}
                              multiline
                              numberOfLines={5}
                              name="notes"
                              {...props.inputAbout}
                              value={values["notes"]}
                            />
                          ) : null}
                        </View>
                      </View>
                    </View>

                    <FormTribe
                      avatars={avatars}
                      editing={
                        user.uid === userShown.uid ||
                        granted.ROLE_TO_EDIT_FULL_USER
                      }
                      lang={lang}
                      name="dependants"
                      navigation={navigation}
                      styles={props.tribe}
                      UIdata={UIdata}
                      user={userShown}
                    />

                    {user.uid === userShown.uid ? (
                      <Button
                        title={lang.LOG_OUT}
                        onPress={() => onLogout()}
                        {...props.buttonLogOut}
                      />
                    ) : granted.isCoach ? (
                      <Credits
                        props={props.credits}
                        lang={lang}
                        balance={userShown.balance}
                        isEditor={granted.ROLE_TO_UPDATE_CREDITS}
                        onUpdateCredits={(delta, details) =>
                          updateCredits(
                            userShown,
                            delta,
                            details,
                            props,
                            lang,
                            setOverlayMessage,
                            userShown,
                            setUserShown
                          )
                        }
                      />
                    ) : null}
                  </ScrollView>
                </>
              );
            }}
          </Formik>
        </View>
        <OverlayBox {...overlayMessage} />
      </>
    );
}

const updateCredits = (
  user,
  delta,
  details,
  props,
  lang,
  setOverlayMessage,
  userShown,
  setUserShown
) => {
  // setOverlayMessage({ visible: false });
  setOverlayMessage({ ...props.animations.WORKING_ON_IT, visible: true });
  userAPI
    .updateCredits(user, delta, details)
    .then(() => {
      setUserShown({
        ...userShown,
        balance: isNaN(userShown.balance)
          ? parseFloat(delta)
          : parseFloat(userShown.balance) + parseFloat(delta),
      });
      setOverlayMessage({
        ...props.animations.CHECKMARK,
        callback: () => setOverlayMessage({ visible: false }),
        time: 1000,
        visible: true,
      });
      return;
    })
    .catch((error) => {
      console.warn("update credits failed:", error);
      setOverlayMessage({
        ...props.animations.ERROR,
        callback: () => setOverlayMessage({ visible: false }),
        message: lang.SAVE_FAILED,
        time: 1000,
        visible: true,
      });
      throw error;
    });
};

const getPlayerDetails = async (player) => {
  const data = await userAPI.loadPlayerDetails(player);
  return data;
};

const onLogout = () => {
  userAPI
    .logoutUser()
    .then(console.log("Logout sucessful."))
    .catch((error) => {
      console.warn("Logout failed. ", error);
    });
};
