import React, { useContext, useEffect, useState } from "react";
import { View, Text } from "react-native";
import { useSelector } from "react-redux";

import { Button } from "../components";
// navigation
import { navigate, navChange } from "../navigation/rootNavigation";
import routes from "../navigation/routes";

// App data
import { LanguageContext, StylesContext } from "../data/contexts";
import { TouchableOpacity } from "react-native-gesture-handler";

export default function Footer() {
  const [currentScreen, setCurrentScreen] = useState(null);
  const { Footer: props } = useContext(StylesContext);
  const lang = useContext(LanguageContext);
  const granted = useSelector((state) => state.user["rules"] || {});

  useEffect(() => {
    const callback = (event) => {
      const _routes = event?.data?.state?.routes || [];
      if (_routes.length) setCurrentScreen(_routes[_routes.length - 1]?.name);
    };
    navChange.listen(callback);
    return () => {
      navChange.stopListening(callback);
    };
  }, []);

  return (
    <>
      <View style={props.container}>
        <View style={props.buttonContainer}>
          <Button
            {...props.buttonProps}
            inactiveBackgroundColor={
              currentScreen === routes.ACCOUNT_DETAILS
                ? props.activeButton.inactiveBackgroundColor
                : props.buttonProps.inactiveBackgroundColor
            }
            iconProps={{
              ...props.buttonProps.iconProps,
              ...props.buttons.account,
              ...(currentScreen === routes.ACCOUNT_DETAILS
                ? props.activeButton.iconProps
                : {}),
            }}
            onPress={() => navigate(routes.ACCOUNT_DETAILS)}
          />
          <Text {...props.buttonLabel}>{lang.ACCOUNT_DETAILS}</Text>
        </View>

        <View style={props.buttonContainer}>
          <Button
            {...props.buttonProps}
            inactiveBackgroundColor={
              currentScreen === routes.HOME
                ? props.activeButton.inactiveBackgroundColor
                : props.buttonProps.inactiveBackgroundColor
            }
            iconProps={{
              ...props.buttonProps.iconProps,
              ...props.buttons.home,
              ...(currentScreen === routes.HOME
                ? props.activeButton.iconProps
                : {}),
            }}
            onPress={() => navigate(routes.HOME)}
          />
          <Text {...props.buttonLabel}>{lang.HOME}</Text>
        </View>

        <View style={props.buttonContainer}>
          <Button
            {...props.buttonProps}
            inactiveBackgroundColor={
              currentScreen === routes.BOOKING
                ? props.activeButton.inactiveBackgroundColor
                : props.buttonProps.inactiveBackgroundColor
            }
            iconProps={{
              ...props.buttonProps.iconProps,
              ...props.buttons.booking,
              ...(currentScreen === routes.BOOKING
                ? props.activeButton.iconProps
                : {}),
            }}
            onPress={() => navigate(routes.BOOKING)}
          />
          <Text {...props.buttonLabel}>
            {granted.ROLE_TO_EDIT_SESSION ? lang.PLANNING : lang.BOOK_SESSION}
          </Text>
        </View>

        {granted.isManager ? (
          <View style={props.buttonContainer}>
            <Button
              {...props.buttonProps}
              inactiveBackgroundColor={
                currentScreen === routes.ADMIN_NAVIGATOR
                  ? props.activeButton.inactiveBackgroundColor
                  : props.buttonProps.inactiveBackgroundColor
              }
              iconProps={{
                ...props.buttonProps.iconProps,
                ...props.buttons.admin,
                ...(currentScreen === routes.ADMIN_NAVIGATOR
                  ? props.activeButton.iconProps
                  : {}),
              }}
              onPress={() => navigate(routes.ADMIN_NAVIGATOR)}
            />
            <Text {...props.buttonLabel}>{lang.ADMIN_NAVIGATOR}</Text>
          </View>
        ) : null}
      </View>
      <View style={props.info.containerStyle}>
        <TouchableOpacity
          onPress={() => {
            navigate(routes.INFORMATION);
          }}
          style={{ borderWidth: 0 }}
        >
          <Text style={props.info.textStyle}>i</Text>
        </TouchableOpacity>
      </View>
    </>
  );
}
