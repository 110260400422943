export default ({ colors, fullScreen, screen, usedScreen }) => ({
  background: {
    // blurRadius: 3,
    // resizeMode: "cover",
    source: require("../styles/assets/sand3ri.jpg"),
    style: {
      flex: 1,
      //flexDirection: "column",
    },
  },
  sideScreen: {
    backgroundColor: colors.text,
    height: "100%",
    flex: 1,
    overflow: "hidden",
  },
  fullScreen,
  screen,
  statusBar: {
    backgroundColor: colors.primary,
  },
  usedScreen,
});
